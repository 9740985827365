import React from "react";

interface ILoader {
  type?: "lg" | "sm" | "md";
  variant?: "blue";
}

const Loader: React.FC<ILoader> = ({ type, variant }) => {
  return (
    <span
      className={`spinner-border spinner-border-${type ? type : "sm"} ${
        variant === "blue" ? "color-mid-purple" : ""
      } ml-3`}
    ></span>
  );
};

export default Loader;
