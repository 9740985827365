import React from "react";
import styles from "./SectorsGridCard.module.scss";
import { Link } from "react-router-dom";
import { ROUTES } from "../../helpers/routes";

interface ISectorsGridCard {
  id: number;
  interest_rate: number;
  name: string;
}

const SectorsGridCard: React.FC<ISectorsGridCard> = ({
  id,
  interest_rate,
  name,
  children,
}) => {
  return (
    <div className={styles.sectorsGridCard} key={id}>
      {children}
      <div className={styles.sectorsGridCardBottom}>
        <p className={styles.sectorsCardTitle}>{name}</p>
        <p className={styles.sectorsCardInterestRate}>
          Interest Per Annum: {interest_rate}%
        </p>
        <Link
          to={`${ROUTES.SECTORS}/${id}`}
          className="btn btn-sm advancly-white-btn"
        >
          View Details
        </Link>
      </div>
    </div>
  );
};
export default SectorsGridCard;
