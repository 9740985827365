import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { postData } from "../../apis/apiMethods";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { errorHandler } from "../../helpers/errorHandler";
import CustomInputField from "../../components/CustomHTMLElements/CustomInputField";
import Loader from "../../components/Loader/Loader.component";
import { yupValidators } from "../../helpers/yupValidators";
import Alert from "./../../components/Alert/Alert.component";
import { ROUTES } from "./../../helpers/routes";
import { useTypedSelector } from "../../hooks/redux-hooks/useTypedSelector";
import { useActions } from "./../../hooks/redux-hooks/useActions";
import { appInsights } from "../../components/AppInsight/AppInsight";

const schema = yup.object().shape({
  otp: yupValidators.otp,
});

interface IStepTwoProps {}

const StepTwo: React.FC<IStepTwoProps> = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);

  const [otpLoading, setOtpLoading] = useState(false);
  const [otpSuccess, setOtpSuccess] = useState("");
  const history = useHistory();

  const { email, stateToken } = useTypedSelector(
    (state) => state.resetPassword
  );
  const { setResetPasswordInfo } = useActions();

  useEffect(() => {
    appInsights.trackPageView({
      name: "ResetPasswordStepTwo",
      isLoggedIn: true,
    });
  }, []);

  useEffect(() => {
    if (!stateToken) {
      history.goBack();
    }
    // eslint-disable-next-line
  }, []);

  const resendOtp = async () => {
    setOtpLoading(true);
    setError(null);
    setOtpSuccess("");
    const reqBody = {
      reset_step: "first_step",
      email,
    };
    try {
      const response = await postData(apiEndpoints.RESET_PASSWORD, reqBody);
      setResetPasswordInfo({
        email,
        stateToken: response.state_token,
      });
      setOtpLoading(false);
      setOtpSuccess("An OTP has been sent to your email and/or phone number");
    } catch (error) {
      setOtpLoading(false);
      setError(errorHandler(error));
      appInsights.trackException({
        exception : new Error(JSON.stringify(error)),
        properties: {fileName : 'ResetPasswordStepTwo'}
      })
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async ({ otp }: { otp: string }) => {
    setLoading(true);
    setError(null);
    const reqBody = {
      reset_step: "second_step",
      otp,
      state_token: stateToken,
    };
    try {
      const response = await postData(apiEndpoints.RESET_PASSWORD, reqBody);
      setLoading(false);
      setResetPasswordInfo({
        email,
        stateToken: response.state_token,
      });
      history.push(ROUTES.RESET_PASSWORD_SET_PASSWORD);
    } catch (error) {
      setLoading(false);
      setError(errorHandler(error));
      appInsights.trackException({
        exception : new Error(JSON.stringify(error)),
        properties: {fileName : 'ResetPasswordStepTwo'}
      })
    }
  };

  return (
    <form className="my-5" onSubmit={handleSubmit(onSubmit)}>
      {error && <Alert message={error} />}
      {otpSuccess && <Alert message={otpSuccess} type="success" />}
      <h3 className="font-weight-bold mb-3 color-dark-blue">Verification</h3>
      <p>
        A One-Time Password (OTP) has been sent to your phone number and/or
        email address.
      </p>
      <div className="row">
        <div className="col-md-12">
          <CustomInputField
            type="number"
            maxLength={5}
            {...register("otp")}
            label="OTP"
            placeholder="Enter OTP"
            name="otp"
            errors={errors.otp}
          />
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center my-4 flex-wrap">
        <p className="d-flex flex-wrap">
          <span
            className={`color-blue ${
              otpLoading ? "cursor-disabled" : "cursor-pointer"
            }`}
            onClick={resendOtp}
          >
            Resend OTP {otpLoading && <Loader />}
          </span>
        </p>
        <button
          type="submit"
          value="submit"
          className="btn advancly-btn btn-sm"
          disabled={loading}
        >
          Validate
          {loading && <Loader />}
        </button>
      </div>

      <hr />
      <p className="d-flex flex-wrap justify-content-center">
        <span className="text-muted pr-2">Entered a wrong email address?</span>
        <span
          onClick={() => history.goBack()}
          className="color-blue cursor-pointer"
        >
          Go back
        </span>
      </p>
    </form>
  );
};

export default StepTwo;
