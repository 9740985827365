import React, { useEffect } from "react";
import styles from "./InvestmentModalStepTwo.module.scss";
import { useTypedSelector } from "./../../../../hooks/redux-hooks/useTypedSelector";
import {
  selectAgreementCheck,
  selectInvestmentCurrentStep,
  selectSelectedInvestment,
} from "../../../../redux/selectors/investmentSelector";
import { useActions } from "./../../../../hooks/redux-hooks/useActions";
import { selectOnlineInvestment } from "./../../../../redux/selectors/investmentSelector";
import { Dispatch, FormEvent, SetStateAction, useState } from "react";
import { TRepayment_Types } from "../../../../interfaces/investment";
import formatMoney from "./../../../../helpers/formatMoney";
import { InvestmentRangeCard } from "./../../../InvestmentRangeCard/InvestmentRangeCard.component";
import useInterestCalculation from "../../../../hooks/custom-hooks/useInterestCalculation";
import InvestmentOptions from "../../ShowInterest/InvestmentOptions";

interface IInvestmentModalStepTwo {
  setError: Dispatch<SetStateAction<string>>;
}

const InvestmentModalStepTwo: React.FC<IInvestmentModalStepTwo> = ({
  setError,
}) => {
  const currentStep = useTypedSelector(selectInvestmentCurrentStep);
  const selectedInvestment = useTypedSelector(selectSelectedInvestment);
  const { duration, repaymentPlan, amount, currency, numberOfDaysInYear } =
    useTypedSelector(selectOnlineInvestment);
  const [extraInfo, setExtraInfo] = useState<{
    monthlyInterestRepayment: string;
    estimatedReturns: string;
  }>({
    monthlyInterestRepayment: "",
    estimatedReturns: "",
  });

  const { setInvestmentStep, setOnlineInvestmentInfo } = useActions();
  const agreementCheck = useTypedSelector(selectAgreementCheck);

  const onChangeRepaymentFrequency = (plan: TRepayment_Types) => {
    setOnlineInvestmentInfo({ repaymentPlan: plan });
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    setError("");
    e.preventDefault();
    if (!repaymentPlan) {
      setError("Select a repayment plan");
      // Clear error after 3 seconds
      setTimeout(() => setError(""), 3000);
      return;
    }
    if (!duration?.days) {
      setError("Select investment duration");
      // Clear error after 3 seconds
      setTimeout(() => setError(""), 3000);
      return;
    }
    if (!agreementCheck) {
      setError("Please agree to terms and conditions ");
      // Clear error after 3 seconds
      setTimeout(() => setError(""), 3000);
      return;
    }

    setInvestmentStep(currentStep + 1);
  };

  const filteredInvestmentRange: any = selectedInvestment?.interest_ranges.find(
    (range: any) =>
      range.lower_amount <= Number(amount) &&
      range.upper_amount >= Number(amount),
  );
  const { data, isError, isLoading } = useInterestCalculation({
    principalAmount: Number(amount),
    interestRate: duration?.interest,
    tenure: duration?.days,
    repaymentPlan,
    numberOfDaysInYear: Number(numberOfDaysInYear),
  });
  useEffect(() => {
    if (data?.maturity_amount && data?.monthly_interest_payable) {
      setExtraInfo({
        estimatedReturns: data?.maturity_amount,
        monthlyInterestRepayment: data?.monthly_interest_payable,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.maturity_amount, data?.monthly_interest_payable]);
  useEffect(() => {
    setOnlineInvestmentInfo({
      numberOfDaysInYear: selectedInvestment?.numberOfDaysInYear,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInvestment]);

  return (
    <div>
      <h5 className="page-subtitle mb-3">Investment Duration</h5>
      <form onSubmit={onSubmit}>
        <div className="mt-4">
          <p className="form-label">How long do you want to Invest?</p>
          <InvestmentOptions
            investmentRange={filteredInvestmentRange}
            duration={duration}
            isError={isError}
            isLoading={isLoading}
            repaymentPlan={repaymentPlan}
            setInvestmentInfo={setOnlineInvestmentInfo}
            onChangeRepaymentFrequency={onChangeRepaymentFrequency}
            extraInfo={extraInfo}
            setExtraInfo={setExtraInfo}
            currency={currency}
          />
          <div className="d-flex justify-content-end align-items-center mt-3 flex-wrap">
            <button
              type="button"
              className="btn btn-sm advancly-nobg-btn mr-3"
              onClick={() => setInvestmentStep(2)}
            >
              Previous
            </button>
            <button type="submit" className="btn btn-sm advancly-white-btn">
              Next
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
export default InvestmentModalStepTwo;
