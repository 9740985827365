import { PaystackProps } from "react-paystack/dist/types";
import { TCurrencyCodes } from "../interfaces/currencies";
import CONFIG from "./config";
type PaymentChannels = "bank" | "card" | "bank_transfer";

//@ts-ignore
export interface TPaystackConfig extends PaystackProps {
  channels: PaymentChannels[];
  currency: TCurrencyCodes;
}

export const paystackConfig = ({
  email,
  amount,
  currency,
  reference,
}: {
  email: string;
  amount: number;
  currency: TCurrencyCodes;
  reference: string | "";
}): TPaystackConfig => {
  return {
    reference,
    //@ts-ignore
    publicKey: CONFIG.PAYSTACK_PUBLIC_KEY,
    channels: ["card", "bank", "bank_transfer"],
    email,
    amount: amount * 100, // Converting to Kobo
    currency,
  };
};
