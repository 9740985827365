import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { yupValidators } from "../../helpers/yupValidators";
import { useState } from "react";
import CustomInputField from "./../../components/CustomHTMLElements/CustomInputField";
import CustomSelectDropdown from "./../../components/CustomHTMLElements/CustomSelectDropdown";
import { postData } from "./../../apis/apiMethods";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { errorHandler } from "./../../helpers/errorHandler";
import Alert from "./../../components/Alert/Alert.component";
import Loader from "../../components/Loader/Loader.component";
import { useQueryClient } from "react-query";
import { appInsights } from "../../components/AppInsight/AppInsight";

const schema = yup.object().shape({
  phoneNumber: yupValidators.phoneNumber,
  email: yupValidators.email,
  firstName: yupValidators.genericRequired({
    message: "Please enter your first name",
    min: 1,
    max: 30,
  }),
  lastName: yupValidators.genericRequired({
    message: "Please enter your last name",
    min: 1,
    max: 30,
  }),
  isAdmin: yupValidators.genericRequired({
    message: "Please choose an option",
  }),
});

interface IAddUserModal {}

const AddUserModal: React.FC<IAddUserModal> = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const queryClient = useQueryClient();
  const dismissRef = useRef<null | HTMLButtonElement>(null);
  const dismissModal = () => {
    dismissRef?.current?.click();
  };
  useEffect(() => {
    appInsights.trackPageView({ name: "AddUserModal.tsx", isLoggedIn: true });
  }, []);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async ({
    phoneNumber,
    email,
    firstName,
    lastName,
    isAdmin,
  }: {
    phoneNumber: string;
    email: string;
    firstName: string;
    lastName: string;
    isAdmin: string;
  }) => {
    setLoading(true);
    setError("");
    setSuccess("");

    const reqBody = {
      funder_users: [
        {
          firstname: firstName,
          lastname: lastName,
          email,
          phone_number: phoneNumber,
          is_admin: isAdmin === "true" ? true : false,
        },
      ],
    };

    try {
      await postData(apiEndpoints.CREATE_FUNDER_USER, reqBody);
      setSuccess("New user has been created and can now log in as a funder.");
      setLoading(false);
      queryClient.invalidateQueries(["getUsers"]);
      setTimeout(() => {
        dismissModal();
      }, 5000);
    } catch (error) {
      setLoading(false);
      setError(errorHandler(error));
      appInsights.trackException({
        exception : new Error(JSON.stringify(error)),
        properties: {fileName : AddUserModal}
      })
    }
  };

  return (
    <div
      className="modal fade"
      id="addUserModal"
      tabIndex={-1}
      aria-labelledby="addUserModalLabel"
      aria-hidden="true"
    >
      {error && <Alert message={error} />}
      {success && <Alert message={success} type="success" />}
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content p-4">
          <div className="modal-header p-0 pb-2">
            <h5 className="page-subtitle">Add User</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              ref={dismissRef}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body px-0">
            <form onSubmit={handleSubmit(onSubmit)}>
              <CustomInputField
                type="email"
                maxLength={128}
                {...register("email")}
                placeholder="morty@ricknmorty.com"
                label="New User's Email address"
                errors={errors.email}
              />
              <CustomInputField
                type="text"
                maxLength={30}
                {...register("firstName")}
                label="New User's First Name"
                placeholder="Rick"
                errors={errors.firstName}
              />

              <CustomInputField
                type="text"
                maxLength={30}
                {...register("lastName")}
                label="New User's Last Name"
                placeholder="Morty"
                errors={errors.lastName}
              />
              <CustomInputField
                type="number"
                maxLength={20}
                {...register("phoneNumber")}
                placeholder="e.g. 07010000001"
                label="New User's Phone Number"
                errors={errors.phoneNumber}
              />

              <CustomSelectDropdown
                {...register("isAdmin")}
                label="Give user admin rights?"
                errors={errors.isAdmin}
              >
                <option value="">Admin?</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </CustomSelectDropdown>

              <button
                className="btn btn-sm advancly-btn mt-3 w-100"
                disabled={loading}
              >
                Create User {loading && <Loader />}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddUserModal;
