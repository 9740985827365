import React from "react";
import { ReactComponent as SearchIcon } from "../../assets/img/search-icon.svg";

interface ICustomSearchInputProps {
  name?: string;
  placeholder?: string;
  maxLength?: undefined | number;
  onChange?: any;
  onClickSearchIcon: () => void;
  value?: string | number;
}

const CustomSearchInput: React.FC<ICustomSearchInputProps> = React.forwardRef(
  (
    { name, placeholder, maxLength, onChange, onClickSearchIcon, value },
    ref: any
  ) => {
    return (
      <div className="input-group">
        <input
          className="form-control border-right-0"
          type="search"
          name={name}
          placeholder={placeholder}
          maxLength={maxLength}
          onChange={onChange}
          value={value}
          ref={ref}
        />
        <div className="input-group-append cursor-pointer">
          <span className="input-group-text" onClick={onClickSearchIcon}>
            <SearchIcon />
          </span>
        </div>
      </div>
    );
  }
);

export default CustomSearchInput;
