import React, { Fragment, useEffect, useState } from "react";
import styles from "./InvestmentDetails.module.scss";
import { BackButton } from "../../../components/BackButton/BackButton.component";
import InvestmentModal from "./../../../components/InvestmentModal/InvestmentModal.component";
import ToggleTab from "../../../components/ToggleTab/ToggleTab.component";
import useInvestment from "../../../hooks/custom-hooks/useInvestment";
import { match, useRouteMatch } from "react-router-dom";
import { formatDateFull } from "../../../helpers/formatDate";
import Alert from "../../../components/Alert/Alert.component";
import { errorHandler } from "../../../helpers/errorHandler";
import Loader from "../../../components/Loader/Loader.component";
import { useActions } from "../../../hooks/redux-hooks/useActions";
import { InvestmentTermSheet } from "../../../components/InvestmentTermSheet/InvestmentTermSheet.component";
import { downloadPdfFile } from "../../../helpers/downloadPdfFile";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import useInvestmentTermSheet from "./../../../hooks/custom-hooks/useInvestmentTermSheet";
import { appInsights } from "../../../components/AppInsight/AppInsight";
import {Button, Image, message, Modal} from "antd";
import ShowInterestModal from "../../../components/InvestmentModal/ShowInterest/ShowInterestModal.component";
import { useTypedSelector } from "../../../hooks/redux-hooks/useTypedSelector";
import { selectSelectedInvestment } from "../../../redux/selectors/investmentSelector";
import PDFViewer from "../../../components/PdfViewer";
import {getData} from "../../../apis/apiMethods";
import CONFIG from "../../../helpers/config";






export default function InvestmentDetails() {
  const { selectInvestment, resetInvestment } = useActions();
  const match: match<{ id: string }> = useRouteMatch();
  const [activeTab, setActiveTab] = useState("");
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadError, setDownloadError] = useState("");
  const [isShowInterestVisible, setIsShowInterestVisible] = useState(false);
  const [lcyProductDetailsId, setLcyProductDetailsId] = useState<number | undefined>()
  const [usdProductDetailsId, setUsdProductDetailsId] = useState<number | undefined>()
  const [isLoadingBase64, setIsLoadingBase64] = useState(false);
  const [base64Pdf, setBase64Pdf] = useState<string | undefined>()
  const [showPdfModal, setShowPdfModal] = useState(false)

  const { data, error, isLoading, isError } = useInvestment({
    funderInvestmentId: Number(match.params.id),
  });

  useEffect(() => {
    appInsights.trackPageView({
      name: "InvestmentDetails.tsx",
      isLoggedIn: true,
    });
  }, []);

  useEffect(() => {
    if (data && data?.investment_product_details){
      let usdProduct = data?.investment_product_details?.find((l: { currency_code: string; }) => l.currency_code === 'USD')
      if (usdProduct){
        setUsdProductDetailsId(usdProduct?.id)
      }
      let lcyProduct = data?.investment_product_details?.find((l: { currency_code: string; }) => l.currency_code !== 'USD')
      if (lcyProduct){
        setLcyProductDetailsId(lcyProduct?.id)
      }
    }
  }, [data]);

  const downloadtermSheet = async () => {
    window.open(`${CONFIG.ADVANCLY_STORAGE_URL}${data?.term_sheet_url}`, '_blank');
  };

  const isLockedAccount = () => {
    // If investment has both local and ud currency, it is locked only when both are locked
    if (data?.usd_product?.id && data?.lcy_product?.id) {
      return data?.lcy_locked && data?.usd_locked;
    } else if (data?.usd_product?.id && !data?.lcy_product?.id) {
      return data?.usd_locked;
    } else if (!data?.usd_product?.id && data?.lcy_product?.id) {
      return data?.lcy_locked;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (data) {
      setActiveTab(data?.investment_product_details[0].currency_code);
    }
    // eslint-disable-next-line
  }, [data]);

  const resolveDataToMap = () => {
    return data?.investment_product_details[1]?.currency_code === "USD" &&
      activeTab === "USD"
      ? data?.investment_product_details[1]
      : data?.investment_product_details[0];
  };
  const dataToMap = resolveDataToMap() || {};

  const termSheetDetails = useInvestmentTermSheet({
    investmentId: dataToMap?.id,
  });

  const investmentTenor =
    termSheetDetails?.data?.interest_ranges[0]?.interest_rates?.map(
      ({
        duration,
        period_type,
      }: {
        duration: number;
        period_type: string;
      }) => {
        return `${duration} ${period_type}`;
      },
    );

  const disabled =
    !data?.id ||
    isError ||
    termSheetDetails.isLoading ||
    termSheetDetails.isError ||
    isLockedAccount();

  const getUSDProductId = () => {
    const usdProduct = data?.investment_product_details.find(
      (value: { currency_code: string }) => value.currency_code === "USD",
    );
    if (usdProduct) {
      return usdProduct.id;
    } else {
      return null;
    }
  };

  const getLocalCurrencyProductId = () => {
    const localCurrencyProduct = data?.investment_product_details.find(
      (value: { currency_code: string }) => value.currency_code !== "USD",
    );
    if (localCurrencyProduct) {
      return localCurrencyProduct.id;
    } else {
      return null;
    }
  };

  const handleInvestOrShowInterest = () => {
    const interest_ranges = termSheetDetails?.data?.interest_ranges;
    return selectInvestment({
      usd_product_id: getUSDProductId(),
      lcy_product_id: getLocalCurrencyProductId(),
      name: data?.name,
      investment_id: data?.id,
      interest_ranges,
      period_type:
        interest_ranges && interest_ranges[0]?.interest_rates[0]?.period_type,
      lcy_locked: data?.lcy_locked,
      usd_locked: data?.usd_locked,
      numberOfDaysInYear: termSheetDetails?.data?.number_of_days_in_year,
      currencyCode: activeTab,
      investment_product_details_id: data?.investment_product_details[0].id,
    });
  };


  const convertDocumentToBase64 = async (document_path: string) => {
    setIsLoadingBase64(true)
    try {
      const response = await getData(`https://advanclyapitest.azurewebsites.net/api/v1/account/document_download_base64?file_path=${document_path}`)
      setBase64Pdf(`data:application/pdf;base64,${response?.base64_string}`)
    } catch (error) {
      message.error(errorHandler(error))
    }finally {
      setIsLoadingBase64(false)
    }
  }

  useEffect(() => {
    if (data?.term_sheet_url){
      convertDocumentToBase64(data?.term_sheet_url)
    }
  }, [data]);


  return (
    <div>
      {error && <Alert message={errorHandler(error)} />}
      {downloadError && <Alert message={errorHandler(downloadError)} />}
      <BackButton text="Back" />
      {isLoading ? (
        <div className="no-advancly-data">
          <Loader type="lg" variant="blue" />
          <div className="mt-3 text-center">
            Fetching investment information...
          </div>
        </div>
      ) : (
        <Fragment>
          <div className="d-flex align-items-center justify-content-between mt-2 mb-4">
            <div className="page-title">{data?.name}</div>
            <div>
              <Button
                className="mr-2"
                type="default"
                size="large"
                disabled={disabled}
                onClick={() => {
                  setIsShowInterestVisible(true);
                  resetInvestment();
                  handleInvestOrShowInterest();
                }}
              >
                Show Interest
              </Button>
              <Button
                size="large"
                type={!isLockedAccount() ? "primary" : "default"}
                danger={isLockedAccount()}
                data-toggle="modal"
                data-target="#investmentModal"
                disabled={disabled}
                onClick={() => {
                  resetInvestment();
                  handleInvestOrShowInterest();
                }}
              >
                {isLockedAccount() ? "Sold Out" : "Invest Now"}
              </Button>
            </div>
          </div>

          <div className={styles.detailsGrid}>
            {/* Left hand side  */}
            <div>
              <div className="d-flex">
                {data?.investment_product_details?.map(
                  (item: { currency_code: string }, i: number) => (
                    <div
                      className="badge bg-lighter-grey text-uppercase mr-3"
                      key={i}
                    >
                      {item.currency_code} FUND
                    </div>
                  ),
                )}
              </div>
              <div className="mt-4">
                <h5 className="page-subtitle">About this Fund</h5>
                <p>{data?.description}</p>
              </div>
              <div className="mt-4">
                <h5 className="page-subtitle">Investment Objective</h5>
                <p>{data?.objective}</p>
              </div>
              <div className="mt-4">
                <h5 className="page-subtitle">Returns</h5>
              </div>

              <div className="mt-4">
                <div className="alignToggleTabItems mb-3">
                  {data?.investment_product_details?.map(
                    (item: { currency_code: string }, i: number) => (
                      <ToggleTab
                        text={`${item.currency_code} Investment`}
                        id={item.currency_code}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        key={i}
                      />
                    ),
                  )}
                </div>
              </div>
              {/* Term Sheet Table  */}
              <InvestmentTermSheet termSheetDetails={termSheetDetails} />
            </div>
            {/* Right Hand Side  */}
            <div>
              <div className="card p-3">
                <h6 className="font-weight-bold mb-3">Investment Details:</h6>
                <div className={styles.fancyPoint}>
                  <div className="font-weight-bold">Issuer</div>
                  <div>{data?.issuers}</div>
                </div>
                <hr />
                <div className={styles.fancyPoint}>
                  <div className="font-weight-bold">Target Investors</div>
                  <div>{data?.target_investors}</div>
                </div>
                <hr />
                <div className={styles.fancyPoint}>
                  <div className="font-weight-bold">Tenor</div>
                  {termSheetDetails.isLoading ? (
                    <Loader />
                  ) : (
                    <Fragment>
                      {investmentTenor?.length && (
                        <div>{investmentTenor?.join(", ")}</div>
                      )}
                    </Fragment>
                  )}
                </div>
                <hr />
                <div className={styles.fancyPoint}>
                  <div className="font-weight-bold">Subscription Period</div>
                  <div>
                    {formatDateFull(data?.start_date)} to{" "}
                    {formatDateFull(data?.end_date)}
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <h6 className="font-weight-bold">
                  Want to learn more about this Fund?
                </h6>
                {isLoadingBase64 ?
                <p>Loading Term Sheet.....</p>
                    :
                    <>
                    {data?.term_sheet_url?.toLowerCase()?.endsWith(".pdf") ?
                        <Button type="primary" className="tw-my-2" onClick={() => setShowPdfModal(true)}>View Term
                          Sheet
                        </Button> :
                        data?.term_sheet_url?.toLowerCase()?.endsWith(".jpeg") || data?.term_sheet_url?.toLowerCase()?.endsWith(".jpg") || data?.term_sheet_url?.toLowerCase()?.endsWith(".png") ? <Image src={`${CONFIG.ADVANCLY_STORAGE_URL}${data?.term_sheet_url}`}  /> : null
                    }

                      <div
                          className={`color-blue text-underline cursor-pointer ${
                              (isDownloading || disabled) && "cursor-disabled"
                          }`}
                          onClick={downloadtermSheet}
                      >
                        Download Term Sheet {isDownloading && <Loader/>}
                      </div>
                    </>
                }

              </div>
            </div>
          </div>
        </Fragment>
      )}
      <InvestmentModal/>
      {isShowInterestVisible && (
        <ShowInterestModal
          isModalOpen={isShowInterestVisible}
          handleCancel={() => setIsShowInterestVisible(false)}
          lcyProductDetailsId={lcyProductDetailsId}
          usdProductDetailsId={usdProductDetailsId}
        />
      )}
      <PDFViewer url={base64Pdf} open={showPdfModal} onCancel={() => setShowPdfModal(false)} />
    </div>
  );
}
