import { useQuery } from "react-query";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { getData } from "../../apis/apiMethods";

type paramProps = {
  page: number;
  size: number;
};

const getUsers = async ({ queryKey }: any) => {
  const { page, size } = queryKey[1];
  const data = await getData(
    `${apiEndpoints.USERS}?limit=${size}&offset=${Number(page - 1) * size}`
  );
  return data;
};

export default function useUsers({ page, size }: paramProps) {
  return useQuery(["getUsers", { page, size }], getUsers);
}
