import React from "react";
import styles from "./SectorsGridDetailsLeft.module.scss";
import FinancialServicesImage from "../../assets/img/sectors_images/financial-services.png";
import DummyChart from "../../assets/img/dummy-chart.png";
import InvestmentModal from "../InvestmentModal/InvestmentModal.component";
import WarningIcon from "../WarningIcon/WarningIcon.component";

export default function SectorsGridDetailsLeft() {
  return (
    <div>
      <div className={styles.top}>
        <img src={FinancialServicesImage} alt="Sector Avatar" />
        <div className={styles.details}>
          <div className={styles.item}>
            <div className={styles.itemTop}>Est. Annual Interest</div>
            <div className={styles.itemBottom}>15.6%</div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemTop}>Customer base</div>
            <div className={styles.itemBottom}>4,500,000</div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemTop}>
              NPL Score <WarningIcon />
            </div>
            <div className={styles.itemBottom}>15%</div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemTop}>
              Risk Level <WarningIcon />
            </div>
            <div className={styles.itemBottom}>High</div>
          </div>
          <button
            className="btn btn-sm advancly-btn"
            data-toggle="modal"
            data-target="#investmentModal"
          >
            Invest
          </button>
        </div>
      </div>
      <div className="mt-4">
        <h5 className="page-subtitle">About this sector</h5>
        <p>
          The Financial services sector is lorem ipsum dolor sit amet,
          consectetur adipiscing elit sed do eiusmod do eiusmod tempor
          incididunt ut labore et dolore magna aliqua.
        </p>
      </div>

      <div className={`${styles.bottom} mt-4`}>
        <div className="title-align">
          <h5 className="page-subtitle">
            Market Performance{" "}
            <i className="ri-error-warning-line color-orange font-weight-normal"></i>
          </h5>
          <div className={styles.titleRightAlign}>
            <div className={styles.toggleTab}>Week</div>
            <div className={styles.toggleTab}>Month</div>
            <div className={`${styles.toggleTab} ${styles.activeTab}`}>
              Quarter
            </div>
            <div className={styles.toggleTab}>Year</div>
            <div className={styles.toggleTab}>5 Years</div>
          </div>
        </div>
        <img src={DummyChart} alt="Replace With Chart" />
      </div>
      <InvestmentModal />
    </div>
  );
}
