import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// @ts-ignore
import { encryptTransform } from "redux-persist-transform-encrypt";
import { userReducer } from "./userReducer";
import { signUpReducer } from "./signUpReducer";
import { investmentReducer } from "./investmentReducer";
import { withdrawalReducer } from "./withdrawalReducer";
import { walletTopupReducer } from "./walletTopupReducer";
import { resetPasswordReducer } from "./resetPasswordReducer";
import { userCurrenciesReducer } from "./userAvailableCurrenciesReducer";

const persistWithConfig = {
  key: "root",
  storage,
  transforms: [
    encryptTransform({
      secretKey: "this_is_actually_a_very_secretive_password",
      onError: function () {
        // console.log(error);
      },
    }),
  ],
};

const persistCurrenciesWithConfig = {
  key: "currencies",
  storage,
  transforms: [
    encryptTransform({
      secretKey: "this_is_actually_a_very_secretive_password_for_currencies",
      onError: function () {
        // console.log(error);
      },
    }),
  ],
};

const reducers = combineReducers({
  user: persistReducer(persistWithConfig, userReducer),
  currencies: persistReducer(
    persistCurrenciesWithConfig,
    userCurrenciesReducer
  ),
  signUp: signUpReducer,
  investment: investmentReducer,
  withdrawal: withdrawalReducer,
  walletTopup: walletTopupReducer,
  resetPassword: persistReducer(persistWithConfig, resetPasswordReducer),
});

export default reducers;

export type TRootState = ReturnType<typeof reducers>;
