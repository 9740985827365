import { useQuery } from "react-query";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { postData } from "./../../apis/apiMethods";

type paramProps = {
  bankCode: string;
  accountNumber: string;
};

const validateBankAccount = async ({ queryKey }: any) => {
  const { bankCode, accountNumber } = queryKey[1];
  const data = await postData(apiEndpoints.VALIDATE_BANK_ACCOUNT, {
    bank_code: bankCode,
    bank_account_num: accountNumber,
  });
  return data?.bank_account_name;
};

export default function useValidateBankAccount({
  bankCode,
  accountNumber,
}: paramProps) {
  return useQuery(
    ["validateBankAccount", { bankCode, accountNumber }],
    validateBankAccount,
    {
      enabled: bankCode && accountNumber?.length === 10 ? true : false,
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );
}
