import { TPAYMENTS } from "../interfaces/payments";

export const PAYMENT_OPTIONS: {
  PAYSTACK: TPAYMENTS;
  BANK_TRANSFER: TPAYMENTS;
  VGN_PAYMENT_CORE: TPAYMENTS;
  WALLET: TPAYMENTS;
} = {
  PAYSTACK: "Paystack",
  BANK_TRANSFER: "BankTransfer",
  VGN_PAYMENT_CORE: "VGNPaymentCore",
  WALLET: "Wallet",
};
