export const mockUserAvailableCurrencies = {
  data: [
    {
      currencyType: "Local",
      currencyCode: "KSH",
    },
    {
      currencyType: "Foreign",
      currencyCode: "USD",
    },
  ],
};
