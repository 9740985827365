import { useRef, useState } from "react";
import { useTypedSelector } from "./../../hooks/redux-hooks/useTypedSelector";
import { selectInvestmentType } from "./../../redux/selectors/investmentSelector";
import { InvestmentLanding } from "./InvestmentLanding/InvestmentLanding.component";
import OnlineInvestmentLanding from "./OnlineInvestment/OnlineInvestmentLanding.component";
import OfflineInvestmentLanding from "./OfflineInvestment/OfflineInvestmentLanding.component";
import { useActions } from "./../../hooks/redux-hooks/useActions";
import Alert from "../Alert/Alert.component";

export default function InvestmentModal() {
  const dismissRef = useRef<null | HTMLButtonElement>(null);
  const investmentType = useTypedSelector(selectInvestmentType);
  const { resetInvestment } = useActions();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const closeModal = () => {
    dismissRef?.current?.click();
    resetInvestment();
  };

  return (
    <div
      className="modal fade"
      id="investmentModal"
      tabIndex={-1}
      aria-labelledby="investmentModalLabel"
      aria-hidden="true"
    >
      {error && <Alert message={error} />}
      {success && <Alert message={success} type="success" />}
      <div className="modal-dialog">
        <div className="modal-content p-4">
          <div className="modal-body">
            <button
              type="button"
              className="d-none"
              data-dismiss="modal"
              aria-label="Close"
              ref={dismissRef}
            >
              <span aria-hidden="true">&times;</span>
            </button>

            {(() => {
              switch (investmentType) {
                case "":
                  return (
                    <InvestmentLanding
                      closeModal={closeModal}
                      setError={setError}
                    />
                  );
                case "online":
                  return (
                    <OnlineInvestmentLanding
                      closeModal={closeModal}
                      error={error}
                      setError={setError}
                      success={success}
                      setSuccess={setSuccess}
                    />
                  );
                case "offline":
                  return (
                    <OfflineInvestmentLanding
                      closeModal={closeModal}
                      error={error}
                      setError={setError}
                      success={success}
                      setSuccess={setSuccess}
                    />
                  );
                default:
                  return null;
              }
            })()}
          </div>
        </div>
      </div>
    </div>
  );
}
