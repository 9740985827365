import { useQuery } from "react-query";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { getData } from "../../apis/apiMethods";

type paramProps = {
  targetId: number;
};

const getWalletAccountDetails = async ({ queryKey }: any) => {
  const { targetId } = queryKey[1];
  const { data } = await getData(
    `${apiEndpoints.GET_WALLET_ACCOUNT}?ConsumerType=Customer&TargetId=${targetId}`
  );
  return data;
};

export default function useWalletAccountDetails({ targetId }: paramProps) {
  return useQuery(
    ["getWalletAccountDetails", { targetId }],
    getWalletAccountDetails,
    {
      refetchOnWindowFocus: false, // Refetch on window focus
    }
  );
}
