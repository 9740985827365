import React, { Dispatch, Fragment, SetStateAction } from "react";
import { getAdvanclyTranRef } from "../../../../../helpers/generalUtils";
import Loader from "../../../../Loader/Loader.component";
import formatMoney from "./../../../../../helpers/formatMoney";
import { useTypedSelector } from "./../../../../../hooks/redux-hooks/useTypedSelector";
import { useActions } from "./../../../../../hooks/redux-hooks/useActions";
import {
  selectOnlineInvestment,
  selectSelectedInvestment,
} from "./../../../../../redux/selectors/investmentSelector";

interface IInvestmentModalStepThreeConfirm {
  setSection: Dispatch<SetStateAction<"initiate" | "confirm" | "success">>;
  setError: Dispatch<SetStateAction<string>>;
  error: string;
}

const InvestmentModalStepThreeConfirm: React.FC<IInvestmentModalStepThreeConfirm> =
  ({ setSection, setError, error }) => {
    const investmentDetails = useTypedSelector(selectOnlineInvestment);
    const selectedInvestment = useTypedSelector(selectSelectedInvestment);
    const { setOnlineInvestmentInfo } = useActions();

    const { currency, amount } = investmentDetails;

    const { name } = selectedInvestment;

    const isWalletSufficient = true;
    const loading = false;
    const onSubmit = () => {
      const advanclyTranRef = getAdvanclyTranRef();
      setOnlineInvestmentInfo({
        advanclyTranRef,
        paymentGatewayReference: advanclyTranRef,
      });
      setSection("success");
    };

    return (
      <div>
        <h5 className="page-subtitle mb-3">Pay from Wallet</h5>
        <hr />
        <div className="d-flex text-center justify-content-center py-5 px-3">
          {loading ? (
            <Loader type="lg" variant="blue" />
          ) : error ? (
            <h6 className="color-red font-weight-bold">
              An error occured while processing information, please try again.
            </h6>
          ) : !isWalletSufficient ? (
            <Fragment>
              You do not have sufficient funds in your Naira Wallet for this
              transaction
            </Fragment>
          ) : (
            <div>
              You are about to make an investment of{" "}
              <span className="font-weight-bold">
                {currency && formatMoney(Number(amount), currency)}
              </span>{" "}
              into <span className="font-weight-bold">{name}</span> funding,
              please click{" "}
              <span className="font-weight-bold">Confirm Payment</span> to
              proceed.
            </div>
          )}
        </div>
        <div className="mt-5">
          <button
            type="submit"
            className="btn btn-sm advancly-btn mb-3 w-100"
            disabled={!isWalletSufficient || loading}
            onClick={onSubmit}
          >
            Confirm Payment
          </button>
          <button
            type="button"
            className="btn btn-sm advancly-nobg-btn w-100"
            onClick={() => setSection("initiate")}
          >
            Go back
          </button>
        </div>
      </div>
    );
  };
export default InvestmentModalStepThreeConfirm;
