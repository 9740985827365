import { useQuery } from "react-query";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { getData } from "../../apis/apiMethods";

const getInvestments = async () => {
  const { data } = await getData(
    `${apiEndpoints.AVAILABLE_INVESTMENTS}?status=${1}`
  );
  return data;
};

export default function useInvestments() {
  return useQuery("getInvestments", getInvestments, {
    refetchInterval: 5000 * 60, // refetch data every five minutes
  });
}
