import { createSelector } from "reselect";
import { TRootState } from "../reducers";

const selectState = (state: TRootState) => state.user;

// Output Selectors
export const selectUser = createSelector([selectState], ({ user }) => user);
export const selectShowBalance = createSelector(
  [selectState],
  ({ showBalance }) => showBalance
);
export const selectUserId = createSelector(
  [selectState],
  ({ user }) => user.funder_id
);
export const selectUserType = createSelector(
  [selectState],
  ({ user }) => user.funder_type_id
);
export const selectUserRole = createSelector(
  [selectState],
  ({ user }) => user.role
);
export const selectUserEmail = createSelector(
  [selectState],
  ({ user }) => user.email
);
export const selectUserCountry = createSelector(
  [selectState],
  ({ user }) => user.country
);
export const selectUserWallets = createSelector([selectState], ({ user }) => {
  return {
    lcy_wallet_id: user?.lcy_wallet_id,
    usd_wallet_id: user?.usd_wallet_id,
  };
});

export const selectToken = createSelector([selectState], (user) => user.token);
export const selectUserActivationStatus = createSelector(
  [selectState],
  ({ user }) => user.activated
);
export const selectForcePasswordChange = createSelector(
  [selectState],
  ({ user }) => user.forcePasswordChange
);
export const selectKycName = createSelector(
  [selectState],
  ({ user }) =>
    `${user?.kyc_resolved_firstname || ""} ${user?.kyc_resolved_lastname || ""}`
);
export const selectUserCurrencies = createSelector(
  [selectState],
  ({ user }) => {
    return user.availableCurrencies;
  }
);
