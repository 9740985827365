import { TUSER_TYPES } from "../interfaces/userTypes";

export const USER_TYPES: {
  FUNDER_USER: TUSER_TYPES;
  FUNDER_ADMIN: TUSER_TYPES;
} = {
  FUNDER_USER: "funder_user",
  FUNDER_ADMIN: "funder_admin",
};

export const formatUserType = (userType: TUSER_TYPES): string => {
  if (userType === USER_TYPES.FUNDER_ADMIN) {
    return "Admin";
  } else if (userType === USER_TYPES.FUNDER_USER) {
    return "User";
  } else {
    return userType;
  }
};
