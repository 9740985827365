import React, { useEffect } from "react";
import styles from "../../InvestmentModal/InvestmentLanding/InvestmentLanding.module.scss";
import { appInsights } from "../../AppInsight/AppInsight";

interface IProps {
  type: "wallet" | "bankTransfer" | undefined;
  setType: (type: "wallet" | "bankTransfer") => void;
}
const PaymentMethodModal = ({ type, setType }: IProps) => {
  useEffect(() => {
    appInsights.trackPageView({
      name: "PaymentMethodModal.tsx",
      isLoggedIn: true,
    });
  }, []);
  return (
    <>
      <p>How would your like to proceed with payment?</p>
      <div
        className={`${styles.modalCard} ${type === "wallet" && styles.active}`}
        onClick={() => setType("wallet")}
      >
        <div className={styles.radioButton}></div>
        <div>
          <h6 className="font-weight-bold">Fund with your wallet</h6>
        </div>
      </div>

      <div
        className={`${styles.modalCard} ${
          type === "bankTransfer" && styles.active
        }`}
        onClick={() => setType("bankTransfer")}
      >
        <div className={styles.radioButton}></div>
        <div>
          <h6 className="font-weight-bold">Fund with Bank Transfer</h6>
        </div>
      </div>
    </>
  );
};

export default PaymentMethodModal;
