import React from "react";
import styles from "./KycDocumentCard.module.scss";
import { useHistory } from "react-router-dom";
import { DocumentStatusIcon } from "../DocumentStatusIcon/DocumentStatusIcon.component";
import { getDocumentUploadStatus } from "../../helpers/getDocumentUploadStatus";

interface KycDocumentCardProps {
  data: any;
  field: string;
  route: string;
  iconClass: string;
  title: string;
  subTitle: string;
}

export const KycDocumentCard: React.FC<KycDocumentCardProps> = ({
  data,
  field,
  route,
  iconClass,
  title,
  subTitle,
}) => {
  const history = useHistory();
  const { failed, none } = getDocumentUploadStatus({
    data,
    field,
  });
  return (
    <div
      className={styles.kycCard}
      onClick={() => (failed || none ? history.push(route) : null)}
    >
      <div className={styles.top}>
        <div className={styles.iconArea}>
          <i className={iconClass}></i>
        </div>
        <div className={styles.right}>
          <DocumentStatusIcon data={data} field={field} />
        </div>
      </div>
      <div>
        <div className="font-weight-bold h4 mb-3">{title}</div>
        <div className={styles.infoText}>{subTitle}</div>
      </div>
    </div>
  );
};
