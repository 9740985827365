import StepTwo from "./StepTwo/StepTwo.component";
import StepOne from "./StepOne/StepOne.component";
import LandingLayout from "../../components/LandingLayout/LandingLayout.component";
import { useTypedSelector } from "./../../hooks/redux-hooks/useTypedSelector";
import StepThree from "./StepThree/StepThree";
import { useEffect, useState } from "react";
import { funderTypes } from "../../helpers/funderTypes";
import { appInsights } from "../../components/AppInsight/AppInsight";

const Signup = () => {
  const { step } = useTypedSelector((state) => state.signUp);
  const [funderType, setFunderType] = useState(funderTypes.CORPORATE_FUNDER);
  useEffect(() => {
    appInsights.trackPageView({ name: "Signup.tsx", isLoggedIn: true });
  }, []);
  return (
    <LandingLayout>
      {(() => {
        switch (step) {
          case 1:
            return <StepOne />;
          case 2:
            return (
              <StepTwo funderType={funderType} setFunderType={setFunderType} />
            );
          case 3:
            return (
              <StepThree
                funderType={funderType}
                setFunderType={setFunderType}
              />
            );
          default:
            return null;
        }
      })()}
    </LandingLayout>
  );
};

export default Signup;
