import React from 'react';
// @ts-ignore
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { QueryClient, QueryClientProvider } from "react-query";
import "react-notification-alert/dist/animate.css";
import { ConfigProvider } from "antd";
import "./index.css";

const queryClient = new QueryClient();
const persistor = persistStore(store);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            {/*@ts-ignore*/}
            <PersistGate loading={null} persistor={persistor}>
                <QueryClientProvider client={queryClient}>
                    <ConfigProvider
                        theme={{
                            token: {
                                // Seed Token
                                colorPrimary: "#713fff",
                                borderRadius: 6,
                            },
                        }}
                    >
                        <App />
                    </ConfigProvider>
                </QueryClientProvider>
            </PersistGate>
        </Provider>
    </React.StrictMode>
);

