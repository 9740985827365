import { TAction } from "../actions";
import { actionType } from "../actionTypes";
import produce from "immer";
import { TResetPassword } from "../../interfaces/resetPassword";

export const initialState = {
  email: "",
  stateToken: "",
};

export const resetPasswordReducer = produce(
  (state: TResetPassword = initialState, action: TAction): TResetPassword => {
    switch (action.type) {
      // Set During Reset Password
      case actionType.SET_RESET_PASSWORD_INFO:
        state = { ...action.payload };
        return state;
      default:
        return state;
    }
  }
);
