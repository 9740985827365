import ContactForm from "../../components/ContactForm/ContactForm.component";
import styles from "./ContactPage.module.scss";
import { BackButton } from "../../components/BackButton/BackButton.component";
import { useEffect } from "react";
import { appInsights } from "../../components/AppInsight/AppInsight";

export default function ContactPageSendEmail() {
  useEffect(() => {
    appInsights.trackPageView({
      name: "ContactPageSendEmail.tsx",
      isLoggedIn: true,
    });
  }, []);
  return (
    <div>
      <h3 className="page-title">Contact Support</h3>
      <div className={styles.contactAlign}>
        <div>
          <div className="color-blue my-3">
            <BackButton text="Go Back" />
          </div>
          <ContactForm />
        </div>
      </div>
    </div>
  );
}
