import { useQuery } from "react-query";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { getData } from "../../apis/apiMethods";

type paramProps = {
  countryId: number;
};

const getCountryStates = async ({ queryKey }: any) => {
  const { countryId } = queryKey[1];
  const { data } = await getData(`${apiEndpoints.COUNTRY_STATES}/${countryId}`);
  return data;
};

export default function useCountryStates({ countryId }: paramProps) {
  return useQuery(["getCountryStates", { countryId }], getCountryStates, {
    refetchOnWindowFocus: false, // Refetch on window focus
    enabled: countryId ? true : false,
  });
}
