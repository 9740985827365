import * as yup from "yup";

// Regular expression for phone number
export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const numberRegExp = /[0-9]/;
export const yupValidators = {
  newPassword: yup
    .string()
    .required("Please enter your new password")
    .min(8, "Password must have at least 8 characters")
    .max(128, "Password cannot be longer than 128 characters")
    .matches(/[^A-Za-z0-9]/, "Password must contain a special character")
    .matches(/[A-Z]/, "Password must contain an uppercase alphabet")
    .matches(/[a-z]/, "Password must contain a lowercase alphabet")
    .matches(numberRegExp, "Password must contain a number"),
  confirmPassword: yup
    .string()
    .required("Please confirm your new password")
    .min(8, "Password must have at least 8 characters")
    .max(128, "Password cannot be longer than 128 characters")
    .matches(/[^A-Za-z0-9]/, "Password must contain a special character")
    .matches(/[A-Z]/, "Password must contain an uppercase alphabet")
    .matches(/[a-z]/, "Password must contain a lowercase alphabet")
    .matches(numberRegExp, "Password must contain a number"),
  phoneNumber: yup
    .string()
    .trim()
    .required("Please enter your phone number")
    .min(7, "Phone number must have at least 7 digits")
    .max(20, "Phone number cannot have more than 20 digits")
    .matches(phoneRegExp, "Invalid Phone Number"),
  email: yup
    .string()
    .trim()
    .email("Please enter a valid email address")
    .required("Please enter your email address")
    .min(5, "Email must have at least 5 characters")
    .max(128, "Email cannot have more than 128 characters"),
  businessCategory: yup
    .string()
    .trim()
    .required("Please select a business sector"),
  otp: yup
    .string()
    .required("Please enter OTP")
    .min(4, "OTP must have at least 4 characters")
    .max(10, "OTP cannot have more than 10 characters")
    .matches(numberRegExp, "Invalid OTP"),
  fullName: yup
    .string()
    .trim()
    .required("Please enter your name")
    .min(2, "Name must have at least 2 characters")
    .max(60, "Name cannot be longer than 60 characters"),
  contactMessage: yup
    .string()
    .trim()
    .required("Please enter your message")
    .min(10, "Message must have at least 10 characters")
    .max(300, "Message cannot be longer than 300 characters"),
  investmentName: yup
    .string()
    .trim()
    .required("Please enter investment name")
    .min(2, "Investment name must have at least 2 characters")
    .max(128, "Investment name cannot be longer than 128 characters"),
  investmentAmount: yup
    .string()
    .required("Please enter investment amount")
    .matches(/[0-9]/, "Please enter a valid amount")
    .max(20, "Investment amount cannot have more than 20 digits"),
  genericRequired: ({
    message,
    min,
    max,
  }: {
    message?: string;
    min?: number;
    max?: number;
  }) => {
    return yup
      .string()
      .trim()
      .required(message ? message : "This field is required")
      .min(min || 0, `This field must have at least ${min} characters`)
      .max(
        max || Infinity,
        `This field cannot be longer than ${max} characters`
      );
  },
  genericRequiredNumber: ({
    message,
    min,
    max,
    matches,
  }: {
    message?: string;
    min?: number ;
    max?: number;
    matches?: string;
  }) => {
    return yup
      .string()
      .trim()
      .required(message ? message : "This field is required ")
      .matches(numberRegExp, matches ? matches : "Invalid number")
      .min(min || 0, `This field must have at least ${min} characters`)
      .max(
        max || Infinity,
        `This field cannot be longer than ${max} characters`
      );
  },
  generic: ({ min, max }: { min?: number; max?: number }) => {
    return yup
      .string()
      .trim()
      .min(min || 0, `This field must have at least ${min} characters`)
      .max(
        max || Infinity,
        `This field cannot be longer than ${max} characters`
      );
  },
};
