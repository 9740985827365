import { useQuery } from "react-query";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { getData } from "../../apis/apiMethods";

type paramProps = {
  funderInvestmentId: number;
};

const getPortfolio = async ({ queryKey }: any) => {
  const { funderInvestmentId } = queryKey[1];
  const { data } = await getData(
    `${apiEndpoints.PORTFOLIO}?funderInvestmentId=${funderInvestmentId}`
  );
  return data;
};

export default function usePortfoilio({ funderInvestmentId }: paramProps) {
  return useQuery(["getPortfolio", { funderInvestmentId }], getPortfolio, {
    refetchOnWindowFocus: false, // Refetch on window focus
  });
}
