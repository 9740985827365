import React from "react";
import { yupValidators } from "../../../helpers/yupValidators";
import CustomInputField from "../../CustomHTMLElements/CustomInputField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTypedSelector } from "../../../hooks/redux-hooks/useTypedSelector";
import { useActions } from "../../../hooks/redux-hooks/useActions";
import { selectWithdrawal } from "../../../redux/selectors/withdrawalSelector";
import { handleFormatAmount } from "../../../helpers/formatAmount";

interface IWithdrawModalStepOne {
  closeModal: () => void;
}

const schema = yup.object().shape({
  withdrawalAmount: yupValidators.genericRequiredNumber({
    message: "Please enter the amount you want to withdraw",
    matches: "Please enter a valid amount",
    max: 20,
  }),
});

const WithdrawalModalStepOne: React.FC<IWithdrawModalStepOne> = ({
  closeModal,
}) => {
  const withdrawalInfo = useTypedSelector(selectWithdrawal);
  const { setWithdrawalInfo } = useActions();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: {
      withdrawalAmount: withdrawalInfo.amount,
    },
  });

  const onSubmit = ({ withdrawalAmount }: { withdrawalAmount: number }) => {
    console.log({ withdrawalAmountBeforeFormat: withdrawalAmount });
    console.log({
      withdrawalAmountAfterFormat: handleFormatAmount(String(withdrawalAmount)),
    });
    setWithdrawalInfo({
      ...withdrawalInfo,
      amount: handleFormatAmount(String(withdrawalAmount)),
      currentStep: 2,
    });
  };
  return (
    <div>
      <div className="modal-header p-0 mb-4">
        <h5 className="page-subtitle">Withdrawal Amount</h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={closeModal}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomInputField
          type="number"
          enableSeparator
          defaultValue={withdrawalInfo.amount}
          maxLength={20}
          {...register("withdrawalAmount")}
          placeholder="e.g. 100,000.00"
          label="Enter Amount"
          errors={errors.withdrawalAmount}
        />

        <div className="d-flex justify-content-between mt-5 pt-5">
          <button
            type="button"
            className="btn btn-sm advancly-white-btn w-50 mr-2"
            onClick={closeModal}
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-sm advancly-btn w-50 ml-2">
            Continue
          </button>
        </div>
      </form>
    </div>
  );
};
export default WithdrawalModalStepOne;
