import React, { useEffect } from "react";
import { BackButton } from "../../../components/BackButton/BackButton.component";
import { FilePicker } from "../../../components/FilePicker/FilePicker.component";
import CONFIG from "../../../helpers/config";
import { appInsights } from "../../../components/AppInsight/AppInsight";

interface ProfileSettingsProofOfAddressIndividualProps {}

const DROPDOWN_OPTIONS = [
  { value: "Electricity Bill", optionText: "Electricity Bill" },
  { value: "Water Bill", optionText: "Water Bill" },
  { value: "Waste Bill", optionText: "Waste Bill" },
  { value: "Others", optionText: "Others" },
];

export const ProfileSettingsProofOfAddressIndividual: React.FC<
  ProfileSettingsProofOfAddressIndividualProps
> = () => {
  useEffect(() => {
    appInsights.trackPageView({
      name: "ProfileSettingsProofOfAddressIndividual.tsx",
      isLoggedIn: true,
    });
  }, []);
  return (
    <div>
      <BackButton text="Go back" />

      <div className="mt-4">
        <FilePicker
          DROPDOWN_OPTIONS={DROPDOWN_OPTIONS}
          document_type_code={CONFIG.DOCUMENT_CODES.proofOfAddressIndividual}
        />
      </div>
    </div>
  );
};
