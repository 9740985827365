import React, { useEffect, useState } from "react";
import Alert from "./../Alert/Alert.component";
import OtherBankAccountUpdate from "./OtherBankAccountUpdate";
import { IBankAccount } from "../../interfaces/bankAccounts";
import { getData } from "../../apis/apiMethods";
import { apiEndpoints } from "../../apis/apiEndpoints";
import PageLoader from "../PageLoader/PageLoader.component";
import { useTypedSelector } from "../../hooks/redux-hooks/useTypedSelector";
import NigeriaBankAccountUpdate from "./NigeriaBankAccountUpdate";
import { currenciesSelector } from "../../redux/selectors/currenciesSelector";
import AccountUpdateOtpModal from "./AccountUpdateOtpModal.component";

interface IProps {
  funder: any;
}

const BankingInformation: React.FC<IProps> = ({ funder }) => {
  const accessibleCurrenciesByLocation: {
    currencyType: "Foreign" | "Local";
    currencyCode: string;
  }[] = useTypedSelector(currenciesSelector);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [bankAccounts, setBankAccounts] = useState<IBankAccount[]>([]);
  const [localCurrencyCode, setLocalCurrencyCode] = useState<
    "KES" | "NGN" | string
  >("");
  const [USDAccountAdded, setUSDAccountAdded] = useState(false);
  const [KESAccountAdded, setKESAccountAdded] = useState(false);
  const [NGNAccountAdded, setNGNAccountAdded] = useState(false);
  const [currencyToAddUpdate, setCurrencyToAddUpdate] = useState("");
  const [showNGNModal, setShowNGNModal] = useState(false);
  const [showUSDKESModal, setShowUSDKESModal] = useState(false);
  const [selectedAccountDetails, setSelectedAccountDetails] = useState<
    IBankAccount | undefined
  >();
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [otp, setOtp] = useState("");

  const handleFetchBankAccounts = async () => {
    setIsLoading(true);
    try {
      const data = await getData(
        `${apiEndpoints.CUSTOMER_BANK_ACCOUNTS}/${funder?.funder_id}/bank_accounts`
      );
      const { bank_accounts } = data.data;
      setBankAccounts(bank_accounts);
    } catch (error) {
      setError("An error occurred");
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    handleFetchBankAccounts();
  }, []);

  function handleSetCurrencies() {
    const localCurrency = accessibleCurrenciesByLocation.find(
      (c) => c.currencyType === "Local"
    );
    const addedUSDAccount = bankAccounts.find((b) => b.currency === "USD");
    const addedKESAccount = bankAccounts.find((b) => b.currency === "KES");
    const addedNGNAccount = bankAccounts.find((b) => b.currency === "NGN");
    if (accessibleCurrenciesByLocation.length === 1) {
      return setCurrencyToAddUpdate(
        accessibleCurrenciesByLocation[0].currencyCode
      );
    }
    if (localCurrency) {
      if (addedUSDAccount) setUSDAccountAdded(true);
      if (addedKESAccount) setKESAccountAdded(true);
      if (addedNGNAccount) setNGNAccountAdded(true);
      setLocalCurrencyCode(localCurrency.currencyCode);
      if (bankAccounts.length === 2) {
        return setCurrencyToAddUpdate("");
      } else if (bankAccounts.length === 0) {
        return setCurrencyToAddUpdate(localCurrency.currencyCode);
      } else if ((addedKESAccount || addedNGNAccount) && !addedUSDAccount) {
        return setCurrencyToAddUpdate("USD");
      } else if (localCurrency.currencyCode === "KES" && !KESAccountAdded) {
        return setCurrencyToAddUpdate("KES");
      } else if (localCurrency.currencyCode === "NGN" && !KESAccountAdded) {
        return setCurrencyToAddUpdate("NGN");
      }
    }
  }

  useEffect(() => {
    handleSetCurrencies();
  }, [bankAccounts]);

  useEffect(() => {
    if (success) {
      setSelectedAccountDetails(undefined);
    }
  }, [success]);

  return (
    <div>
      {error && <Alert message={error} />}
      {success && <Alert message={success} type="success" />}
      <div className="d-flex justify-content-between align-items-center ">
        <div>
          <h5 className="page-subtitle">Linked Bank Accounts</h5>
          <p>
            Your settlement account is the default bank account into which your
            wallet funds will be paid.
          </p>
        </div>

        {isLoading ||
        showOtpModal ||
        showUSDKESModal ||
        showNGNModal ||
        (bankAccounts &&
          bankAccounts?.length === 1 &&
          accessibleCurrenciesByLocation.length === 1) ||
        (bankAccounts &&
          bankAccounts.length === 2) ? null : currencyToAddUpdate ? (
          <div>
            <button
              className="btn advancly-btn"
              type="button"
              onClick={() => setShowOtpModal(true)}
            >
              {`Add ${currencyToAddUpdate} Account`}
            </button>
          </div>
        ) : null}
      </div>

      {isLoading ? (
        <PageLoader />
      ) : bankAccounts && bankAccounts.length > 0 ? (
        <div className="table-responsive">
          <table className="table">
            <thead className="bg-lighter-grey">
              <tr>
                <th>Currency</th>
                <th>Account Name</th>
                <th>Account Number</th>
                <th>Bank Name</th>
                <th>Swift Code</th>
                <th>Routing Number</th>
                <th className="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {bankAccounts &&
                bankAccounts?.length > 0 &&
                bankAccounts.map(
                  (
                    {
                      account_number,
                      bank_name,
                      bank_code,
                      account_name,
                      routing_number,
                      swift_code,
                      currency,
                    }: IBankAccount,
                    index
                  ) => (
                    <tr key={index}>
                      <td>{currency}</td>
                      <td>{account_name}</td>
                      <td>{account_number}</td>
                      <td>{bank_name}</td>
                      <td>{swift_code || "NIL"}</td>
                      <td>{routing_number || "NIL"}</td>
                      <td
                        className="text-center text-underline-hover color-blue cursor-pointer"
                        onClick={() => {
                          setSelectedAccountDetails({
                            account_number,
                            bank_name,
                            bank_code,
                            account_name,
                            routing_number,
                            swift_code,
                            currency,
                          });
                          setCurrencyToAddUpdate(currency);
                          setShowOtpModal(true);
                        }}
                      >
                        Change Account
                      </td>
                    </tr>
                  )
                )}
            </tbody>
          </table>
        </div>
      ) : null}

      {showOtpModal ? (
        <AccountUpdateOtpModal
          handleCancel={() => {
            handleSetCurrencies();
            setOtp("");
            setShowOtpModal(false);
          }}
          isModalOpen={showOtpModal}
          handleProceed={() => {
            setShowOtpModal(false);
            if (
              currencyToAddUpdate === "KES" ||
              currencyToAddUpdate === "USD"
            ) {
              setShowUSDKESModal(true);
            } else {
              setShowNGNModal(true);
            }
          }}
          otp={otp}
          setOtp={setOtp}
        />
      ) : null}

      {showNGNModal ? (
        <NigeriaBankAccountUpdate
          setError={setError}
          setSuccess={(msg) => {
            setSuccess(msg);
            handleFetchBankAccounts();
          }}
          isModalOpen={showNGNModal}
          handleCancel={() => {
            handleSetCurrencies();
            setShowNGNModal(false);
            setSelectedAccountDetails(undefined);
          }}
          bankAccount={selectedAccountDetails}
          otp={otp}
        />
      ) : null}
      {showUSDKESModal ? (
        <OtherBankAccountUpdate
          isModalOpen={showUSDKESModal}
          handleCancel={() => {
            handleSetCurrencies();
            setShowUSDKESModal(false);
            setSelectedAccountDetails(undefined);
          }}
          setError={setError}
          setSuccess={(msg) => {
            handleFetchBankAccounts();
            setSuccess(msg);
          }}
          currency={currencyToAddUpdate}
          bankAccount={selectedAccountDetails}
          otp={otp}
        />
      ) : null}
    </div>
  );
};
export default BankingInformation;
