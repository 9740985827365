import { TAction } from "../actions";
import { actionType } from "../actionTypes";
import produce from "immer";
import {
  TWithdrawal,
  TWithdrawalStep,
  TWithdrawalCurrency,
  TWithdrawalAccountDetails,
} from "../../interfaces/withdrawal";

export const initialState: {
  currentStep: TWithdrawalStep;
  currency: TWithdrawalCurrency;
  accountDetails: TWithdrawalAccountDetails;
  comment?: string;
  walletId: number | null;
  amount: number | "";
} = {
  currentStep: 1,
  currency: "",
  walletId: null,
  amount: "",
  accountDetails: {
    type: "",
    accountNumber: "",
    bankId: "",
    accountName: "",
  },
  comment: "",
};

export const withdrawalReducer = produce(
  (state: TWithdrawal = initialState, action: TAction): TWithdrawal => {
    switch (action.type) {
      case actionType.SET_WITHDRAWAL_INFO:
        state = {
          ...state,
          ...action.payload,
        };
        return state;

      case actionType.RESET_WITHDRAWAL:
        return initialState;
      default:
        return state;
    }
  }
);
