import { Button, Input, Tooltip } from "antd";
import React from "react";
import { CopyOutlined } from "@ant-design/icons";
import { useTypedSelector } from "../../hooks/redux-hooks/useTypedSelector";
import { selectUser } from "../../redux/selectors/userSelector";
import { handleCopy } from "../../helpers/generalUtils";

const ReferralLinkDisplay = () => {
  const user = useTypedSelector(selectUser);
  return (
    <>
      <h5 className="page-subtitle">Referral Link</h5>
      <br />
      <div className="tw-flex">
        <Input.Group compact>
          <Input
            style={{ width: "calc(100% - 150px)" }}
            defaultValue={
              user?.referral_link
            }
            readOnly
          />
          <Tooltip title="copy referral link">
            <Button
              icon={<CopyOutlined />}
              onClick={() =>
                handleCopy(
                  user?.referral_link
                )
              }
            />
          </Tooltip>
          <Button
            type="primary"
            className="tw-ml-2"
            onClick={() =>
              handleCopy(
                user?.referral_link
              )
            }
          >
            Copy Link
          </Button>
        </Input.Group>
      </div>
    </>
  );
};

export default ReferralLinkDisplay;
