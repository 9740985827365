import React, {useEffect, useState} from "react";
import LandingLayout from "../../components/LandingLayout/LandingLayout.component";
import { Button, Form, Input, message } from "antd";
import {postData} from "../../apis/apiMethods";
import {apiEndpoints} from "../../apis/apiEndpoints";
import {errorHandler} from "../../helpers/errorHandler";
import {logAppInsightErrors, logAppInsightPageView} from "../../helpers";

const ReferredUserSignup = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);

  const referralCode = params.get('referralCode');

  useEffect(() => {
    console.log({
      params, referralCode
    })
    if (referralCode){
      form.setFieldValue('referralCode', referralCode)
    }
  }, [ params, referralCode]);

  useEffect(() => {
    logAppInsightPageView('ReferredUserSignup', false)
  }, []);

  const onFinish = async ({email}: { email: string }) => {
    setIsLoading(true);
    try {
      await postData(apiEndpoints.REFERRALS, {email, referralCode});
      message
          .success("Email registered successfully.")
          .then(() => {
            window.location.href = "https://onelink.to/u3b6c6";
          });
    } catch (error) {
      message.error(errorHandler(error))
      logAppInsightErrors(error, 'ReferredUserSignup')
    } finally {
      setIsLoading(false)
    }
  };

  return (
    <LandingLayout>
      <div className="my-5">
        <h3 className="h3 font-weight-bold mb-3 color-background-purple">
          Welcome to Advest.
        </h3>
        <p>Enter your email address to continue.</p>
        <br />
        <Form
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
          form={form}
        >
          <Form.Item
            label="Referral Code"
            name="referralCode"
          >
            <Input readOnly={true} />
          </Form.Item>
          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              { required: true, message: "Please input your email!" },
              {
                type: "email",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <br />
          <Button loading={isLoading} type="primary" htmlType="submit" block>
            Get the app
          </Button>
        </Form>
      </div>
    </LandingLayout>
  );
};

export default ReferredUserSignup;
