import React, {ReactNode, useEffect} from "react";
import {
  Form,
  Input,
  InputNumber,
  Button,
  Select,
  Checkbox,
  Radio,
  FormInstance,
} from "antd";
import { FormProps, Rule } from "antd/lib/form";

interface IFieldConfig {
  name: string;
  label: string | ReactNode;
  type:
    | "text"
    | "number"
    | "select"
    | "checkbox"
    | "radio"
    | "searchableSelect"
    | "custom";
  rules?: Rule[];
  options?: { id: any; name: string }[];
  formatter?: (value?: number | string | undefined) => string;
  parser?: (value?: string | undefined) => number;
  initialValue?: string | number;
  customNode?: React.ReactNode;
  handleChange?: (value: any) => void;
}

export interface IFormProps {
  fields: IFieldConfig[];
}

interface IFormConfig {
  fields: IFieldConfig[];
  onFinish: FormProps["onFinish"];
  onFinishFailed: FormProps["onFinishFailed"];
  formRef?: React.MutableRefObject<FormInstance<any> | null>; // Changed to MutableRefObject
  children?: ReactNode; // Optional children to render custom UI elements
}

const AntdReusableForm: React.FC<IFormConfig> = ({
  fields,
  onFinish,
  onFinishFailed,
  formRef,
  children,
}) => {
  const [form] = Form.useForm();

  React.useEffect(() => {
    if (formRef) {
      formRef.current = form; // Assigning the form instance to the passed ref
    }
  }, [form, formRef]);

  return (
    <Form
      name="antd_powered_form"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      form={form}
    >
      {fields.map((field) => {
        switch (field.type) {
          case "text":
            return (
              <Form.Item
                key={field.name}
                label={field.label}
                name={field.name}
                rules={field.rules}
                initialValue={field?.initialValue}
              >
                <Input
                  size="large"
                  onChange={(e) =>
                    field.handleChange
                      ? field.handleChange(e.target.value)
                      : undefined
                  }
                />
              </Form.Item>
            );
          case "number":
            return (
              <Form.Item
                key={field.name}
                label={field.label}
                name={field.name}
                rules={field.rules}
                initialValue={field?.initialValue}
              >
                <InputNumber
                  size="large"
                  className="tw-w-[100%]"
                  formatter={field.formatter}
                  parser={(value) =>
                    field.parser ? field.parser(value || "") : 0
                  }
                  onChange={(value) =>
                    field.handleChange ? field.handleChange(value) : undefined
                  }
                />
              </Form.Item>
            );
          case "select":
          case "searchableSelect":
            return (
              <Form.Item
                key={field.name}
                label={field.label}
                name={field.name}
                rules={field.rules}
                initialValue={field?.initialValue}
              >
                <Select
                  size="large"
                  showSearch={field.type === "searchableSelect"}
                  filterOption={(input, option) =>
                    option?.children
                      ?.toString()
                      .toLowerCase()
                      .includes(input.toLowerCase()) || false
                  }
                  onChange={(e) =>
                    field.handleChange
                      ? field.handleChange(e.target.value)
                      : undefined
                  }
                >
                  {field.options?.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {option.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            );
          case "checkbox":
            return (
              <Form.Item
                key={field.name}
                name={field.name}
                valuePropName="checked"
                rules={field.rules}
                initialValue={field?.initialValue}
              >
                <Checkbox
                  onChange={(e) =>
                    field.handleChange
                      ? field.handleChange(e.target.value)
                      : undefined
                  }
                >
                  {field.label}
                </Checkbox>
              </Form.Item>
            );
          case "radio":
            return (
              <Form.Item
                key={field.name}
                label={field.label}
                name={field.name}
                rules={field.rules}
                initialValue={field?.initialValue}
              >
                <Radio.Group
                  onChange={(e) =>
                    field.handleChange
                      ? field.handleChange(e.target.value)
                      : undefined
                  }
                >
                  {field.options?.map((option) => (
                    <Radio key={option.id} value={option.id}>
                      {option.name}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
            );
          case "custom":
            return (
              <Form.Item
                key={field.name}
                label={field.label}
                name={field.name}
                rules={field.rules}
                initialValue={field?.initialValue}
              >
                {field.customNode}
              </Form.Item>
            );
          default:
            return null;
        }
      })}

      {children}
    </Form>
  );
};

export default AntdReusableForm;

/*Resetting form values from Parent component
*
import React from "react";
import AntdReusableForm, { IFormConfig } from "./AntdReusableForm";
import { FormInstance } from "antd";

const ParentComponent: React.FC = () => {
const formRef = useRef<FormInstance>(null);  // useRef for functional component

const handleReset = () => {
  formRef.current?.resetFields();
};

const formConfig: IFormConfig = {
  // your form configuration,
  formRef: formRef
};

return (
  <div>
    <AntdReusableForm {...formConfig} />
    <button onClick={handleReset}>Reset Form</button>
  </div>
);
};

export default ParentComponent;
*
*
*
* */
