import { createSelector } from "reselect";
import { TRootState } from "../reducers";

const selectState = (state: TRootState) => state.walletTopup;

// Output Selectors
export const selectWalletTopUp = createSelector(
  [selectState],
  (walletTopUp) => walletTopUp
);
export const selectWalletTopUpCurrentStep = createSelector(
  [selectState],
  (walletTopUp) => walletTopUp.currentStep
);
