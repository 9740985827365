import {appInsights} from "../components/AppInsight/AppInsight";

export const logAppInsightErrors = (error: any, fileName: string) => {
    appInsights.trackException({
        exception: new Error(JSON.stringify(error)),
        properties: { fileName: fileName },
    });
};

export const logAppInsightPageView = (fileName: string, isLoggedIn: boolean) => {
    appInsights.trackPageView({
        name: fileName,
        isLoggedIn,
    });
}
