import CryptoJS from 'crypto-js';
import CONFIG from "./config";

const key = CONFIG.ENCRYPTION_KEY;

export const encrypt = (data: any) => {
  if (process.env.NODE_ENV === 'development') {
    return data;
  }
  const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
  return ciphertext;
};

export const decrypt = (data: string) => {
  if (process.env.NODE_ENV === 'development') {
    return data;
  }
  const bytes = CryptoJS.AES.decrypt(data, key);
  const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData;
};
