import React, { Dispatch, Fragment, SetStateAction, useState } from "react";
import InvestmentModalStepThreeInitiate from "./SubPages/InvestmentModalStepThreeInitiate.component";
import InvestmentModalStepThreeConfirm from "./SubPages/InvestmentModalStepThreeConfirm.component";
import InvestmentModalStepThreeSuccess from "./SubPages/InvestmentModalStepThreeSuccess.component";

interface IInvestmentModalStepThree {
  closeModal: () => void;
  setError: Dispatch<SetStateAction<string>>;
  error: string;
}

const InvestmentModalStepThree: React.FC<IInvestmentModalStepThree> = ({
  closeModal,
  setError,
  error,
}) => {
  const [section, setSection] = useState<"initiate" | "confirm" | "success">(
    "initiate"
  );

  return (
    <Fragment>
      {(() => {
        switch (section) {
          case "initiate":
            return (
              <InvestmentModalStepThreeInitiate
                setSection={setSection}
                setError={setError}
              />
            );
          case "confirm":
            return (
              <InvestmentModalStepThreeConfirm
                setSection={setSection}
                setError={setError}
                error={error}
              />
            );
          case "success":
            return (
              <InvestmentModalStepThreeSuccess
                closeModal={closeModal}
                setSection={setSection}
                setError={setError}
                error={error}
              />
            );
          default:
            return null;
        }
      })()}
    </Fragment>
  );
};
export default InvestmentModalStepThree;
