import React, { Fragment } from "react";
import { yupValidators } from "../../../helpers/yupValidators";
import CustomInputField from "../../CustomHTMLElements/CustomInputField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTypedSelector } from "../../../hooks/redux-hooks/useTypedSelector";
import { useActions } from "../../../hooks/redux-hooks/useActions";
import { selectWithdrawal } from "../../../redux/selectors/withdrawalSelector";
import CustomSelectDropdown from "./../../CustomHTMLElements/CustomSelectDropdown";
import useBanks from "./../../../hooks/custom-hooks/useBanks";
import Loader from "../../Loader/Loader.component";
import { errorHandler } from "../../../helpers/errorHandler";

interface IWithdrawModalStepThree {
  closeModal: () => void;
}

const schema = yup.object().shape({
  account_number: yupValidators.genericRequiredNumber({
    message: "Please enter account number",
    max: 20,
    min: 10,
  }),
  bank_id: yupValidators.genericRequired({
    message: "Please select your bank",
  }),
  account_name: yupValidators.genericRequired({
    message: "Please enter account name",
    max: 128,
  }),
});

const WithdrawalModalStepThree: React.FC<IWithdrawModalStepThree> = ({
  closeModal,
}) => {
  const withdrawalInfo = useTypedSelector(selectWithdrawal);
  const { setWithdrawalInfo } = useActions();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schema),
  });

  const { data, isError, isLoading, error, isFetching, refetch } = useBanks("NG");

  const onSubmit = ({
    bank_id,
    account_number,
    account_name,
  }: {
    bank_id: number;
    account_number: number;
    account_name: string;
  }) => {
    setWithdrawalInfo({
      ...withdrawalInfo,
      currentStep: 4,
      accountDetails: {
        ...withdrawalInfo.accountDetails,
        bankId: bank_id,
        accountNumber: String(account_number),
        accountName: account_name,
        type: "new",
      },
    });
  };
  return (
    <div>
      <div className="modal-header p-0 mb-4">
        <h5 className="page-subtitle">New Account Details</h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={closeModal}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <Fragment>
        {isLoading ? (
          <div className="py-5 d-flex justify-content-center align-items-center flex-column">
            <Loader type="lg" variant="blue" />
          </div>
        ) : error ? (
          <div className="py-5 d-flex justify-content-center align-items-center flex-column">
            <h6 className="color-red font-weight-bold text-center">
              {errorHandler(error) ||
                "An error occured while getting available banks, please try again."}
            </h6>

            <button
              type="button"
              className="btn btn-sm advancly-white-btn  mt-3"
              onClick={() => refetch()}
              disabled={isFetching}
            >
              Retry {isFetching && <Loader />}
            </button>
          </div>
        ) : (
          <Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
              <CustomSelectDropdown
                {...register("bank_id")}
                label="Bank"
                errors={errors.bank_id}
                showRequiredIcon={true}
              >
                <option value="">Choose Bank</option>
                {data?.data?.map(
                  ({ name, id }: { name: string; id: number }) => (
                    <option value={id} key={id}>
                      {name}
                    </option>
                  )
                )}
              </CustomSelectDropdown>
              <CustomInputField
                type="number"
                maxLength={20}
                placeholder="Enter Account number"
                label="Account Number"
                errors={errors.account_number}
                {...register("account_number")}
              />
              <CustomInputField
                type="text"
                maxLength={128}
                placeholder="Enter Account Name"
                label="Account Name"
                errors={errors.account_name}
                {...register("account_name")}
              />
              <div className="d-flex justify-content-between mt-5 pt-5">
                <button
                  type="button"
                  className="btn btn-sm advancly-white-btn w-50 mr-2"
                  onClick={() =>
                    setWithdrawalInfo({
                      ...withdrawalInfo,
                      currentStep: 2,
                    })
                  }
                >
                  Go Back
                </button>
                <button
                  type="submit"
                  className="btn btn-sm advancly-btn w-50 ml-2"
                  disabled={isError}
                >
                  Continue
                </button>
              </div>
            </form>
          </Fragment>
        )}
      </Fragment>
    </div>
  );
};
export default WithdrawalModalStepThree;
