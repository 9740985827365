import { useQuery } from "react-query";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { getData } from "../../apis/apiMethods";

const getNextOfKinsDetails = async () => {
  const { data } = await getData(apiEndpoints.NEXT_OF_KIN);
  return data;
};

export default function useNextOfKinDetails() {
  return useQuery("getNextOfKinsDetails", getNextOfKinsDetails, {
    refetchOnWindowFocus: false,
  });
}
