import React from "react";
import { Spin } from "antd";
import { InvestmentRangeCard } from "../../InvestmentRangeCard/InvestmentRangeCard.component";
import formatMoney from "../../../helpers/formatMoney";
import styles from "../OnlineInvestment/InvestmentModalStepTwo/InvestmentModalStepTwo.module.scss";
import { TRepayment_Types } from "../../../interfaces/investment";

interface Investment {
  duration: number;
  rate: number;
  period_type: string;
}

interface InvestmentRange {
  interest_rates: Investment[];
}

interface ExtraInfo {
  estimatedReturns: string;
  monthlyInterestRepayment: string;
}

interface Props {
  investmentRange: InvestmentRange;
  duration: any; // Define more specific types based on your application's structure
  isError: boolean;
  isLoading: boolean;
  repaymentPlan?: "" | TRepayment_Types;
  setInvestmentInfo: (info: any) => void; // Define the parameter type more specifically
  onChangeRepaymentFrequency: (frequency: "Monthly" | "EndOfTenure") => void;
  calculatedDataLoading?: boolean;
  extraInfo: ExtraInfo;
  setExtraInfo: (info: any) => void;
  currency?: string;
}

const InvestmentOptions: React.FC<Props> = ({
  investmentRange,
  duration,
  isError,
  isLoading,
  repaymentPlan,
  setInvestmentInfo,
  onChangeRepaymentFrequency,
  calculatedDataLoading,
  extraInfo,
  currency,
  setExtraInfo,
}) => (
  <>
    <div className={styles.alignStepTwoBoxes}>
      {investmentRange?.interest_rates?.map((investment) => (
        <InvestmentRangeCard
          key={investment.duration}
          duration={duration}
          isError={isError}
          isLoading={isLoading}
          repaymentPlan={repaymentPlan || ""}
          days={investment.duration}
          onClick={() =>
            setInvestmentInfo({
              duration: {
                days: investment.duration,
                interest: investment.rate,
              },
            })
          }
          rate={investment.rate}
          period_type={investment.period_type}
          principalAmount={Number(duration?.amount)}
          setExtraInfo={setExtraInfo}
        />
      ))}
    </div>
    <div>
      <p className="mt-3">Select an interest payment plan:</p>
      <div
        className="form-check mb-3 cursor-pointer"
        onClick={() => onChangeRepaymentFrequency("Monthly")}
      >
        <input
          className="form-check-input cursor-pointer"
          type="radio"
          checked={repaymentPlan === "Monthly"}
          onChange={() => onChangeRepaymentFrequency("Monthly")}
        />
        <label className="form-check-label cursor-pointer">
          Monthly <br />
          Interest will be paid monthly and principal will be paid at maturity
        </label>
      </div>
      <div
        className="form-check mb-3 cursor-pointer"
        onClick={() => onChangeRepaymentFrequency("EndOfTenure")}
      >
        <input
          className="form-check-input cursor-pointer"
          type="radio"
          checked={repaymentPlan === "EndOfTenure"}
          onChange={() => onChangeRepaymentFrequency("EndOfTenure")}
        />
        <label className="form-check-label cursor-pointer">
          End of Tenure <br />
          Both Interest and principal will be paid at maturity
        </label>
      </div>
    </div>
    <hr />
    {calculatedDataLoading ? (
      <Spin />
    ) : (
      <>
        <p className="mt-4">
          Estimated Returns:{" "}
          {extraInfo?.estimatedReturns &&
            currency &&
            formatMoney(Number(extraInfo.estimatedReturns), currency)}
        </p>
        <p className="mt-3">
          Monthly Interest Payable:{" "}
          {extraInfo?.monthlyInterestRepayment &&
            currency &&
            formatMoney(Number(extraInfo.monthlyInterestRepayment), currency)}
        </p>
      </>
    )}
  </>
);

export default InvestmentOptions;
