import { useEffect, useRef } from "react";
import { appInsights } from "../../components/AppInsight/AppInsight";

interface IDeleteUserModal {
  user: {
    isActive?: boolean;
    id?: boolean;
  };
}

const DeleteUserModal: React.FC<IDeleteUserModal> = ({ user }) => {
  const dismissRef = useRef<null | HTMLButtonElement>(null);
  const dismissModal = () => {
    dismissRef?.current?.click();
  };
  useEffect(() => {
    appInsights.trackPageView({
      name: "DeleteUserModal.tsx",
      isLoggedIn: true,
    });
  }, []);
  return (
    <div
      className="modal fade"
      id="deleteUserModal"
      tabIndex={-1}
      aria-labelledby="deleteUserModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content p-4">
          <div className="modal-header p-0 pb-2">
            <h5 className="page-subtitle">Remove User</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              ref={dismissRef}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body px-0">
            <div>
              Please confirm that you want to remove this user from the
              platform.
            </div>
            <div className="d-flex justify-content-end align-items-center mt-3">
              <button
                className="btn btn-sm advancly-nobg-btn mr-2"
                onClick={dismissModal}
              >
                Cancel
              </button>
              <button
                className="btn btn-sm advancly-dimred-btn"
                onClick={dismissModal}
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DeleteUserModal;
