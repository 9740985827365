import { Fragment, useEffect } from "react";
import StepOne from "./StepOne.component";
import StepTwo from "./StepTwo.component";
import LandingLayout from "../../components/LandingLayout/LandingLayout.component";
import StepThree from "./StepThree.component";
import { Route } from "react-router-dom";
import { ROUTES } from "./../../helpers/routes";
import { appInsights } from "../../components/AppInsight/AppInsight";

const ResetPassword = () => {
  useEffect(() => {
    appInsights.trackPageView({ name: "ResetPassword.tsx", isLoggedIn: true });
  }, []);
  return (
    <Fragment>
      <LandingLayout>
        <Route path={ROUTES.RESET_PASSWORD} exact render={() => <StepOne />} />
        <Route
          path={ROUTES.RESET_PASSWORD_VALIDATE_OTP}
          exact
          render={() => <StepTwo />}
        />
        <Route
          path={ROUTES.RESET_PASSWORD_SET_PASSWORD}
          exact
          render={() => <StepThree />}
        />
      </LandingLayout>
    </Fragment>
  );
};

export default ResetPassword;
