import React, { Dispatch, SetStateAction, useEffect } from "react";
import { funderTypes } from "../../../helpers/funderTypes";
import CorporateInvestor from "../StepTwo/CorporateInvestor.component";
import IndividualInvestor from "../StepTwo/IndividualInvestor.component";
import styles from "../StepThree/StepThree.module.scss";
import { appInsights } from "../../../components/AppInsight/AppInsight";
export type stepsProp = {
  funderType: funderTypes;
  setFunderType: Dispatch<SetStateAction<funderTypes>>;
};

const StepThree = ({ funderType }: stepsProp) => {
  useEffect(() => {
    appInsights.trackPageView({
      name: "SignUpStepThree.tsx",
      isLoggedIn: true,
    });
  }, []);
  return (
    <>
      <div className={styles.detailsHeading}>
        <h2>Create an account</h2>
        <p>Enter your details to continue</p>
      </div>
      <div>
        {(() => {
          switch (funderType) {
            case funderTypes.INDIVIDUAL_FUNDER:
              return <IndividualInvestor />;
            case funderTypes.CORPORATE_FUNDER:
              return <CorporateInvestor />;
            default:
              return null;
          }
        })()}
      </div>
      <br />
      <br />
      <br />
    </>
  );
};

export default StepThree;
