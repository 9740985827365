import DismissableAlert from "./../DismissableAlert/DismissableAlert.component";
interface ICompleteSetupPrompt {}

export const CompleteSetupPrompt: React.FC<ICompleteSetupPrompt> = () => {
  return (
    <div>
      <DismissableAlert type="dashboard">
        <span>
          Welcome to Advest! Tap the button to finish setting up your Investor
          profile.{" "}
          <button
            data-toggle="modal"
            data-target="#dashboardCompleteProfileModal"
            className="btn btn-sm advancly-white-btn ml-3 border-0"
          >
            Complete Setup
          </button>
        </span>
      </DismissableAlert>
    </div>
  );
};
