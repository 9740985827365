import { TRootState } from "../reducers";
import { createSelector } from "reselect";

const selectState = (state: TRootState) => state.currencies;

export const currenciesSelector = createSelector(
  [selectState],
  ({ availableCurrencies }) => availableCurrencies
);

export const isLoadingCurrencies = createSelector(
  [selectState],
  ({ isLoading }) => isLoading
);

export const getCurrenciesFailed = createSelector(
  [selectState],
  ({ fetchAvailableCurrenciesFailed }) => fetchAvailableCurrenciesFailed
);
