import styles from "./ProgressIndicator.module.scss";
import { useTypedSelector } from "./../../hooks/redux-hooks/useTypedSelector";
import {
  selectInvestmentCurrentStep,
  selectInvestmentSteps,
} from "./../../redux/selectors/investmentSelector";
import { useActions } from "./../../hooks/redux-hooks/useActions";

const ProgressIndicator = () => {
  const steps = useTypedSelector(selectInvestmentSteps);
  const currentStep = useTypedSelector(selectInvestmentCurrentStep);
  const { setInvestmentStep } = useActions();
  const validBox = (i: number) => currentStep - 1 > i;
  return (
    <div className={styles.stepperBox}>
      <div className={styles.lineSeparatorBackground}></div>
      {steps.map((_, i: number) => {
        return (
          <div
            key={i}
            className={`${styles.stepperArea} ${
              i + 1 === 1 && styles.firstElement
            } ${i + 1 === steps.length && styles.lastElement} ${
              currentStep > i + 1 && "cursor-pointer"
            }`}
            // @ts-ignore
            onClick={() => currentStep > i + 1 && setInvestmentStep(i + 1)}
          >
            <div
              className={`${styles.label} ${validBox(i) && styles.active} ${
                currentStep === i + 1 && styles.current
              }`}
            >
              {validBox(i) ? "✔" : i + 1}
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default ProgressIndicator;
