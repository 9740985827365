import { ChangeEvent, useEffect, useState } from "react";
import { errorHandler } from "../../helpers/errorHandler";
import CustomInputField from "../CustomHTMLElements/CustomInputField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { yupValidators } from "../../helpers/yupValidators";
import Loader from "../Loader/Loader.component";
import Alert from "./../Alert/Alert.component";
import { postData } from "./../../apis/apiMethods";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { reValidateUserActivationStatus } from "../../helpers/isUserActivated";
import { useActions } from "./../../hooks/redux-hooks/useActions";
import { useTypedSelector } from "./../../hooks/redux-hooks/useTypedSelector";
import { selectUser } from "./../../redux/selectors/userSelector";
import useWorldCountries from "../../hooks/custom-hooks/useWorldCountries";
import PageLoader from "../PageLoader/PageLoader.component";
import ErrorHandler from "../ErrorHandler/ErrorHandler.component";
import useGenderOptions from "../../hooks/custom-hooks/useGenderOptions";
import countryCodeEmoji from "country-code-emoji";
import { useQueryClient } from "react-query";
import { appInsights } from "../AppInsight/AppInsight";

const schema = yup.object().shape({
  firstName: yupValidators.genericRequired({
    message: "Please enter your first name",
    min: 1,
    max: 30,
  }),
  lastName: yupValidators.genericRequired({
    message: "Please enter your last name",
    min: 1,
    max: 30,
  }),
  gender: yupValidators.genericRequired({
    message: "Please choose your gender",
  }),
  phoneNoCountryDialCode: yupValidators.genericRequired({
    message: "Please choose your dial code",
  }),
  contactNumber: yupValidators.phoneNumber,
});

interface IAccountInformation {
  funder: any;
  isLoading: boolean;
}

const AccountInformation: React.FC<IAccountInformation> = ({
  funder,
  isLoading,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const user = useTypedSelector(selectUser);
  const { setCurrentUser } = useActions();
  const queryClient = useQueryClient();
  const { data: countries, isLoading: isLoadingCountries } =
    useWorldCountries();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schema),
  });

  const gender = watch("gender");
  const phoneNoCountryDialCode = watch("phoneNoCountryDialCode");
  useEffect(() => {
    if (funder?.gender && !isLoading) {
      setValue("gender", funder?.gender?.toLowerCase());
      setValue("firstName", funder?.first_name);
      setValue("lastName", funder?.last_name);
      setValue("phoneNoCountryDialCode", funder?.phone_no_country_dial_code);
      setValue("contactNumber", funder?.phone_number);
    }
    // eslint-disable-next-line
  }, [funder]);

  const {
    data: genders,
    isLoading: isLoadingGender,
    error: genderError,
  } = useGenderOptions({
    countryCode: user?.country,
  });

  const onChangeGender = (e: ChangeEvent<HTMLInputElement>) => {
    setValue("gender", e.target.value);
  };

  useEffect(() => {
    if (!isLoadingCountries) {
      setValue("country", funder?.country);
    }
    // eslint-disable-next-line
  }, [countries]);

  const onSubmit = async ({
    gender,
    contactNumber,
    firstName,
    lastName,
    phoneNoCountryDialCode,
  }: any) => {
    setLoading(true);
    setError("");
    setSuccess("");

    const reqBody = {
      first_name: firstName,
      phone_number: contactNumber,
      last_name: lastName,
      gender,
      phone_no_country_dial_code: phoneNoCountryDialCode,
    };
    try {
      await postData(apiEndpoints.UPDATE_USER, reqBody);
      queryClient.invalidateQueries("getFunder");
      // Revalidate User Status if he is initally not activated
      if (!user.activated) {
        const activated = await reValidateUserActivationStatus();
        setCurrentUser({ ...user, activated });
      }
      setSuccess("Profile updated successfully.");
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setError(errorHandler(error));
      appInsights.trackException({
        exception : new Error(JSON.stringify(error)),
        properties: { fileName: "AccountInformation" },
      });
    }
  };

  return (
    <div>
      <>{error && <Alert message={error}/>}
        {genderError && <Alert message={errorHandler(genderError)}/>}
        {success && <Alert message={success} type="success"/>}

        {isLoadingCountries ? (
            <PageLoader/>
        ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row mt-4">
                <div className="col-12 col-md-6">
                  <CustomInputField
                      type="text"
                      maxLength={30}
                      placeholder="First Name"
                      label="First Name"
                      defaultValue={funder?.first_name}
                      {...register("firstName")}
                      errors={errors.firstName}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <CustomInputField
                      type="text"
                      maxLength={30}
                      placeholder="Last Name"
                      label="Last Name"
                      defaultValue={funder?.last_name}
                      {...register("lastName")}
                      errors={errors.lastName}
                  />
                </div>
                <div className="col-12">
                  <CustomInputField
                      type="email"
                      placeholder="morty@ricknmorty.com"
                      label="Email Address"
                      defaultValue={funder?.email}
                      readOnly
                  />
                </div>
                <div className="col-12 mb-4">
                  <label htmlFor="Gender" className="form-label d-block ">
                    Gender
                  </label>
                  {genders?.map(({name, id}: { name: string; id: number }) => {
                    return (
                        <div className="form-check form-check-inline" key={id}>
                          <input
                              className="form-check-input"
                              type="radio"
                              value={name}
                              name="gender"
                              onChange={onChangeGender}
                              checked={
                                gender?.toLowerCase() === name?.toLowerCase()
                                    ? true
                                    : false
                              }
                          />
                          <label className="form-check-label" htmlFor={name}>
                            {name}
                          </label>
                        </div>
                    );
                  })}

                  {errors.gender && <ErrorHandler errors={errors.gender}/>}
                </div>
                <div className="col-12 col-md-6">
                  <CustomInputField
                      type="number"
                      maxLength={10}
                      {...register("contactNumber")}
                      placeholder="8000000000"
                      label="Contact Number"
                      name="contactNumber"
                      errors={errors.contactNumber}
                      defaultValue={funder?.phone_number}
                  >
                    <select
                        onChange={(e) =>
                            setValue("phoneNoCountryDialCode", e.target.value)
                        }
                        value={phoneNoCountryDialCode}
                    >
                      {countries?.map(
                          ({
                             iso2,
                             id,
                             dialCode,
                           }: {
                            iso2: string;
                            id: number;
                            dialCode: string;
                          }) => (
                              <option value={dialCode} key={id}>
                                {iso2 && countryCodeEmoji(iso2)} {dialCode}
                              </option>
                          )
                      )}
                    </select>
                  </CustomInputField>
                </div>
              </div>

              <button
                  type="submit"
                  className="btn btn-sm advancly-white-btn"
                  disabled={loading || isLoadingGender || isLoadingCountries}
              >
                Update
                {loading && <Loader/>}
              </button>
            </form>
        )}</>
    </div>
  );
};
export default AccountInformation;
