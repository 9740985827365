/* eslint-disable jsx-a11y/anchor-is-valid */
import styles from "./ContactPage.module.scss";
import { useHistory } from "react-router-dom";
import { ROUTES } from "./../../helpers/routes";
import { useEffect, useRef } from "react";
import { appInsights } from "../../components/AppInsight/AppInsight";

export default function ContactPageLanding() {
  const history = useHistory();
  const chatRef = useRef<null | HTMLAnchorElement>(null);

  useEffect(() => {
    appInsights.trackPageView({
      name: "ContactPageLanding.tsx",
      isLoggedIn: true,
    });
  }, []);

  return (
    <div>
      <h3 className="page-title">Contact Support</h3>
      <p>Our support channels offers live chat and email options</p>
      <div className={`two-grid-layout ${styles.contactLanding}`}>
        <div
          className={styles.contactCard}
          onClick={() => chatRef?.current?.click()}
        >
          <div className={styles.left}>
            <div className={styles.iconArea}>
              <i className="ri-customer-service-2-fill"></i>
            </div>
            <div>
              <div className="font-weight-bold">Live Chat</div>
              <div className={styles.infoText}>
                You can chat with a customer support agent
              </div>
            </div>
          </div>
          <div className={styles.right}>
            <i className="ri-arrow-right-line"></i>
          </div>
        </div>
        <div
          className={styles.contactCard}
          onClick={() => history.push(`${ROUTES.CONTACT_SUPPORT}/email`)}
        >
          <div className={styles.left}>
            <div className={styles.iconArea}>
              <i className="ri-mail-line"></i>
            </div>
            <div>
              <div className="font-weight-bold">Send an email</div>
              <div className={styles.infoText}>
                You can send an email to our support team, you will get a
                response in 24hrs
              </div>
            </div>
          </div>
          <div className={styles.right}>
            <i className="ri-arrow-right-line"></i>
          </div>
        </div>
        <a
          // eslint-disable-next-line no-script-url
          href="javascript:void(Tawk_API.toggle())"
          className="d-none"
          ref={chatRef}
        >
          Live Chat
        </a>
      </div>
    </div>
  );
}
