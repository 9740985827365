import { Dispatch } from "redux";
import { actionType } from "../actionTypes";
import { TAction } from "../actions";
import { TUser } from "../../interfaces/user";
import { TSignUp } from "../../interfaces/individualSignUp";
import {
  TInvestmentStep,
  TInvestmentType,
  TOfflineInvestmentInfo,
  TOnlineInvestmentInfo,
  TSelectedInvestment,
} from "../../interfaces/investment";
import { TResetPassword } from "../../interfaces/resetPassword";
import { TWithdrawal } from "../../interfaces/withdrawal";
import { TWalletTopUp } from "../../interfaces/walletTopup";
import { getData } from "../../apis/apiMethods";
import { apiEndpoints } from "../../apis/apiEndpoints";
import CONFIG from "../../helpers/config";
import { mockUserAvailableCurrencies } from "../../pages/Login/service/mock";

export const setCurrentUser = (payload: TUser) => {
  return async (dispatch: Dispatch<TAction>) => {
    dispatch({
      type: actionType.SET_LOGGED_IN_USER,
      payload,
    });
  };
};
export const setUserToken = (payload: string) => {
  return async (dispatch: Dispatch<TAction>) => {
    dispatch({
      type: actionType.SET_LOGGED_IN_USER_TOKEN,
      payload,
    });
  };
};

export const clearUserInfo = () => {
  return async (dispatch: Dispatch<TAction>) => {
    dispatch({
      type: actionType.CLEAR_LOGGED_IN_USER,
    });
  };
};

export const setSignUp = (payload: TSignUp) => {
  return async (dispatch: Dispatch<TAction>) => {
    dispatch({
      type: actionType.SET_SIGN_UP,
      payload,
    });
  };
};

export const resetSignUp = () => {
  return async (dispatch: Dispatch<TAction>) => {
    dispatch({
      type: actionType.RESET_SIGN_UP,
    });
  };
};

export const selectInvestment = (payload: TSelectedInvestment) => {
  return async (dispatch: Dispatch<TAction>) => {
    dispatch({
      type: actionType.SELECT_INVESTMENT,
      payload,
    });
  };
};

export const setInvestmentStep = (payload: TInvestmentStep) => {
  return async (dispatch: Dispatch<TAction>) => {
    dispatch({
      type: actionType.SET_INVESTMENT_STEP,
      payload,
    });
  };
};
export const setInvestmentAgreementCheck = (payload: boolean) => {
  return async (dispatch: Dispatch<TAction>) => {
    dispatch({
      type: actionType.SET_INVESTMENT_AGREEMENT_CHECK,
      payload,
    });
  };
};
export const setInvestmentType = (payload: TInvestmentType) => {
  return async (dispatch: Dispatch<TAction>) => {
    dispatch({
      type: actionType.SET_INVESTMENT_TYPE,
      payload,
    });
  };
};

export const setOnlineInvestmentInfo = (payload: TOnlineInvestmentInfo) => {
  return async (dispatch: Dispatch<TAction>) => {
    dispatch({
      type: actionType.SET_ONLINE_INVESTMENT_INFO,
      payload,
    });
  };
};

export const setOfflineInvestmentInfo = (payload: TOfflineInvestmentInfo) => {
  return async (dispatch: Dispatch<TAction>) => {
    dispatch({
      type: actionType.SET_OFFLINE_INVESTMENT_INFO,
      payload,
    });
  };
};

export const resetInvestment = () => {
  return async (dispatch: Dispatch<TAction>) => {
    dispatch({
      type: actionType.RESET_INVESTMENT,
    });
  };
};
export const setWithdrawalInfo = (payload: TWithdrawal) => {
  return async (dispatch: Dispatch<TAction>) => {
    dispatch({
      type: actionType.SET_WITHDRAWAL_INFO,
      payload,
    });
  };
};

export const resetWithdrawal = () => {
  return async (dispatch: Dispatch<TAction>) => {
    dispatch({
      type: actionType.RESET_WITHDRAWAL,
    });
  };
};

export const setWalletTopUpInfo = (payload: TWalletTopUp) => {
  return async (dispatch: Dispatch<TAction>) => {
    dispatch({
      type: actionType.SET_WALLET_TOPUP_INFO,
      payload,
    });
  };
};

export const resetWalletTopUp = () => {
  return async (dispatch: Dispatch<TAction>) => {
    dispatch({
      type: actionType.RESET_WALLET_TOPUP,
    });
  };
};

export const setResetPasswordInfo = (payload: TResetPassword) => {
  return async (dispatch: Dispatch<TAction>) => {
    dispatch({
      type: actionType.SET_RESET_PASSWORD_INFO,
      payload,
    });
  };
};

export const setShowBalance = (payload: boolean) => {
  return async (dispatch: Dispatch<TAction>) => {
    dispatch({
      type: actionType.SHOW_BALANCE,
      payload,
    });
  };
};

export const setMinimumTopUpAmounts = (payload: TWalletTopUp) => {
  return async (dispatch: Dispatch<TAction>) => {
    dispatch({
      type: actionType.SET_MINIMUM_TOP_UP_AMOUNTS,
      payload,
    });
  };
};

export const getCurrencies = () => {
  return async (dispatch: Dispatch<TAction>) => {
    try {
      dispatch({
        type: actionType.LOADING_USER_AVAILABLE_CURRENCIES,
        payload: true,
      });
      if (CONFIG.USE_MOCK) {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(
              dispatch({
                type: actionType.SET_USER_AVAILABLE_CURRENCIES,
                payload: mockUserAvailableCurrencies.data,
              })
            );
          }, 3000);
        });
      } else {
        const response = await getData(apiEndpoints.CURRENCIES);
        dispatch({
          type: actionType.SET_USER_AVAILABLE_CURRENCIES,
          payload: response?.data,
        });
      }
    } catch (error) {
      console.log({ error });
      dispatch({
        type: actionType.SET_USER_AVAILABLE_CURRENCIES_FAILED,
      });
    } finally {
      dispatch({
        type: actionType.LOADING_USER_AVAILABLE_CURRENCIES,
        payload: false,
      });
    }
  };
};
