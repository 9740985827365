import React, { Fragment } from "react";
import InvestmentSummaryGridCard from "./../InvestmentSummaryGridCard/InvestmentSummaryGridCard.component";
import formatMoney from "./../../helpers/formatMoney";
import Alert from "./../Alert/Alert.component";
import { errorHandler } from "./../../helpers/errorHandler";
import { useTypedSelector } from "../../hooks/redux-hooks/useTypedSelector";
import { selectShowBalance } from "../../redux/selectors/userSelector";

interface IDashboardCards {
  activeTab: string;
  error: any;
  loading: boolean;
  dataToShow: {
    total_active_investment: number;
    total_amount_invested: number;
    total_interest_payable: number;
    total_monthly_interest_payable: number;
  };
}

export const DashboardCards: React.FC<IDashboardCards> = ({
  activeTab,
  error,
  loading,
  dataToShow,
}) => {
  const showBalance = useTypedSelector(selectShowBalance);

  return (
    <Fragment>
      {error && <Alert message={errorHandler(error)} />}
      <div className="four-grid-layout">
        <InvestmentSummaryGridCard
          title="Total Active Investment"
          value={dataToShow?.total_active_investment}
          loading={loading}
        />
        <InvestmentSummaryGridCard
          title="Total Amount Invested"
          value={
            showBalance
              ? formatMoney(dataToShow?.total_amount_invested, activeTab)
              : "****"
          }
          loading={loading}
        />
        <InvestmentSummaryGridCard
          title="Total Interest Payable"
          value={
            showBalance
              ? formatMoney(dataToShow?.total_interest_payable, activeTab)
              : "****"
          }
          loading={loading}
        />
        <InvestmentSummaryGridCard
          title="Total Monthly Interest Payable"
          value={
            showBalance
              ? formatMoney(
                  dataToShow?.total_monthly_interest_payable,
                  activeTab
                )
              : "****"
          }
          loading={loading}
        />
      </div>
    </Fragment>
  );
};
