import { Fragment, useEffect } from "react";
import { EmailVerification } from "./EmailVerification.component";
import { OtpVerification } from "./OtpVerification.component";
import { useTypedSelector } from "./../../../hooks/redux-hooks/useTypedSelector";
import { appInsights } from "../../../components/AppInsight/AppInsight";

const StepOne = () => {
  const { emailVerified } = useTypedSelector((state) => state.signUp);
  useEffect(() => {
    appInsights.trackPageView({
      name: "StepOne.component.tsx",
      isLoggedIn: true,
    });
  }, []);
  return (
    <Fragment>
      {!emailVerified ? <EmailVerification /> : <OtpVerification />}
    </Fragment>
  );
};

export default StepOne;
