import { format } from "date-fns";
import differenceInMonths from "date-fns/differenceInMonths";

function isValidDate(d: any) {
  return d instanceof Date && !isNaN(Number(d));
}

export const formatDate = (date: string) => {
  const dateToFormat = new Date(date);
  if (!dateToFormat || !isValidDate(dateToFormat)) return "";
  return format(dateToFormat, "dd/MM/yyyy");
};

export const formatDateFull = (date: string) => {
  const dateToFormat = new Date(date);
  if (!dateToFormat || !isValidDate(dateToFormat)) return "";
  return format(dateToFormat, "do MMM, yyyy");
};

export const formatDateAlt = (date: string) => {
  const dateToFormat = new Date(date);
  if (!dateToFormat || !isValidDate(dateToFormat)) return "";
  return format(dateToFormat, "yyyy-MM-dd");
};

export const getMonthDifference = ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) => {
  if (!startDate || !endDate) return 0;
  const formattedStartDate = new Date(startDate);
  const formattedEndDate = new Date(endDate);
  return differenceInMonths(formattedEndDate, formattedStartDate);
};

export const formatMonthToDays = (numberOfMonths: number) => {
  if (!numberOfMonths) return "";
  return `${numberOfMonths * 30} Days`;
};

export const getMonthFromDays = (days: number) => {
  return Math.round(days / 30);
};

export const formatDaysToMonth = (numberOfDays: number) => {
  if (!numberOfDays) return "";
  return `${getMonthFromDays(numberOfDays)} Days`;
};
