import React, { Dispatch, SetStateAction } from "react";
import styles from "./InvestmentRange.module.scss";
import { resolveDurationName } from "../../helpers/resolveDuration";
import { TRepayment_Types } from "../../interfaces/investment";
import { string } from "yup";

interface IInvestmentRangeCard {
  duration: any;
  days: number;
  rate: number;
  isError: boolean;
  isLoading: boolean;
  repaymentPlan: TRepayment_Types | "" | string;
  period_type: string;
  onClick: () => void;
  principalAmount: number;
  setExtraInfo: Dispatch<
    SetStateAction<{
      monthlyInterestRepayment: string;
      estimatedReturns: string;
    }>
  >;
}

export const InvestmentRangeCard: React.FC<IInvestmentRangeCard> = ({
  duration,
  days,
  rate,
  period_type,
  onClick,
  principalAmount,
  setExtraInfo,
  repaymentPlan,
  isError,
  isLoading,
}) => {
  // const { data, isError, isLoading } = useInterestCalculation({
  //   principalAmount,
  //   interestRate: rate,
  //   tenure: days,
  //   repaymentPlan,
  // });
  // useEffect(() => {
  //   if (data?.maturity_amount && data?.monthly_interest_payable) {
  //     setExtraInfo({
  //       estimatedReturns: data?.maturity_amount,
  //       monthlyInterestRepayment: data?.monthly_interest_payable,
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data?.maturity_amount, data?.monthly_interest_payable]);
  return (
    <div
      className={`${styles.box} ${
        duration?.days === days ? styles.active : ""
      } ${(isError || isLoading) && styles.disabled}`}
      onClick={() => {
        onClick();
      }}
      key={days}
    >
      <div className={styles.top}>
        <div className={styles.month}>{days}</div>
        <div className={styles.label}>{resolveDurationName(period_type)}</div>
      </div>
      <div className={styles.bottom}>{`${rate.toFixed(2)}%`}</div>
    </div>
  );
};
