import React, { Fragment, memo } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models, Report } from "powerbi-client";
import CONFIG from "../../helpers/config";

interface IDashboardPowerBi {
  funderId: number;
  accessToken: string;
  embedUrl: string;
}

export const DashboardPowerBi: React.FC<IDashboardPowerBi> = memo(
  ({ funderId, accessToken, embedUrl }) => {
    return (
      <Fragment>
        <PowerBIEmbed
          embedConfig={{
            type: "report", // Supported types: report, dashboard, tile, visual and qna
            id: CONFIG.POWERBI_REPORT_ID,
            embedUrl,
            accessToken,
            tokenType: models.TokenType.Embed,
            // @ts-ignore
            filters: [
              {
                $schema: "http://powerbi.com/product/schema#basic",
                target: {
                  table: "advancly lendxmoniapp_xfunder",
                  column: "id",
                },
                operator: "In",
                values: [funderId],
                filterType: 1,
              },
            ],

            settings: {
              panes: {
                filters: {
                  expanded: false,
                  visible: false,
                },
              },
            },
          }}
          eventHandlers={
            new Map([
              [
                "loaded",
                () => {
                  // console.log("Report loaded");
                },
              ],

              [
                "rendered",
                () => {
                  // console.log("Report rendered");
                },
              ],

              [
                "error",
                function (event: any) {
                  // console.log(event.detail);
                },
              ],
            ])
          }
          cssClassName="powerbi-embed-container"
          getEmbeddedComponent={(embeddedReport) => {
            //@ts-ignore
            if (embeddedReport) window.report = embeddedReport as Report;
          }}
        />
      </Fragment>
    );
  }
);
