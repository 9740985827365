import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Loader from "../../../components/Loader/Loader.component";
import { postData } from "../../../apis/apiMethods";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { errorHandler } from "../../../helpers/errorHandler";
import { yupValidators } from "../../../helpers/yupValidators";
import CustomInputField from "../../../components/CustomHTMLElements/CustomInputField";
import { useTypedSelector } from "../../../hooks/redux-hooks/useTypedSelector";
import Alert from "../../../components/Alert/Alert.component";
import { useActions } from "../../../hooks/redux-hooks/useActions";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "./../../../helpers/routes";
import { appInsights } from "../../../components/AppInsight/AppInsight";
import useOtpTypes from "../../../hooks/custom-hooks/useOtp";

const schema = yup.object().shape({
  otp: yupValidators.otp,
});

export const OtpVerification = () => {
  const [loading, setLoading] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [otpSuccess, setOtpSuccess] = useState("");
  const [error, setError] = useState<null | string>(null);

  const signUpDetails = useTypedSelector((state) => state.signUp);
  const { setSignUp } = useActions();
  useEffect(() => {
    appInsights.trackPageView({
      name: "SignUpOtpVerification.component.tsx",
      isLoggedIn: true,
    });
  }, []);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async ({ otp }: { otp: string }) => {
    setLoading(true);
    setError(null);
    try {
      const reqBody = {
        otp,
        email: signUpDetails.email,
        phone: signUpDetails.phone_number,
      };
      await postData(apiEndpoints.VALIDATE_SIGN_UP_OTP, reqBody);
      setSignUp({ ...signUpDetails, emailVerified: true, step: 2 });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(errorHandler(error));
      appInsights.trackException({
        exception : new Error(JSON.stringify(error)),
        properties: { fileName: OtpVerification },
      });
    }
  };

  const {
    data: otpTypes,
    error: otpTypesError,
    isLoading: isLoadingOtpTypes,
  } = useOtpTypes();

  const resendOtp = async () => {
    setOtpLoading(true);
    setError(null);
    setOtpSuccess("");
    try {
      await postData(
        `${apiEndpoints.RESEND_SIGN_UP_OTP}?email=${signUpDetails?.email}&countryCode=${signUpDetails?.country}`,
        {
          otpType: otpTypes?.find(
            (o: { name: string }) => o?.name === "Onboarding"
          )?.id,
        }
      );
      setOtpLoading(false);
      setOtpSuccess(
        "OTP has been sent to your email address and phone number."
      );
    } catch (error) {
      setOtpLoading(false);
      setError(errorHandler(error));
      appInsights.trackException({
        exception : new Error(JSON.stringify(error)),
        properties: { fileName: OtpVerification },
      });
    }
  };
  return (
    <div>
      {error && <Alert message={error} />}
      {otpSuccess && <Alert message={otpSuccess} type="success" />}
      <form className="my-5" onSubmit={handleSubmit(onSubmit)}>
        <h3 className="font-weight-bold mb-3 color-dark-blue">
          OTP Verification
        </h3>
        <p>
          A One-Time Password (OTP) has been sent to: <br />{" "}
          {signUpDetails?.email}
        </p>
        <div className="row">
          <div className="col-md-12">
            <CustomInputField
              type="email"
              label="Email Address"
              readOnly
              defaultValue={signUpDetails?.email}
            />
          </div>
          <div className="col-md-12">
            <CustomInputField
              type="number"
              maxLength={6}
              {...register("otp")}
              label="OTP"
              placeholder="Enter OTP"
              name="otp"
              errors={errors.otp}
            />
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-center my-4 flex-wrap">
          <p className="d-flex flex-wrap">
            <span
              className={`color-blue ${
                otpLoading ? "cursor-disabled" : "cursor-pointer"
              }`}
              onClick={resendOtp}
            >
              Resend OTP {otpLoading && <Loader />}
            </span>
          </p>
          <button
            type="submit"
            value="submit"
            className="btn advancly-btn btn-sm"
            disabled={loading || otpLoading}
          >
            Validate
            {loading && <Loader />}
          </button>
        </div>

        <hr />
        <p className="d-flex flex-wrap justify-content-center">
          <span className="text-muted pr-2">
            Entered a wrong email address?
          </span>
          <span
            onClick={() =>
              setSignUp({ ...signUpDetails, step: 1, emailVerified: false })
            }
            className="color-blue cursor-pointer"
          >
            Go back
          </span>
        </p>
        <div className="d-flex flex-wrap justify-content-center">
          <span className="text-muted pr-2">Already have an account? </span>
          <Link to={ROUTES.LOGIN} className="color-blue">
            Login
          </Link>
        </div>
      </form>
    </div>
  );
};
