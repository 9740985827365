export const attachClassNameToTag = (element: string) => {
  const item = element ? element.toLowerCase() : "";
  if (item === "success" || item === "live" || item === "active") {
    return "custom-advancly-tag success";
  } else if (item === "pending" || item === "processing") {
    return "custom-advancly-tag pending";
  } else if (item === "error" || item === "failed") {
    return "custom-advancly-tag error";
  } else {
    return "custom-advancly-tag ";
  }
};

export const attachClassNameToText = (element: string) => {
  const item = element ? element.toLowerCase() : "";
  if (item === "deposit") {
    return "color-green";
  } else if (item === "withdrawal") {
    return "color-red";
  } else {
    return "color-page-black";
  }
};
