import React from "react";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../helpers/routes";
import { TCurrencyCodes } from "../../interfaces/currencies";
import styles from "./PortfolioCard.module.scss";
import formatMoney from "./../../helpers/formatMoney";
import { attachClassNameToTag } from "./../../helpers/attachClassNameToTag";

interface IPortfolioCard {
  portfolio: {
    title: string;
    startDate: string;
    endDate: string;
    tenure: string;
    id: number;
    amount: number;
    currencyCode: TCurrencyCodes;
    status: "processing" | "live" | "matured";
  };
}

export const PortfolioCard: React.FC<IPortfolioCard> = ({
  portfolio: { title, startDate, id, amount, currencyCode, status, tenure },
}) => {
  const history = useHistory();
  return (
    <div
      className="card cursor-pointer"
      onClick={() => id && history.push(`${ROUTES.PORTFOLIO}/${id}`)}
    >
      <div className="d-flex align-items-center justify-content-between flex-wrap">
        <h6 className="font-weight-bold mb-0">{title}</h6>
        <small className={attachClassNameToTag(status)}>{status}</small>
      </div>
      {/* <small className="color-grey mb-2">{subtitle}</small> */}
      <h4 className="py-2 color-bg-blue font-weight-bold">
        {formatMoney(amount, currencyCode)}
      </h4>
      <div className={styles.windowSection}>
        <div>
          <div>Start Date</div>
          <div className="font-weight-bold">{startDate}</div>
        </div>
        <div>
          <div>Tenor</div>
          <div className="font-weight-bold">{tenure} days</div>
        </div>
      </div>
    </div>
  );
};
