import { Fragment, useEffect } from "react";
import { Route, useRouteMatch } from "react-router-dom";
import ContactPageLanding from "./ContactPageLanding";
import ContactPageSendEmail from "./ContactPageSendEmail";
import { appInsights } from "../../components/AppInsight/AppInsight";

const ContactPageRoot = () => {
  const match = useRouteMatch();
  useEffect(() => {
    appInsights.trackPageView({
      name: "ContactPageRoot.tsx",
      isLoggedIn: true,
    });
  }, []);
  return (
    <Fragment>
      <Route exact path={match.path} component={ContactPageLanding} />
      <Route
        exact
        path={`${match.path}/email`}
        component={ContactPageSendEmail}
      />
    </Fragment>
  );
};

export default ContactPageRoot;
