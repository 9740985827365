import React from "react";
import { useHistory } from "react-router-dom";

interface IBackButtonProps {
  text?: any;
}

export const BackButton: React.FC<IBackButtonProps> = ({ text }) => {
  const history = useHistory();
  return (
    <div className="d-flex">
      <div
        className="cursor-pointer d-flex align-items-center font-weight-bold"
        onClick={() => history.goBack()}
      >
        <i className="ri-arrow-left-line ri-2x mr-3"></i>
        {text}
      </div>
    </div>
  );
};
