import { createSelector } from "reselect";
import { TRootState } from "../reducers";

const selectState = (state: TRootState) => state.withdrawal;

// Output Selectors
export const selectWithdrawal = createSelector(
  [selectState],
  (withdrawal) => withdrawal
);
export const selectWithdrawalCurrentStep = createSelector(
  [selectState],
  (withdrawal) => withdrawal.currentStep
);
export const selectWithdrawalType = createSelector(
  [selectState],
  (withdrawal) => withdrawal.amount
);
export const selectWithdrawalCurrency = createSelector(
  [selectState],
  (withdrawal) => withdrawal.currency
);
export const selectWithdrawalAccountDetails = createSelector(
  [selectState],
  (withdrawal) => withdrawal.accountDetails
);
