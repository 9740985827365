import React, { ReactElement } from "react";

interface INoTableData {
  text?: string;
  icon?: ReactElement<HTMLElement>;
}

const NoTableData: React.FC<INoTableData> = ({ text, icon }) => {
  return (
    <tr>
      <td colSpan={100}>
        <div className="no-advancly-data">
          {icon ? icon : <i className="ri-briefcase-4-fill ri-3x"></i>}
          <span>{text ? text : "There is no data available"}</span>
        </div>
      </td>
    </tr>
  );
};
export default NoTableData;
