import { Fragment, useEffect } from "react";
import { Route, useRouteMatch } from "react-router-dom";
import Investments from "./Investments/Investments";
import InvestmentDetails from "./InvestmentDetails/InvestmentDetails";
import { appInsights } from "../../components/AppInsight/AppInsight";

const InvestmentsRoot = () => {
  const match = useRouteMatch();
  useEffect(() => {
    appInsights.trackPageView({
      name: "InvestmentsRoot.tsx",
      isLoggedIn: true,
    });
  }, []);
  return (
    <Fragment>
      <Route exact path={match.path} component={Investments} />
      <Route exact path={`${match.path}/:id`} component={InvestmentDetails} />
    </Fragment>
  );
};

export default InvestmentsRoot;
