import { TCurrencies, TCurrencyCodes } from "../interfaces/currencies";

type TCURRENCIES = {
  name: TCurrencies;
  code: TCurrencyCodes;
  symbol: string;
};

export const LOCAL_CURRENCY_CODE = "NGN";
export const USD_CURRENCY_CODE = "USD";
export const LOCAL_CURRENCY_NAME = "naira";
export const USD_CURRENCY_NAME = "dollar";

export const CURRENCIES: TCURRENCIES[] = [
  { name: LOCAL_CURRENCY_NAME, code: LOCAL_CURRENCY_CODE, symbol: "₦" }, // Always leave this as the first item in this array as it is the local currency
  { name: USD_CURRENCY_NAME, code: USD_CURRENCY_CODE, symbol: "$" },
];

type TFUNDER_CURRENCY_OPTIONS = {
  lcy_wallet_id: number;
  usd_wallet_id: number;
};

export const currenciesAvailableToFunder = ({
  lcy_wallet_id,
  usd_wallet_id,
}: TFUNDER_CURRENCY_OPTIONS) => {
  const funderCurrencies = [
    { code: LOCAL_CURRENCY_CODE, value: lcy_wallet_id },
    { code: USD_CURRENCY_CODE, value: usd_wallet_id },
  ];
  const validCurrencies = funderCurrencies
    .filter((currency) => currency.value)
    .map((currency) => currency.code);

  return CURRENCIES.filter((currency) =>
    validCurrencies.includes(currency.code)
  );
};

export const getCurrencySymbolByCode = (code: TCurrencyCodes): string => {
  const match = CURRENCIES.find(
    (currency: TCURRENCIES) => currency.code === code
  );
  return match?.symbol || "";
};

export const getCurrencyCodeByName = (name: TCurrencies): string => {
  const match = CURRENCIES.find(
    (currency: TCURRENCIES) => currency.name === name
  );
  return match?.code || "";
};

export const getCurrencyNameByCode = (code: string): string => {
  const match = CURRENCIES.find(
    (currency: TCURRENCIES) => currency.code === code
  );
  return match?.name || "";
};

export const getWallets = (
  wallets: {
    walletId: number;
    currency: string;
    isDefault: boolean;
    isExternal: boolean;
    walletProvider: string;
  }[]
) => {
  const localNGNWallet = wallets.find(
    (f) => f.currency === "NGN" && f.isExternal
  );
  const localKESWallet = wallets.find(
    (f) => f.currency === "KES" && f.isExternal
  );

  const usdWallet = wallets.find((f) => f.currency === "USD" && f.isExternal);
  const returnAvailableWallets = () => {
    let wallets: {
      currencyType: string;
      currencyCode: string;
    }[] = [];
    if (localNGNWallet || localKESWallet) {
      wallets = [
        ...wallets,
        {
          currencyType: "Local",
          currencyCode:
            localNGNWallet?.currency || localKESWallet?.currency || "",
        },
      ];
    }
    if (usdWallet) {
      wallets = [
        ...wallets,
        {
          currencyType: "Foreign",
          currencyCode: usdWallet?.currency,
        },
      ];
    }

    return wallets;
  };
  return {
    localNGNWallet,
    localKESWallet,
    returnAvailableWallets,
    usdWallet,
  };
};
