import { Fragment, useEffect } from "react";
import { Route, useRouteMatch } from "react-router-dom";
import Portfolios from "./Portfolios/Portfolios";
import { PortfolioDetail } from "./PortfolioDetail/PortfolioDetail";
import { appInsights } from "../../components/AppInsight/AppInsight";

const PortfoliosRoot = () => {
  const match = useRouteMatch();
  useEffect(() => {
    appInsights.trackPageView({ name: "PortfoliosRoot.tsx", isLoggedIn: true });
  }, []);
  return (
    <Fragment>
      <Route exact path={match.path} component={Portfolios} />
      <Route exact path={`${match.path}/:id`} component={PortfolioDetail} />
    </Fragment>
  );
};

export default PortfoliosRoot;
