import { useQuery } from "react-query";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { getData } from "../../apis/apiMethods";

type paramProps = {
  funderId: number;
};

const getFunderKycDocuments = async ({ queryKey }: any) => {
  const { funderId } = queryKey[1];
  const { data } = await getData(
    `${apiEndpoints.FUNDER_UPLOADED_KYC_DOCUMENTS}?funder_id=${funderId}`
  );
  return data;
};

export default function useFunderKycDocuments({ funderId }: paramProps) {
  return useQuery(
    ["getFunderKycDocuments", { funderId }],
    getFunderKycDocuments,
    {
      refetchOnWindowFocus: false, // Refetch on window focus
      enabled: funderId ? true : false,
    }
  );
}
