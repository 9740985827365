import React, { Fragment, SetStateAction, Dispatch } from "react";

interface IToggleTab {
  text: string;
  id: string;
  activeTab: string;
  setActiveTab: Dispatch<SetStateAction<any>>; // Any here because of internal usage
}

const ToggleTab: React.FC<IToggleTab> = ({
  text,
  id,
  activeTab,
  setActiveTab,
}) => {
  return (
    <Fragment>
      <div
        className={`tabItem ${activeTab === id && "activeTab"}`}
        onClick={() => setActiveTab(id)}
      >
        {text}
      </div>
    </Fragment>
  );
};
export default ToggleTab;
