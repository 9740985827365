import React, { Fragment, useState } from "react";
import CustomInputField from "./../../CustomHTMLElements/CustomInputField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { yupValidators } from "../../../helpers/yupValidators";
import { useEffect } from "react";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { postData } from "./../../../apis/apiMethods";
import { errorHandler } from "./../../../helpers/errorHandler";
import Loader from "../../Loader/Loader.component";
import Alert from "../../Alert/Alert.component";
import PageLoader from "../../PageLoader/PageLoader.component";
import { useQueryClient } from "react-query";
import {appInsights} from "../../AppInsight/AppInsight";

const schema = yup.object().shape({
  pin: yupValidators.genericRequired({ message: "Please enter your PIN" }),
  confirmPin: yupValidators.genericRequired({
    message: "Please enter your PIN again",
  }),
});

interface IResetPinProps {
  dismissModal: () => void;
}

export const ResetPin: React.FC<IResetPinProps> = ({ dismissModal }) => {
  const [fieldsMatch, setFieldsMatch] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pinType, setPinType] = useState("password");
  const [confirmPinType, setConfirmPinType] = useState("password");

  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setError: setFormError,
  } = useForm<any>({
    resolver: yupResolver(schema),
  });

  const pin = watch("pin");
  const confirmPin = watch("confirmPin");

  useEffect(() => {
    if (pin?.length === 4 && confirmPin?.length === 4 && pin === confirmPin) {
      setFieldsMatch(true);
    } else {
      setFieldsMatch(false);
    }
  }, [pin, confirmPin]);

  const onSubmit = async ({
    pin,
    confirmPin,
  }: {
    pin: number;
    confirmPin: number;
  }) => {
    if (pin !== confirmPin) {
      return setFormError("confirmPin", {
        type: "manual",
        message: "Pins do not match",
      });
    }

    setError("");
    setLoading(true);
    setSuccess(false);

    const reqBody = {
      pin: String(pin),
      type: 1,
    };

    try {
      await postData(apiEndpoints.SAVE_SECURITY_PIN, reqBody);
      setLoading(false);
      setSuccess(true);
      queryClient.invalidateQueries("getSavedSecurityQuestion");
      setTimeout(() => {
        dismissModal();
      }, 10000);
    } catch (error) {
      setLoading(false);
      setError(errorHandler(error));
      appInsights.trackException({
        exception : new Error(JSON.stringify(error)),
        properties: {fileName : ResetPin}
      })
    }
  };

  const togglePinField = () => {
    if (pinType === "password") {
      setPinType("text");
    } else {
      setPinType("password");
    }
  };

  const toggleConfirmPinField = () => {
    if (confirmPinType === "password") {
      setConfirmPinType("text");
    } else {
      setConfirmPinType("password");
    }
  };

  return (
    <Fragment>
      {error && <Alert message={error} />}
      {success ? (
        <div className="d-flex align-items-center justify-content-between flex-column">
          <i className="ri-checkbox-circle-fill color-green ri-5x"></i>
          <h5 className="mt-5 font-weight-bold">Transaction PIN Set</h5>
          <button
            type="button"
            className="btn btn-sm advancly-btn mt-5 w-100"
            onClick={dismissModal}
          >
            Done
          </button>
        </div>
      ) : (
        <Fragment>
          {loading ? (
            <div className="p-5">
              <PageLoader />
            </div>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="text-justify">Enter your new transaction pin</div>
              <div className="row mt-4">
                <div className="col-12">
                  <CustomInputField
                    type={pinType}
                    maxLength={4}
                    {...register("pin")}
                    label="New Transaction PIN"
                    errors={errors.pin}
                    hasActionButton={true}
                    actionButtonText={pinType === "password" ? "show" : "hide"}
                    onClickActionButton={togglePinField}
                  />
                </div>
                <div className="col-12">
                  <CustomInputField
                    type={confirmPinType}
                    maxLength={4}
                    {...register("confirmPin")}
                    label="Confirm New Transaction PIN"
                    errors={errors.confirmPin}
                    hasActionButton={true}
                    actionButtonText={
                      confirmPinType === "password" ? "show" : "hide"
                    }
                    onClickActionButton={toggleConfirmPinField}
                  />
                </div>

                {pin && confirmPin && (
                  <div className="col-12 d-flex align-items-center justify-content-between">
                    <small>PIN should be a four digit number</small>
                    <div>
                      <i
                        className={`ri-checkbox-circle-fill ${
                          fieldsMatch ? "color-green" : "color-red"
                        }`}
                      ></i>
                    </div>
                  </div>
                )}
              </div>
              <button
                className="btn btn-sm advancly-btn mt-5 w-100"
                disabled={!fieldsMatch || loading}
              >
                Save {loading && <Loader />}
              </button>
            </form>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};
