import React from "react";
import Loader from "../Loader/Loader.component";
import styles from "./InvestmentSummaryGridCard.module.scss";

interface IInvestmentSummaryGridCard {
  title: string;
  value: string | number;
  loading: boolean;
}

const InvestmentSummaryGridCard: React.FC<IInvestmentSummaryGridCard> = ({
  title,
  value,
  loading,
}) => {
  return (
    <div className={`${styles.card} p-3`}>
      <div className={styles.title}>
        <span className="mr-2">{title} </span>
      </div>
      <div className={styles.value}>
        {loading ? <Loader /> : <span>{value}</span>}
      </div>
    </div>
  );
};
export default InvestmentSummaryGridCard;
