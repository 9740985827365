import React from "react";
import styles from "./SectorsGridCardForProducts.module.scss";
import { Link } from "react-router-dom";
import { ROUTES } from "../../helpers/routes";

interface ISectorsGridCardForProducts {
  id: number;
  interest_rate: number;
  name: string;
  description: string;
}

const SectorsGridCardForProducts: React.FC<ISectorsGridCardForProducts> = ({
  id,
  interest_rate,
  name,
  description,
  children,
}) => {
  return (
    <div className={styles.sectorsGridCard} key={id}>
      <div className={styles.sectorsGridCardBottom}>
        {children}
        <p className={styles.sectorsCardTitle}>{name}</p>
        <p className={styles.sectorsCardDescription}>{description}</p>
        <p className={styles.sectorsCardInterestRate}>
          Interest Per Annum: {interest_rate}%
        </p>
        <Link
          to={`${ROUTES.SECTORS}/${id}`}
          className="btn btn-sm advancly-white-btn"
        >
          View Details
        </Link>
      </div>
    </div>
  );
};
export default SectorsGridCardForProducts;
