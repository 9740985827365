import { useQuery } from "react-query";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { TRepayment_Types } from "../../interfaces/investment";
import { postData } from "./../../apis/apiMethods";

type paramProps = {
  principalAmount: number | "" | undefined;
  interestRate: number | "" | undefined;
  tenure: number | "" | undefined;
  repaymentPlan: TRepayment_Types | "" | undefined | string;
  numberOfDaysInYear: number | "";
};

const getInterestCalculation = async ({ queryKey }: any) => {
  const {
    principalAmount,
    interestRate,
    tenure,
    repaymentPlan,
    numberOfDaysInYear,
  } = queryKey[1];
  let isLoading = true;
  const { data } = await postData(apiEndpoints.GET_INTEREST_CALCULATION, {
    principal_amount: principalAmount,
    interest_rate: interestRate,
    tenure: tenure,
    interest_repayment_type: repaymentPlan,
    number_of_days_in_year: numberOfDaysInYear,
  });
  isLoading = false;
  return {
    data,
    isLoading,
    maturity_amount: data?.maturity_amount,
    monthly_interest_payable: data?.monthly_interest_payable,
  };
};

export default function useInterestCalculation({
  principalAmount,
  interestRate,
  tenure,
  repaymentPlan,
  numberOfDaysInYear,
}: paramProps) {
  return useQuery(
    [
      "getInterestCalculation",
      {
        principalAmount,
        interestRate,
        tenure,
        repaymentPlan,
        numberOfDaysInYear,
      },
    ],
    getInterestCalculation,
    {
      enabled: repaymentPlan?.length ? true : false,
      refetchOnWindowFocus: false, // Refetch on window focus
    }
  );
}
