import React, { Fragment, useEffect } from "react";
import { Route, useRouteMatch } from "react-router-dom";
import SectorDetails from "./SectorDetails/SectorDetails";
import SectorsLanding from "./SectorsLanding/SectorsLanding";
import { appInsights } from "../../components/AppInsight/AppInsight";

const SectorsRoot = () => {
  const match = useRouteMatch();
  useEffect(() => {
    appInsights.trackPageView({
      name: "SectorsRoot.component.tsx",
      isLoggedIn: true,
    });
  }, []);
  return (
    <Fragment>
      <Route exact path={match.path} component={SectorsLanding} />
      <Route exact path={`${match.path}/:id`} component={SectorDetails} />
    </Fragment>
  );
};

export default SectorsRoot;
