import produce from "immer";
import { TAction } from "../actions";
import { actionType } from "../actionTypes";

export const initialState: {
  isLoading: boolean;
  availableCurrencies: {
    currencyType: "Foreign" | "Local";
    currencyCode: string;
  }[];
  fetchAvailableCurrenciesFailed: boolean;
} = {
  isLoading: false,
  availableCurrencies: [],
  fetchAvailableCurrenciesFailed: false,
};

export const userCurrenciesReducer = produce(
  (state = initialState, action: TAction) => {
    switch (action.type) {
      case actionType.SET_USER_AVAILABLE_CURRENCIES:
        state = {
          ...state,
          availableCurrencies: action.payload,
        };
        return state;

      case actionType.SET_USER_AVAILABLE_CURRENCIES_FAILED:
        state = {
          ...state,
          fetchAvailableCurrenciesFailed: true,
        };
        return state;

      case actionType.LOADING_USER_AVAILABLE_CURRENCIES:
        state = {
          ...state,
          isLoading: action.payload,
        };
        return state;
      default:
        return state;
    }
  }
);
