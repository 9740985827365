import { TAction } from "../actions";
import { actionType } from "../actionTypes";
import produce from "immer";
import { TSignUp } from "../../interfaces/individualSignUp";

export const initialState = {
  first_name: "",
  last_name: "",
  email: "",
  phone_number: "",
  password: "",
  country: "",
  countryCode: "",
  phoneNoCountryDialCode: "",
  gender: "",
  funder_type_id: null,
  business_name: "",
  step: 1,
  emailVerified: false,
  bvn: "",
  dob: "",
  bvnValidated: false,
};

export const signUpReducer = produce(
  (state: TSignUp = initialState, action: TAction): TSignUp => {
    switch (action.type) {
      // Set During Individual Sign up
      case actionType.SET_SIGN_UP:
        state = { ...action.payload };
        return state;
      case actionType.RESET_SIGN_UP:
        return initialState;
      default:
        return state;
    }
  }
);
