import { Fragment, FC } from "react";
import NoTableData from "./../NoTableData/NoTableData.component";
import formatMoney from "./../../helpers/formatMoney";
import { resolveDuration } from "./../../helpers/resolveDuration";
import TableDataLoading from "../TableDataLoading/TableDataLoading.component";
import { errorHandler } from "./../../helpers/errorHandler";
import Alert from "./../Alert/Alert.component";

interface IInvestmentTermSheet {
  termSheetDetails: any;
}

export const InvestmentTermSheet: FC<IInvestmentTermSheet> = ({
  termSheetDetails,
}) => {
  const { data, isLoading, error } = termSheetDetails;

  return (
    <Fragment>
      {error && <Alert message={errorHandler(error)} />}
      <div className="table-responsive">
        <table className="table">
          {isLoading ? (
            <TableDataLoading />
          ) : (
            <Fragment>
              {data?.interest_ranges?.length ? (
                <Fragment>
                  <thead className="bg-lighter-grey">
                    <tr>
                      <th>Range</th>
                      {data?.interest_ranges[0]?.interest_rates?.map(
                        (
                          interest_rate: {
                            duration: number;
                            period_type: string;
                          },
                          _id: number
                        ) => {
                          return (
                            <th key={_id}>
                              {resolveDuration(
                                interest_rate?.duration,
                                interest_rate?.period_type
                              )}
                            </th>
                          );
                        }
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {data?.interest_ranges?.map(
                      (
                        interest_range: {
                          interest_rates: any[];
                          upper_amount: number;
                          lower_amount: number;
                        },
                        i: number
                      ) => {
                        return (
                          <tr key={i}>
                            <td>
                              {formatMoney(
                                interest_range?.lower_amount,
                                data?.currency_code
                              )}
                              {" - "}
                              {formatMoney(
                                interest_range?.upper_amount,
                                data?.currency_code
                              )}
                            </td>
                            {interest_range?.interest_rates?.map(
                              (
                                interest_rate: { rate: number },
                                _id: number
                              ) => {
                                return (
                                  <td key={_id}>{interest_rate?.rate}%</td>
                                );
                              }
                            )}
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </Fragment>
              ) : (
                <NoTableData />
              )}
            </Fragment>
          )}
        </table>
      </div>
    </Fragment>
  );
};
