import { useQuery } from "react-query";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { getData } from "../../apis/apiMethods";

const getFunderAddress = async () => {
  const { data } = await getData(apiEndpoints.FUNDER_ADDRESS_INFORMATION);
  return data;
};

export default function useFunderAddress() {
  return useQuery(["getFunderAddress"], getFunderAddress, {
    refetchOnWindowFocus: false,
  });
}
