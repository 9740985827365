import React, { useEffect } from "react";
import { BackButton } from "../../../components/BackButton/BackButton.component";
import { FilePicker } from "../../../components/FilePicker/FilePicker.component";
import CONFIG from "../../../helpers/config";
import { appInsights } from "../../../components/AppInsight/AppInsight";

interface ProfileSettingsBusinessDocumentProps {}

const DROPDOWN_OPTIONS = [
  {
    value: "Business Registration Document",
    optionText: "Business Registration Document",
  },
  { value: "Others", optionText: "Others" },
];

export const ProfileSettingsBusinessDocument: React.FC<
  ProfileSettingsBusinessDocumentProps
> = () => {
  useEffect(() => {
    appInsights.trackPageView({
      name: "ProfileSettingsBusinessDocument.tsx",
      isLoggedIn: true,
    });
  }, []);
  return (
    <div>
      <BackButton text="Go back" />

      <div className="mt-4">
        <FilePicker
          DROPDOWN_OPTIONS={DROPDOWN_OPTIONS}
          document_type_code={
            CONFIG.DOCUMENT_CODES.businessRegistrationDocument
          }
        />
      </div>
    </div>
  );
};
