import { useQuery } from "react-query";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { getData } from "../../apis/apiMethods";

const getSavedSecurityQuestion = async () => {
  const { data } = await getData(apiEndpoints.GET_SAVED_SECURITY_QUESTION);
  return data;
};

export default function useSavedSecurityQuestion() {
  return useQuery("getSavedSecurityQuestion", getSavedSecurityQuestion, {
    retry: false,
    refetchOnWindowFocus: false,
  });
}
