import { TCurrencyCodes } from "../interfaces/currencies";
import { getCurrencyNameByCode, getCurrencySymbolByCode } from "./currencies";

export const resolveCurrencyCodeBadges = (
  badges: { code: TCurrencyCodes; value: number; walletId: number }[]
) => {
  return badges
    .filter((badge) => badge.walletId)
    .map(({ code, value }) => {
      return (value && getCurrencySymbolByCode(code)) || null;
    })
    .filter((badge) => badge);
};

export const resolveCurrencyNameBadges = (
  badges: { code: TCurrencyCodes; value: number; walletId: number }[]
) => {
  return badges
    .filter((badge) => badge.walletId)
    .map(({ code, value }) => {
      return (value && getCurrencyNameByCode(code)) || null;
    })
    .filter((badge) => badge);
};
