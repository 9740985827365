import React, { useEffect, useState } from "react";
import CustomSearchInput from "../../../components/CustomHTMLElements/CustomSearchInput";
import styles from "./SectorsLanding.module.scss";
import SectorsGridCard from "../../../components/SectorsGridCard/SectorsGridCard.component";
import AdvertisingImage from "../.././../assets/img/sectors_images/advertising.png";
import AgricultureImage from "../.././../assets/img/sectors_images/agriculture.png";
import EducationImage from "../.././../assets/img/sectors_images/education.png";
import FinancialServicesImage from "../.././../assets/img/sectors_images/financial-services.png";
import MediaAndEntertainmentImage from "../.././../assets/img/sectors_images/media-and-entertainment.png";
import OilAndGasImage from "../.././../assets/img/sectors_images/oil-and-gas.png";
import SectorsGridCardForProducts from "../../../components/SectorsGridCardForProducts/SectorsGridCardForProducts.component";
import { ReactComponent as AssetFinance } from "../../../assets/img/asset-finance.svg";
import { ReactComponent as CashAdvance } from "../../../assets/img/cash-advance.svg";
import { ReactComponent as TradeFinance } from "../../../assets/img/trade-finance.svg";
import { ReactComponent as WorkingCapital } from "../../../assets/img/working-capital.svg";
import { appInsights } from "../../../components/AppInsight/AppInsight";

export default function SectorsLanding() {
  const [searchValue, setSearchValue] = useState("");
  const [activeTab, setActiveTab] = useState("sectors");

  const onClickSearchIcon = () => {
    // console.log(searchValue);
  };

  useEffect(() => {
    appInsights.trackPageView({ name: "SectorsLanding.tsx", isLoggedIn: true });
  }, []);

  const onSearchChange = (e: any) => {
    setSearchValue(e.target.value);
  };
  return (
    <div>
      <div className="row mb-4">
        <div className="col-6">
          <div className="page-title">Sectors</div>
        </div>
        <div className="col-6">
          <CustomSearchInput
            placeholder="Search"
            onClickSearchIcon={onClickSearchIcon}
            maxLength={32}
            onChange={onSearchChange}
            value={searchValue}
          />
        </div>
      </div>

      <div className="alignToggleTabItems">
        <div className="subtitle-align">
          <div className="d-flex justify-content-between">
            <div
              className={`tabItem ${activeTab === "sectors" && "activeTab"}`}
              onClick={() => setActiveTab("sectors")}
            >
              All Sectors
            </div>
            <div
              className={`tabItem ${activeTab === "products" && "activeTab"}`}
              onClick={() => setActiveTab("products")}
            >
              All Loan Products
            </div>
          </div>
        </div>
      </div>

      {(() => {
        switch (activeTab) {
          case "sectors":
            return (
              <div className={`${styles.sectorsGrid} mt-4`}>
                <SectorsGridCard id={1} name="advertising" interest_rate={12}>
                  <img src={AdvertisingImage} alt="advertising" />
                </SectorsGridCard>
                <SectorsGridCard id={2} name="agriculture" interest_rate={20}>
                  <img src={AgricultureImage} alt="agriculture" />
                </SectorsGridCard>
                <SectorsGridCard id={3} name="education" interest_rate={9.5}>
                  <img src={EducationImage} alt="education" />
                </SectorsGridCard>
                <SectorsGridCard
                  id={4}
                  name="financial services"
                  interest_rate={15.6}
                >
                  <img src={FinancialServicesImage} alt="financial services" />
                </SectorsGridCard>
                <SectorsGridCard
                  id={5}
                  name="media & entertainment"
                  interest_rate={8}
                >
                  <img
                    src={MediaAndEntertainmentImage}
                    alt="media & entertainment"
                  />
                </SectorsGridCard>
                <SectorsGridCard id={6} name="oil & gas" interest_rate={17.8}>
                  <img src={OilAndGasImage} alt="oil & gas" />
                </SectorsGridCard>
              </div>
            );
          case "products":
            return (
              <div className={`${styles.sectorsGrid} mt-4`}>
                <SectorsGridCardForProducts
                  id={1}
                  name="Asset Finance"
                  description="Purchase assets you have been saving up to buy in minutes"
                  interest_rate={12}
                >
                  <AssetFinance />
                </SectorsGridCardForProducts>
                <SectorsGridCardForProducts
                  id={2}
                  name="Cash Advance"
                  description="Receive instant cash and repay with ease"
                  interest_rate={20}
                >
                  <CashAdvance />
                </SectorsGridCardForProducts>
                <SectorsGridCardForProducts
                  id={3}
                  name="Trade Finance"
                  description="Move your business forward with our Trade finance loans"
                  interest_rate={9.5}
                >
                  <TradeFinance />
                </SectorsGridCardForProducts>
                <SectorsGridCardForProducts
                  id={4}
                  name="Working Capital"
                  description="Gain speed & in your business with our working capital loans"
                  interest_rate={15.6}
                >
                  <WorkingCapital />
                </SectorsGridCardForProducts>
              </div>
            );
          default:
            return null;
        }
      })()}
    </div>
  );
}
