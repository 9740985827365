import React from 'react';
import {useHistory} from "react-router-dom";
import "./PageNotFound.styles.scss";


const PageNotFound = () => {
    const history = useHistory();

    return (
        <div className="pagenotfound">
            <div>
                <h1>Oops!</h1>
                <h1>PAGE NOT FOUND</h1>
                <p>
                    The page you are looking for might have been removed, had its name
                    changed or is temporarily unavailable.
                </p>
            </div>
            <div className="d-flex justify-content-center">
                <button className="btn btn-primary btn-lg" onClick={() => history.push("/")}>
                    Return To Homepage
                </button>
            </div>
        </div>
    );
};

export default PageNotFound;