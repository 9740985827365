import { useQuery } from "react-query";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { getData } from "../../apis/apiMethods";
import CONFIG from "./../../helpers/config";

const getPowerBiToken = async () => {
  const data = await getData(
    `${apiEndpoints.POWERBI_EMBED}?groupId=${CONFIG.POWERBI_GROUP_ID}&reportId=${CONFIG.POWERBI_REPORT_ID}&dataSetId=${CONFIG.POWERBI_DATASET_ID}`
  );
  return data;
};

export default function usePowerBiToken() {
  return useQuery("getPowerBiToken", getPowerBiToken, {
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
  });
}
