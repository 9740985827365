import React, { Dispatch, Fragment, SetStateAction, useState } from "react";
import CustomInputField from "./../../CustomHTMLElements/CustomInputField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { yupValidators } from "../../../helpers/yupValidators";
import CustomSelectDropdown from "./../../CustomHTMLElements/CustomSelectDropdown";
import { postData } from "./../../../apis/apiMethods";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { errorHandler } from "./../../../helpers/errorHandler";
import Loader from "../../Loader/Loader.component";
import Alert from "../../Alert/Alert.component";
import useSecurityQuestions from "./../../../hooks/custom-hooks/useSecurityQuestions";
import PageLoader from "./../../PageLoader/PageLoader.component";
import {appInsights} from "../../AppInsight/AppInsight";

const schema = yup.object().shape({
  question_id: yupValidators.genericRequiredNumber({
    message: "Please select a question",
  }),
  answer: yupValidators.genericRequired({
    message: "Please enter your answer",
    max: 64,
  }),
});

interface ISetSecurityQuestionProps {
  setSection: Dispatch<SetStateAction<any>>;
}

export const SetSecurityQuestion: React.FC<ISetSecurityQuestionProps> = ({
  setSection,
}) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async ({
    question_id,
    answer,
  }: {
    question_id: number;
    answer: string;
  }) => {
    setLoading(true);
    setError("");

    const reqBody = {
      question_id: Number(question_id),
      answer,
    };

    try {
      await postData(apiEndpoints.SAVE_SECURITY_QUESTION, reqBody);
      setLoading(false);
      setSection("pin");
    } catch (error) {
      setLoading(false);
      setError(errorHandler(error));
      appInsights.trackException({
        exception : new Error(JSON.stringify(error)),
        properties: {fileName : SetSecurityQuestion}
      })
    }
  };
  const { data, isLoading } = useSecurityQuestions();
  return (
    <Fragment>
      {isLoading ? (
        <div className="p-5">
          <PageLoader />
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          {error && <Alert message={error} />}
          <div>
            Kindly set a security question before creating your transaction PIN
          </div>
          <div className="row mt-4">
            <div className="col-12">
              <CustomSelectDropdown
                {...register("question_id")}
                label="Security Question"
                name="question_id"
                errors={errors.question_id}
              >
                <option value="">Select</option>
                {data?.map(
                  ({ question, id }: { question: string; id: number }) => {
                    return (
                      <option value={id} key={id}>
                        {question}
                      </option>
                    );
                  }
                )}
              </CustomSelectDropdown>
            </div>
            <div className="col-12">
              <CustomInputField
                type="text"
                maxLength={64}
                {...register("answer")}
                label="Security Question Answer"
                errors={errors.answer}
              />
            </div>
          </div>
          <button
            className="btn btn-sm advancly-btn mt-3 w-100"
            disabled={loading}
          >
            Continue {loading && <Loader />}
          </button>
        </form>
      )}
    </Fragment>
  );
};
