import { TRepayment_Types } from "../interfaces/investment";
import {message} from "antd";

export const getAdvanclyTranRef = () => new Date().getTime().toString();
export const formatRepaymentType = (repaymentType: TRepayment_Types) => {
  if (!repaymentType) return repaymentType;
  if (repaymentType === "EndOfTenure") return "End of Tenure";
  return repaymentType;
};
export const getInterestPlusCapital = ({
  interestRate,
  amount,
}: {
  interestRate: number;
  amount: number;
}): number => {
  const calculatedInterest = getInterestOnCapital({ interestRate, amount });
  return Number(amount) + calculatedInterest;
};
export const getInterestOnCapital = ({
  interestRate,
  amount,
}: {
  interestRate: number;
  amount: number;
}): number => {
  return (Number(interestRate) / 100) * Number(amount);
};

export const addCommasToMoney = (amount: string | number): string => {
  return Number(amount)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
export const removeCommasFromMoney = (amount: string): number => {
  const res = Number(amount.split(",").join(""));
  return Number(res.toFixed(2).toString());
};
export const FunderWalletTopup = 1;
export const InvestmentCreation = 2;

export  const handleCopy = (text: string | number) => {
  navigator.clipboard.writeText(text.toString());
  message.success("Copied!");
};
