import React from "react";
import Loader from "../Loader/Loader.component";
import usePowerBiToken from "./../../hooks/custom-hooks/usePowerBiToken";
import { DashboardPowerBi } from "./../PowerBi/DashboardPowerBi.component";
import { useTypedSelector } from "./../../hooks/redux-hooks/useTypedSelector";
import { selectUserId } from "../../redux/selectors/userSelector";

interface IInvestmentMap {}

const InvestmentMap: React.FC<IInvestmentMap> = () => {
  const { data, isLoading, isError, refetch } = usePowerBiToken();
  const funderId = useTypedSelector(selectUserId);

  return (
    <div>
      {isError ? (
        <div className="investment-map-section color-grey tall">
          <i className="ri-earth-fill"></i>
          <div className="py-2">Unable to load map data</div>
          <div
            className="pb-2 color-blue text-underline cursor-pointer"
            onClick={() => refetch()}
          >
            Refresh
          </div>
        </div>
      ) : isLoading ? (
        <div className="investment-map-section color-grey tall">
          <Loader type="lg" variant="blue" />
        </div>
      ) : (
        <DashboardPowerBi
          funderId={funderId}
          accessToken={data?.token}
          embedUrl={data?.embedUrl}
        />
      )}
    </div>
  );
};
export default InvestmentMap;
