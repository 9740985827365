import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { selectOfflineInvestmentCurrency } from "../../../redux/selectors/investmentSelector";
import { useTypedSelector } from "../../../hooks/redux-hooks/useTypedSelector";
import { useActions } from "../../../hooks/redux-hooks/useActions";
import { selectInvestmentCurrentStep } from "../../../redux/selectors/investmentSelector";
import Alert from "./../../Alert/Alert.component";
import { TInvestmentAccounts } from "../../../interfaces/investmentAccounts";
import { getData } from "../../../apis/apiMethods";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { errorHandler } from "../../../helpers/errorHandler";
import Loader from "../../Loader/Loader.component";
import { appInsights } from "../../AppInsight/AppInsight";

interface IOfflineInvestmentBankDetails {
  setShowBankDetails: Dispatch<SetStateAction<boolean>>;
}
export const OfflineInvestmentBankDetails: React.FC<
  IOfflineInvestmentBankDetails
> = ({ setShowBankDetails }) => {
  // ({ setShowBankDetails }) => {
  const [copied, setCopied] = useState("");
  const currentStep = useTypedSelector(selectInvestmentCurrentStep);
  const currency = useTypedSelector(selectOfflineInvestmentCurrency);
  const { setInvestmentStep, setInvestmentType } = useActions();
  const [error, setError] = useState<null | string>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [bankDetails, setBankDetails] = useState<TInvestmentAccounts>({
    id: 0,
    accountName: "",
    accountNumber: "",
    bankCode: "",
    bankName: "",
    currency: "",
    isDefault: false,
    type: "",
    routingNumber: "",
    swiftCode: "",
    updatedAt: "",
    createdAt: "",
  });

  useEffect(() => {
    fetchInvestmentAccounts();
  }, [currency]);

  const copyText = (text: string) => {
    setCopied("Copied.");
    navigator.clipboard.writeText(text);
    setTimeout(() => setCopied(""), 3000);
  };

  const fetchInvestmentAccounts = async () => {
    try {
      setIsLoading(true);
      const response = await getData(
        `${apiEndpoints.INVESTMENT_BANK_ACCOUNTS}?type=2&currency=${currency}`
      );
      setBankDetails(response.data);
    } catch (error) {
      setError(errorHandler(error));
      appInsights.trackException({
        exception : new Error(JSON.stringify(error)),
        properties: { fileName: "OfflineInvestmentBankDetails" },
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {error && <Alert message={error} />}
      {copied && <Alert message={copied} type="dark" place="bc" />}
      <h5 className="page-subtitle mb-3">Advancly Fund Account</h5>
      {isLoading ? (
        <div className="d-flex justify-content-center my-5">
          <Loader type="lg" />
        </div>
      ) : (
        <>
          <p className="form-label">
            Kindly transfer your investment amount to this account, click
            proceed when it is done
          </p>

          <div className="bg-lighter-grey p-3">
            <div className="mb-4">
              <div className="form-label mb-2">
                <small>Account Name</small>
              </div>
              <h6 className="page-subtitle">{bankDetails?.accountName}</h6>
            </div>
            <div className="mb-4">
              <div className="form-label mb-2">
                <small>Account Number</small>
              </div>
              <h6 className="page-subtitle">
                {bankDetails?.accountNumber}
                <span
                  className="font-weight-bold cursor-pointer ml-4"
                  onClick={() => copyText(bankDetails?.accountNumber)}
                >
                  <i className="ri-file-copy-fill color-mid-blue ml-2"></i>
                </span>
              </h6>
            </div>
            <div className="mb-4">
              <div className="form-label mb-2">
                <small>Bank Name</small>
              </div>
              <h6 className="page-subtitle">{bankDetails?.bankName}</h6>
            </div>
            {currency !== "NGN" ? (
              <div className="mb-4">
                <div className="form-label mb-2">
                  <small>Bank Swift Code</small>
                </div>
                <h6 className="page-subtitle">{bankDetails?.swiftCode}</h6>
              </div>
            ) : null}
          </div>

          <div className="d-flex justify-content-end align-items-center mt-3 flex-wrap">
            <button
              type="button"
              className="btn btn-sm advancly-nobg-btn mr-3"
              onClick={() => setInvestmentType("")}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-sm advancly-white-btn"
              onClick={() => {
                setShowBankDetails(false);
                setInvestmentStep(currentStep + 1);
              }}
            >
              Proceed
            </button>
          </div>
        </>
      )}
    </div>
  );
};
