import { useQuery } from "react-query";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { getData } from "../../apis/apiMethods";

const getFunder = async () => {
  const { data } = await getData(apiEndpoints.USER);
  return data;
};

export default function useFunder() {
  return useQuery("getFunder", getFunder, {
    refetchOnWindowFocus: false,
    refetchInterval: 5 * 1000 * 60, // Refetch every five minutes
  });
}
