import React, { useEffect } from "react";
import SectorsGridDetailsLeft from "../../../components/SectorsGridDetailsLeft/SectorsGridDetailsLeft.component";
import SectorsGridDetailsRight from "../../../components/SectorsGridDetailsRight/SectorsGridDetailsRight.component";
import styles from "./SectorDetails.module.scss";
import { BackButton } from "../../../components/BackButton/BackButton.component";
import { appInsights } from "../../../components/AppInsight/AppInsight";

export default function SectorDetails() {
  useEffect(() => {
    appInsights.trackPageView({ name: "SectorDetails.tsx", isLoggedIn: true });
  }, []);
  return (
    <div>
      <BackButton text="Back to all Sectors" />
      <div className="row mt-3">
        <div className="col-md-6">
          <div className="page-title">Invest in Financial Services</div>
        </div>
        <div className="col-md-6">
          <div className="float-right">
            <button className="btn btn-sm advancly-white-btn mr-2">
              <i className="ri-arrow-left-s-line ri-xl align-middle"></i>
            </button>
            <button className="btn btn-sm advancly-white-btn">
              Next Sector{" "}
              <i className="ri-arrow-right-s-line ri-xl align-middle"></i>
            </button>
          </div>
        </div>
      </div>

      <div className={styles.sectorDetailsGrid}>
        <SectorsGridDetailsLeft />
        <SectorsGridDetailsRight />
      </div>
    </div>
  );
}
