import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "./HeaderActionButtons.module.scss";
import { Link, useHistory } from "react-router-dom";
import { useActions } from "./../../hooks/redux-hooks/useActions";
import { ROUTES } from "./../../helpers/routes";
import { useTypedSelector } from "./../../hooks/redux-hooks/useTypedSelector";
import {
  selectUser,
  // selectUserActivationStatus,
} from "./../../redux/selectors/userSelector";
import NotificationIcon from "../../assets/img/notification-icon.svg";
import CaretIcon from "../../assets/img/caret-icon.svg";
import {
  capitalizeFirstLetter,
  getFirstLetter,
} from "../../helpers/capitalizeFirstLetter";
import Loader from "../Loader/Loader.component";
import { getData, putData } from "../../apis/apiMethods";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { TNotifications } from "../../interfaces/notifications";
import { errorHandler } from "../../helpers/errorHandler";
import moment from "moment";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import config from "../../helpers/config";
import {appInsights} from "../AppInsight/AppInsight";

interface IHeaderActionButtons {
  sidebar: boolean;
}

const general_broadcast_hub = `${config.BASE_URL}${apiEndpoints.GENERAL_BROADCAST_HUB}`;
// const specific_broadcast_hub = `${config.BASE_URL}${apiEndpoints.SPECIFIC_BROADCAST_HUB}`;

export const HeaderActionButtons: React.FC<IHeaderActionButtons> = ({
  sidebar,
}) => {
  const history = useHistory();
  const { clearUserInfo } = useActions();
  const user = useTypedSelector(selectUser);
  const [moreContent, setMoreContent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [notifications, setNotifications] = useState<
    TNotifications["data"] | []
  >([]);
  const [error, setError] = useState("");
  const [newMsg, setNewMsg] = useState(false);
  const [selectedMsg, setSelectedMsg] = useState(0);

  const [open, setOpen] = useState<boolean>(false);
  const [readMsgs, setReadMsgs] = useState<number[]>([]);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const general_broadcast_connection = new HubConnectionBuilder()
        .withUrl(general_broadcast_hub)
        .configureLogging(LogLevel.Debug)
        .build();

    let isConnected = false;

    const startConnection = async () => {
      try {
        await general_broadcast_connection.start();
        console.log("connected to general broadcast");
        isConnected = true;
        await general_broadcast_connection.invoke("GetConnectionId", user?.ssoUserId)
            .then(function (connectionId) {
              console.log(`Connected to ropc method `);
            });
      } catch (err) {
        console.log(`Error starting the connection: ${err?.toString()}`);
        if (!isConnected) {
          setTimeout(startConnection, 5000); // Retry every 5 seconds if not connected
        }
      }
    };

    startConnection();

    general_broadcast_connection.onclose(async () => {
      console.log("disconnected from general broadcast");
      isConnected = false;
      startConnection(); // Attempt to reconnect automatically
    });

    general_broadcast_connection.on("sendToAll", function (message) {
      console.log("message from server = ", message);
      handleFetchNotifications();
    });

    general_broadcast_connection.on("sendToSpecific", (response: any) => {
      console.log({ response });
      handleFetchNotifications();
    });

    return () => {
      general_broadcast_connection.stop();
    };
  }, [user.ssoUserId]);


  const handleFetchNotifications = async () => {
    setIsLoading(true);
    try {
      let response: any = await getData(apiEndpoints.NOTIFICATION_LIST);
      if (response.totalUnRead > 0) {
        setNewMsg(true);
      } else {
        setNewMsg(false);
      }
      setNotifications(response.data);
    } catch (error) {
      setError(errorHandler(error));
      setTimeout(() => {
        setError("");
      }, 2000);
      appInsights.trackException({
        exception : new Error(JSON.stringify(error)),
        properties: {fileName : HeaderActionButtons}
      })
    }
    setIsLoading(false);
  };

  const handleSetMessageAsRead = useCallback(async () => {
    if (readMsgs.length !== 0) {
      let allNotificationIds = [];
      for (let i = 0; i < readMsgs.length; i++) {
        allNotificationIds.push(readMsgs[i]);
      }
      setIsLoading(true);
      try {
        await putData(apiEndpoints.SET_NOTIFICATION_AS_READ, {
          ids: allNotificationIds,
        });
        setReadMsgs([]);
        handleFetchNotifications();
      } catch (error) {
        setError(errorHandler(error));
        setTimeout(() => {
          setError("");
        }, 2000);
      appInsights.trackException({
        exception : new Error(JSON.stringify(error)),
        properties: {fileName : HeaderActionButtons}
      })
      }
    }
    setIsLoading(false);
  }, [readMsgs]);

  const handleClearAllMsgs = async () => {
    let allNotificationIds = [];
    for (let i = 0; i < notifications.length; i++) {
      allNotificationIds.push(notifications[i].id);
    }
    setIsLoading(true);
    try {
      await putData(apiEndpoints.SET_NOTIFICATION_AS_READ, {
        ids: allNotificationIds,
      });
      setNotifications([]);
      handleFetchNotifications();
    } catch (error) {
      setError(errorHandler(error));
      setTimeout(() => {
        setError("");
      }, 2000);
      appInsights.trackException({
        exception : new Error(JSON.stringify(error)),
        properties: {fileName : HeaderActionButtons}
      })
    }
    setIsLoading(false);
    setReadMsgs([]);
  };

  useEffect(() => {
    handleFetchNotifications();
  }, []);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
        handleSetMessageAsRead();
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [open, handleSetMessageAsRead]);
  const logout = (): void => {
    clearUserInfo();
    history.push(ROUTES.LOGIN);
  };
  return (
    <Fragment>
      <div className={`${styles.actionButtons} ${sidebar ? styles.open : ""}`}>
        <div className={styles.notificationBox} ref={ref}>
          {newMsg && <div className={styles.notificationDot}></div>}
          <a
            href="##"
            // id="navbarDropdownBell"
            className={styles.dropdownNotificationButtonArea}
            role="button"
            // data-toggle="dropdown"
            // aria-haspopup="true"
            // aria-expanded="false"
            onClick={() => {
              setOpen(!open);
            }}
          >
            <img src={NotificationIcon} alt="Notification" />
          </a>

          {open && (
            <div
              className={` ${styles.dropdownNotificationMenu} main-menu`}
              // aria-labelledby="navbarDropdownBell"
            >
              <div className={styles.notificationHeader}>
                <p className={styles.notifyheading}>Notifications</p>
                {notifications.length > 0 && (
                  <div className={styles.clear_close}>
                    <small
                      className={styles.clear_all}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClearAllMsgs();
                      }}
                    >
                      Clear all
                    </small>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="main-menu"
                      aria-label="Close"
                      onClick={() => {
                        setOpen(false);
                        handleSetMessageAsRead();
                      }}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                )}
              </div>

              {isLoading ? (
                <div className="no-advancly-data">
                  <Loader type="lg" variant="blue" />
                  <div className="mt-3 text-center">
                    Loading Notifications...
                  </div>
                </div>
              ) : (
                <div
                  className={` ${styles.notification_overview}`}
                  onClick={(e) => e.stopPropagation()}
                >
                  {error && (
                    <div>
                      <p className="alert alert-danger alert-dismissable">
                        {error}
                      </p>
                    </div>
                  )}

                  <div className={styles.notification}>
                    {notifications.length === 0 ? (
                      <p className="text-center color-blue">
                        You have no notifications
                      </p>
                    ) : (
                      notifications?.map((item, index) => (
                        <div key={item.id}>
                          <div className={styles.notification_content}>
                            <div className={styles.notifyAlert}>
                              <p className={styles.notification_title}>
                                {item.title}
                              </p>
                              {newMsg && (
                                <div>
                                  <div
                                    className={styles.notificationContentDot}
                                  ></div>
                                </div>
                              )}
                            </div>
                            {moreContent && item.id === selectedMsg && (
                              <small className={styles.notification_contents}>
                                {item.message}
                              </small>
                            )}
                            <small className={styles.notification_time}>
                              {moment(item.createdAt)
                                .startOf("minute")
                                .fromNow()}
                            </small>
                            <button
                              className={styles.seeMore}
                              onClick={() => {
                                setSelectedMsg(item.id);
                                setMoreContent(!moreContent);
                                if (!readMsgs.includes(item.id)) {
                                  setReadMsgs([...readMsgs, item.id]);
                                }
                              }}
                            >
                              {moreContent && item.id === selectedMsg
                                ? "Hide"
                                : "See more"}
                            </button>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        {/* end of notification bell */}
        <div className={styles.verticalBar}></div>
        <div className={styles.profileNameBox}>
          <div className={styles.profileNameInnerBox}>
            <div className={styles.profileNameInnerBoxWithContent}>
              {getFirstLetter(capitalizeFirstLetter(user?.first_name))}
            </div>
          </div>
        </div>
        <div className={styles.dropdown}>
          <a
            href="##"
            id="navbarDropdown"
            className={styles.dropdownButtonArea}
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img src={CaretIcon} alt="Caret Dropdown" />
          </a>
          <div
            className={`dropdown-menu ${styles.dropdownMenu}`}
            aria-labelledby="navbarDropdown"
          >
            <h5 className={styles.profileName}>
              {`${user?.first_name} ${user?.last_name}`}
            </h5>
            <p className={styles.profileEmail}>{user?.email}</p>

            <hr />

            <Link className={styles.navLink} to={ROUTES.PROFILE_SETTINGS}>
              <span>Settings</span>
            </Link>
            <p
              className={`${styles.navLink} ${styles.logoutLink}`}
              onClick={logout}
            >
              <span>Log Out</span>
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
