import React, {
  Fragment,
  SetStateAction,
  useState,
  Dispatch,
  useEffect,
} from "react";
import { ReactComponent as SuccessIcon } from "../../../assets/img/coin-icon.svg";
import { postData } from "../../../apis/apiMethods";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { useTypedSelector } from "../../../hooks/redux-hooks/useTypedSelector";
import Loader from "../../Loader/Loader.component";
import { errorHandler } from "../../../helpers/errorHandler";
import { selectWithdrawal } from "../../../redux/selectors/withdrawalSelector";
import { CustomPinInputField } from "../../CustomHTMLElements/CustomPinInputField.component";
import { useActions } from "../../../hooks/redux-hooks/useActions";
import { appInsights } from "../../AppInsight/AppInsight";

interface IWithdrawalModalStepFour {
  closeModal: () => void;
  setError: Dispatch<SetStateAction<string>>;
  setSuccess: Dispatch<SetStateAction<string>>;
  success: string;
}

const WithdrawalModalStepFour: React.FC<IWithdrawalModalStepFour> = ({
  closeModal,
  setError,
  setSuccess,
  success,
}) => {
  const [loading, setLoading] = useState(false);
  const [pin, setPin] = useState<string | null>(null);
  const [isValidPin, setIsValidPin] = useState(false);

  const withdrawalInfo = useTypedSelector(selectWithdrawal);
  const { walletId, amount, comment } = withdrawalInfo;
  const { setWithdrawalInfo } = useActions();

  useEffect(() => {
    if (pin?.length === 4) {
      setIsValidPin(true);
    } else {
      setIsValidPin(false);
    }
  }, [pin]);

  const onSubmit = async () => {
    setLoading(true);
    setError("");
    setSuccess("");

    const reqBody = {
      wallet_id: Number(walletId),
      amount: Number(amount),
      narration: comment,
      pin: pin?.toString(),
    };

    try {
      await postData(apiEndpoints.MAKE_WITHDRAWAL, reqBody);
      setLoading(false);
      setSuccess(
        "Withdrawal processing… Your account will be credited shortly."
      );
      setPin(null);
      setTimeout(() => {
        closeModal();
      }, 10000);
    } catch (error) {
      setLoading(false);
      setError(errorHandler(error));
      setPin(null);
      appInsights.trackException({
        exception : new Error(JSON.stringify(error)),
        properties: { fileName: WithdrawalModalStepFour },
      });
    }
  };
  return (
    <div>
      <div className="modal-header p-0">
        <h5 className="page-subtitle">
          {success ? "Successful" : "Transaction PIN"}
        </h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={closeModal}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="d-flex text-center justify-content-center align-items-center flex-column py-5 ">
        {loading ? (
          <Loader type="lg" variant="blue" />
        ) : success ? (
          <Fragment>
            <div className="d-flex text-center justify-content-center align-items-center flex-column">
              <SuccessIcon />
              <h5 className="page-subtitle my-3">{success}</h5>
            </div>
            <div className="mt-5">
              <button
                type="button"
                className="btn btn-sm advancly-white-btn w-100"
                onClick={closeModal}
              >
                Done
              </button>
            </div>
          </Fragment>
        ) : (
          <div className="w-100">
            <form onSubmit={onSubmit}>
              <CustomPinInputField
                setValue={(value: string) => setPin(value)}
                length={4}
                label="Enter transaction PIN to proceed"
                focus={true}
              />

              <div className="d-flex justify-content-between mt-5">
                <button
                  type="button"
                  className="btn btn-sm advancly-white-btn w-50 mr-2"
                  onClick={() => {
                    setWithdrawalInfo({
                      ...withdrawalInfo,
                      currentStep:
                        withdrawalInfo.accountDetails.type === "new" ? 3 : 2,
                    });
                  }}
                >
                  Back
                </button>
                <button
                  type="submit"
                  className="btn btn-sm advancly-btn w-50 ml-2"
                  disabled={loading || !isValidPin}
                >
                  Continue
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};
export default WithdrawalModalStepFour;
