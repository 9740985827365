import React, { useEffect, useState, Fragment } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar.component";
import styles from "./AuthLayout.module.scss";
import advestAdvanclyLogo from "../../assets/img/advestAdvanclyLogo.svg";
import { ROUTES } from "../../helpers/routes";
import { HeaderActionButtons } from "./../HeaderActionButtons/HeaderActionButtons.component";
import DashboardCompleteProfileModal from "./../DashboardCompleteProfileModal/DashboardCompleteProfileModal.component";
import WalletModal from "./../../pages/Wallets/WalletModal/WalletModal.component";
import { useTypedSelector } from "../../hooks/redux-hooks/useTypedSelector";
import { selectForcePasswordChange } from "../../redux/selectors/userSelector";

interface IAuthLayout {}

const AuthLayout: React.FC<IAuthLayout> = ({ children }) => {
  const [sidebar, setSidebar] = useState(true);
  const deviceWidth = window.innerWidth;
  const { pathname } = useLocation();
  const history = useHistory();

  const forcePasswordChange = useTypedSelector(selectForcePasswordChange);
  useEffect(() => {
    // Setting Sidebar Dynamically based on window screen width
    if (deviceWidth < 992) {
      setSidebar(false);
    } else {
      setSidebar(true);
    }
  }, [deviceWidth, pathname]);

  useEffect(() => {
    if (sidebar) {
      setSidebar(false);
    }
    // eslint-disable-next-line
  }, [pathname]);

  const toggleSidebar = () => {
    setSidebar(!sidebar);
  };

  useEffect(() => {
    if (forcePasswordChange) {
      history.push({
        pathname: ROUTES.PROFILE_SETTINGS,
        state: { forcePasswordChange },
      });
    }
    // eslint-disable-next-line
  }, [pathname, forcePasswordChange]);

  return (
    <Fragment>
      <div className={styles.authPagesWrapper}>
        <div className={styles.headerSection}>
          <div className={styles.logo}>
            <Link to={ROUTES.DASHBOARD}>
              <img src={advestAdvanclyLogo} alt="Advest Logo" />
            </Link>
          </div>
          <HeaderActionButtons sidebar={sidebar} />
        </div>
        <div
          className={`${styles.sidebar} ${sidebar ? "" : styles.hideSidebar}`}
        >
          <Sidebar />
        </div>
        <div
          className={`${styles.main} ${sidebar ? "" : styles.noSidebar}`}
          style={{ position: "relative" }}
        >
          <div className={styles.children}>
            <div
              className={
                sidebar
                  ? `${styles.menuToggleBtn} ${styles.open}`
                  : styles.menuToggleBtn
              }
              onClick={toggleSidebar}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div className={styles.manipulateChildren}>{children}</div>
          </div>
        </div>
      </div>

      {/* Please don't remove this button  */}
      <button
        className="d-none"
        data-toggle="modal"
        data-target="#dashboardCompleteProfileModal"
        id="promptCompleteProfileModal"
      ></button>
      <DashboardCompleteProfileModal />
      <WalletModal />
    </Fragment>
  );
};
export default AuthLayout;
