import React, { Fragment } from "react";
import styles from "./IdentityInformation.module.scss";
import { ROUTES } from "../../helpers/routes";
import useFunderKycDocuments from "../../hooks/custom-hooks/useFunderKycDocuments";
import { useTypedSelector } from "../../hooks/redux-hooks/useTypedSelector";
import { selectUserId } from "../../redux/selectors/userSelector";
import PageLoader from "../PageLoader/PageLoader.component";
import Alert from "../Alert/Alert.component";
import { errorHandler } from "../../helpers/errorHandler";
import { KycDocumentCard } from "../KycDocumentCard/KycDocumentCard.component";
import CONFIG from "../../helpers/config";

interface IdentityInformationProps {
  funder: any;
}

const IdentityInformationIndividual: React.FC<
  IdentityInformationProps
> = () => {
  const funderId = useTypedSelector(selectUserId);
  const { data, isLoading, error } = useFunderKycDocuments({ funderId });
  return (
    <Fragment>
      {error && <Alert message={errorHandler(error)} />}
      {isLoading ? (
        <PageLoader />
      ) : (
        <div className={`two-grid-layout ${styles.contactLanding}`}>
          <KycDocumentCard
            route={ROUTES.PROFILE_SETTINGS_GOV_ISSUED_ID}
            iconClass="ri-file-text-line"
            title=" Government Issued ID"
            subTitle="Upload your Government-Issued ID Document, Any one of the following documents would be accepted; Driver's License,Voter's Card,National ID Card or International Passport
."
            data={data}
            field={CONFIG.DOCUMENT_CODES.governmentIssuedIdentity}
          />
          <KycDocumentCard
            route={ROUTES.PROFILE_SETTINGS_ADDRESS_PROOF_INDIVIDUAL}
            iconClass="ri-file-3-line"
            title="Proof of Address"
            subTitle="Upload your Proof of Address, Any Utility bill such as Electricity bill or Waste bill would be accepted."
            data={data}
            field={CONFIG.DOCUMENT_CODES.proofOfAddressIndividual}
          />
        </div>
      )}
    </Fragment>
  );
};

export default IdentityInformationIndividual;
