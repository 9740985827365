import { useEffect, useState } from "react";
import { postData } from "../../apis/apiMethods";
import { errorHandler } from "../../helpers/errorHandler";
import { apiEndpoints } from "../../apis/apiEndpoints";
import Loader from "../Loader/Loader.component";
import { useTypedSelector } from "../../hooks/redux-hooks/useTypedSelector";
import { selectUser } from "../../redux/selectors/userSelector";
import { appInsights } from "../AppInsight/AppInsight";
import { Divider, Form, Input, Modal } from "antd";
import useOtpTypes from "../../hooks/custom-hooks/useOtp";

interface IProps {
  handleCancel: () => void;
  isModalOpen: boolean;
  handleProceed: () => void;
  otp: string;
  setOtp: (otp: string) => void;
}

const AccountUpdateOtpModal: React.FC<IProps> = ({
  isModalOpen,
  handleCancel,
  handleProceed,
  otp,
  setOtp,
}) => {
  useTypedSelector(selectUser);
  const { email } = useTypedSelector(selectUser);
  const [otpLoading, setOtpLoading] = useState(false);
  const [, setOtpSuccess] = useState("");
  const [, setOtpError] = useState<null | string>(null);
  const [form] = Form.useForm();

  const {
    data: otpTypes,
    error: otpTypesError,
    isLoading: isLoadingOtpTypes,
  } = useOtpTypes();

  useEffect(() => {
    setOtp("");
    sendOtp();
  }, [isModalOpen]);

  const handleReset = () => {
    form.resetFields();
  };

  const sendOtp = async () => {
    setOtpLoading(true);
    setOtpError(null);
    setOtpSuccess("");
    setOtp("");
    try {
      await postData(`/account/verify`, {
        email,
        otpType: otpTypes?.find(
          (o: { name: string }) =>
            o?.name === "AddOrUpdateCustomerOtherBankAccount"
        )?.id,
      });
      setOtpLoading(false);
      setOtpSuccess(
        "OTP has been sent to your email address and phone number."
      );
    } catch (error) {
      setOtpLoading(false);
      setOtpError(errorHandler(error));
      appInsights.trackException({
        exception : new Error(JSON.stringify(error)),
        properties: { fileName: "AccountUpdateOtp" },
      });
    }
  };

  return (
    <Modal
      title={`OTP Verification`}
      open={isModalOpen}
      onCancel={() => {
        handleReset();
        handleCancel();
      }}
      footer={null}
    >
      <Divider />
      <>
        <p>
          A One-Time Password (OTP) has been sent to: <br /> {email}
        </p>
        <div className="row">
          <div className="col-md-12">
            <Input
              type="number"
              maxLength={6}
              placeholder="Enter OTP"
              onChange={(e) => setOtp(e.target.value)}
              value={otp}
            />
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-center my-4 flex-wrap">
          <p className="d-flex flex-wrap">
            <span
              className={`color-blue ${
                otpLoading ? "cursor-disabled" : "cursor-pointer"
              }`}
              onClick={sendOtp}
            >
              Resend OTP {otpLoading && <Loader />}
            </span>
          </p>
          <button
            className="btn advancly-btn btn-sm"
            disabled={otpLoading || !otp}
            onClick={handleProceed}
          >
            Next
          </button>
        </div>
      </>
    </Modal>
  );
};
export default AccountUpdateOtpModal;
