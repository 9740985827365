import { useRef } from "react";
import { useState } from "react";
import { ResetSecurityQuestion } from "./ResetSecurityQuestion";
import { ResetPin } from "./ResetPin";

interface IResetPinModal {}

const ResetPinModal: React.FC<IResetPinModal> = () => {
  const [section, setSection] = useState<"questions" | "pin">("questions");
  const dismissRef = useRef<null | HTMLButtonElement>(null);
  const dismissModal = () => {
    dismissRef?.current?.click();
  };

  return (
    <div
      className="modal fade"
      id="resetPinModal"
      tabIndex={-1}
      aria-labelledby="resetPinModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content p-4">
          <div className="modal-header p-0 pb-2">
            <h5 className="page-subtitle">
              {section === "questions"
                ? "Security Questions"
                : "Transaction PIN"}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              ref={dismissRef}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body px-0">
            {(() => {
              switch (section) {
                case "questions":
                  return <ResetSecurityQuestion setSection={setSection} />;
                case "pin":
                  return <ResetPin dismissModal={dismissModal} />;
                default:
                  return null;
              }
            })()}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ResetPinModal;
