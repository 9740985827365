import React from "react";
import styles from "./DismissableAlert.module.scss";

interface IDismissableAlert {
  onDismiss?: () => void;
  type: "danger" | "success" | "warning" | "info" | "dashboard";
}

const DismissableAlert: React.FC<IDismissableAlert> = ({
  children,
  onDismiss,
  type,
}) => {
  return (
    <div
      className={`alert alert-dismissible fade show ${
        type === "dashboard" ? styles.dashboardAlert : `alert-${type}`
      }`}
      role="alert"
    >
      <div className="text-center">{children}</div>
      {type === "dashboard" ? null : (
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
          onClick={() => {
            if (onDismiss) {
              onDismiss();
            }
          }}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      )}
    </div>
  );
};

export default DismissableAlert;
