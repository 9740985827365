import React, { useEffect } from "react";
import { BackButton } from "../../../components/BackButton/BackButton.component";
import { FilePicker } from "../../../components/FilePicker/FilePicker.component";
import CONFIG from "../../../helpers/config";
import { appInsights } from "../../../components/AppInsight/AppInsight";

interface ProfileSettingsGovIdProps {}

const DROPDOWN_OPTIONS = [
  { value: "Driver's License", optionText: "Driver's License" },
  { value: "Voter's Card", optionText: "Voter's Card" },
  { value: "National ID Card", optionText: "National ID Card" },
  { value: "International Passport", optionText: "International Passport" },
  { value: "Others", optionText: "Others" },
];

export const ProfileSettingsGovId: React.FC<ProfileSettingsGovIdProps> = () => {
  useEffect(() => {
    appInsights.trackPageView({
      name: "ProfileSettingsGovid.tsx",
      isLoggedIn: true,
    });
  }, []);
  return (
    <div>
      <BackButton text="Go back" />

      <div className="mt-4">
        <FilePicker
          DROPDOWN_OPTIONS={DROPDOWN_OPTIONS}
          document_type_code={CONFIG.DOCUMENT_CODES.governmentIssuedIdentity}
        />
      </div>
    </div>
  );
};
