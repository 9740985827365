import { useQuery } from "react-query";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { getData } from "../../apis/apiMethods";

const getDashboardStats = async () => {
  const { data } = await getData(apiEndpoints.DASHBOARD_STATS);
  return data;
};

export default function useDashboardStats() {
  return useQuery("getDashboardStats", getDashboardStats, {
    refetchOnWindowFocus: true,
  });
}
