import React, { useState } from "react";
import { errorHandler } from "../../helpers/errorHandler";
import CustomInputField from "../CustomHTMLElements/CustomInputField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { yupValidators } from "../../helpers/yupValidators";
import Loader from "../Loader/Loader.component";
import Alert from "../Alert/Alert.component";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { getData, postData } from "../../apis/apiMethods";
import { useTypedSelector } from "../../hooks/redux-hooks/useTypedSelector";
import { selectKycName, selectUser } from "../../redux/selectors/userSelector";
import { useActions } from "../../hooks/redux-hooks/useActions";
import { reValidateUserActivationStatus } from "../../helpers/isUserActivated";
import { appInsights } from "../AppInsight/AppInsight";

const schema = yup.object().shape({
  kyc_number: yupValidators.genericRequired({
    message: "Please enter your Bank Verification Number",
    max: 20,
  }),
  kycName: yupValidators.generic({}),
});

interface IIndividualKYC {
  funder: any;
}
const IndividualKYC: React.FC<IIndividualKYC> = ({ funder }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const user = useTypedSelector(selectUser);
  const kycName = useTypedSelector(selectKycName);
  const { setCurrentUser } = useActions();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schema),
  });
  const onSubmit = async ({ kyc_number }: any) => {
    setLoading(true);
    setError("");
    setSuccess("");

    const reqBody = {
      kyc_number,
      country_code: funder?.country,
      kyc_type: 1,
    };

    try {
      await postData(apiEndpoints.UPDATE_USER_KYC, reqBody);

      const {
        data: { kyc_resolved_lastname, kyc_resolved_firstname },
      } = await getData(apiEndpoints.USER);
      // Revalidate User Status if he is initally not activated
      if (!user.activated) {
        const activated = await reValidateUserActivationStatus();
        setCurrentUser({
          ...user,
          activated,
          kyc_resolved_lastname,
          kyc_resolved_firstname,
        });
      }
      setSuccess("KYC information updated successfully.");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(errorHandler(error));
      appInsights.trackException({
        exception : new Error(JSON.stringify(error)),
        properties: { fileName: "IndividualKYC" },
      });
    }
  };

  return (
    <div className="mt-4">
      <h5 className="page-subtitle">KYC Information</h5>
      <form onSubmit={handleSubmit(onSubmit)}>
        {error && <Alert message={error} />}
        {success && <Alert message={success} type="success" />}
        <div className="row mt-4">
          <div className="col-12 col-md-6">
            <CustomInputField
              type="number"
              maxLength={20}
              {...register("kyc_number")}
              placeholder="e.g. 22222222222"
              label="Identity Number"
              errors={errors.kyc_number}
              defaultValue={funder?.bvn}
              readOnly={funder?.bvn}
            />
          </div>
          <div className="col-12 col-md-6">
            <CustomInputField
              type="text"
              placeholder="e.g. John Doe"
              label="KYC Name"
              defaultValue={kycName}
              errors={errors.kycName}
              {...register("kycName")}
              readOnly
            />
          </div>
        </div>

        {!funder?.bvn && (
          <button
            type="submit"
            className="btn btn-sm advancly-white-btn"
            disabled={loading}
          >
            Update
            {loading && <Loader />}
          </button>
        )}
      </form>
    </div>
  );
};

export default IndividualKYC;
