import { useQuery } from "react-query";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { getData } from "../../apis/apiMethods";

const getBanks = async ({ queryKey }: any) => {
  const { country_code } = queryKey[1];
  const data = await getData(
    `${apiEndpoints.ALL_BANKS}?country_code=${country_code}`
  );
  return data;
};

export default function useBanks(country_code: string) {
  return useQuery(
    [
      "getBanks",
      {
        country_code,
      },
    ],
    getBanks
  );
}
