type CurrencyType = "USD" | "NGN" | "KES";

interface FormatterParser {
  formatter: (value?: number | string) => string;
  parser: (value?: string) => number;
}

const currencySymbols: Record<CurrencyType, string> = {
  USD: "$",
  NGN: "₦",
  KES: "KSh",
};

export const antdCurrencyFormatterParser = (
  currency: CurrencyType,
): FormatterParser => {
  const symbol = currencySymbols[currency];

  const formatter = (value?: number | string): string => {
    if (value === undefined || value === null) return "";
    // Format the number with commas as thousand separators
    const formattedNumber = String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return `${formattedNumber}`;
  };

  const parser = (value?: string): number => {
    if (!value) return 0;
    // Remove the currency symbol and commas, then parse the number
    return parseInt(
      value.replace(new RegExp(`\\${symbol}\\s?|,`, "g"), ""),
      10,
    );
  };

  return { formatter, parser };
};
