import React, { useRef, useState } from "react";
import { Button, Divider, FormInstance, FormProps, message, Modal } from "antd";
import AntdReusableForm from "../../CustomHTMLElements/AntdReusableForm";
import { useShowInterestFormConfig } from "./FormConfig";
import { useTypedSelector } from "../../../hooks/redux-hooks/useTypedSelector";
import { selectOfflineInvestment } from "../../../redux/selectors/investmentSelector";
import { useRouteMatch, match } from "react-router-dom";
import { postData } from "../../../apis/apiMethods";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { errorHandler } from "../../../helpers/errorHandler";
import { logAppInsightErrors } from "../../../helpers";
import { selectUserCurrencies } from "../../../redux/selectors/userSelector";

interface IModalProps {
  isModalOpen: boolean;
  handleCancel: () => void;
  lcyProductDetailsId: number | undefined;
  usdProductDetailsId: number | undefined;
}
interface IFormData {
  currency: string;
  amount?: number;
  readiness_level?: string;
  investment_duration?: string;
}
const ShowInterestModal = ({
  isModalOpen,
  handleCancel,
  usdProductDetailsId,
  lcyProductDetailsId,
}: IModalProps) => {
  const currencyList: {
    currencyType: "Foreign" | "Local";
    currencyCode: string;
  }[] = useTypedSelector(selectUserCurrencies);
  const match: match<{ id: string }> = useRouteMatch();
  const formRef = useRef<FormInstance>(null); // useRef for functional component
  const offlineInvestmentInfo = useTypedSelector(selectOfflineInvestment);
  const { showInterestFormConfig } = useShowInterestFormConfig();
  const [isLoading, setIsLoading] = useState(false);

  const handleReset = () => {
    formRef.current?.resetFields();
  };

  const formConfig = {
    ...showInterestFormConfig,
    formRef: formRef,
  };

  const onFinish: FormProps<IFormData>["onFinish"] = async (values) => {
    if (offlineInvestmentInfo?.duration) {
      setIsLoading(true);
      try {
        const response = await postData(apiEndpoints.INVESTMENT_PROSPECT, {
          currency: values.currency,
          amount: values.amount,
          tenor: offlineInvestmentInfo?.duration?.days,
          investmentProductDetailId:
            values.currency === "USD"
              ? usdProductDetailsId
              : lcyProductDetailsId,
          readinessLevel: values.readiness_level,
        });
        message.success(response?.message);
        handleCancel();
      } catch (error) {
        message.error(errorHandler(error));
        logAppInsightErrors(error, "ShowInterestModal.tsx");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onFinishFailed: FormProps<IFormData>["onFinishFailed"] = (
    errorInfo
  ) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <Modal
        title={
          <>
            <p className="-tw-mb-[7px]">How much do you want to invest?</p>
            <Divider />
          </>
        }
        open={isModalOpen}
        onCancel={() => {
          handleReset();
          handleCancel();
        }}
        footer={null}
      >
        <AntdReusableForm
          {...formConfig}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Button
            htmlType="submit"
            type="primary"
            size="large"
            className="tw-mt-5"
            block
            loading={isLoading}
          >
            Submit
          </Button>
        </AntdReusableForm>
      </Modal>
    </div>
  );
};

export default ShowInterestModal;
