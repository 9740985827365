import { useQuery } from "react-query";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { getData } from "../../apis/apiMethods";

type paramProps = {
  investmentId: number;
};

const getAnInvestmentTermSheet = async ({ queryKey }: any) => {
  const { investmentId } = queryKey[1];
  const { data } = await getData(
    `${apiEndpoints.INVESTMENT_TERM_SHEET}?detail_id=${investmentId}`
  );
  return data;
};

export default function useInvestmentTermSheet({ investmentId }: paramProps) {
  return useQuery(
    ["getAnInvestmentTermSheet", { investmentId }],
    getAnInvestmentTermSheet,
    {
      refetchOnWindowFocus: false, // Refetch on window focus
      enabled: investmentId ? true : false, // Only run when there is investmentId
    }
  );
}
