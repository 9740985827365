import React, { Dispatch, Fragment, SetStateAction, useState } from "react";
import styles from "./InvestmentLanding.module.scss";
import { useActions } from "../../../hooks/redux-hooks/useActions";
import { TInvestmentType } from "../../../interfaces/investment";
import { useTypedSelector } from "../../../hooks/redux-hooks/useTypedSelector";
import { selectInvestmentLocalCurrencyId } from "../../../redux/selectors/investmentSelector";
import { currenciesSelector } from "../../../redux/selectors/currenciesSelector";
import { selectUserCurrencies } from "../../../redux/selectors/userSelector";

interface IInvestModalStepZero {
  closeModal: () => void;
  setError: Dispatch<SetStateAction<string>>;
}

export const InvestmentLanding: React.FC<IInvestModalStepZero> = ({
  closeModal,
  setError,
}) => {
  const currencyList: {
    currencyType: "Foreign" | "Local";
    currencyCode: string;
  }[] = useTypedSelector(selectUserCurrencies);
  const [type, setType] = useState<TInvestmentType>("");
  const { setInvestmentType } = useActions();
  const lcy_product_id = useTypedSelector(selectInvestmentLocalCurrencyId);

  return (
    <Fragment>
      <div className="modal-header border-bottom-0 p-0 mb-4">
        <h5 className="page-subtitle">How would you like to Invest?</h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={closeModal}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      {currencyList[0]?.currencyCode === "NGN" && lcy_product_id && (
        <div
          className={`${styles.modalCard} ${
            type === "online" && styles.active
          }`}
          onClick={() => setType("online")}
        >
          <div className={styles.radioButton}></div>
          <div>
            <h6 className="font-weight-bold">Online Investment</h6>
            <p>Invest in Advancly’s debt funding directly from the platform.</p>
          </div>
        </div>
      )}
      <div
        className={`${styles.modalCard} ${type === "offline" && styles.active}`}
        onClick={() => setType("offline")}
      >
        <div className={styles.radioButton}></div>
        <div>
          <h6 className="font-weight-bold">Log Offline Investment</h6>
          <p>
            Invested manually? Upload details of your investment so you can
            monitor the details of your investment on this platform.
          </p>
        </div>
      </div>
      <button
        className="btn advancly-btn btn-sm w-100 mt-3"
        onClick={() => {
          setInvestmentType(type);
          setError("");
        }}
        disabled={!type}
      >
        Continue
      </button>
    </Fragment>
  );
};
