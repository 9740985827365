import { antdCurrencyFormatterParser } from "../../../helpers/antdInputNumberFormatterAndParse";
import { IFormProps } from "../../CustomHTMLElements/AntdReusableForm";
import { useInvestmentReadinessOptions } from "../../../hooks/custom-hooks/useInvestment";
import React, { useEffect, useState } from "react";
import { message, Spin } from "antd";
import { useTypedSelector } from "../../../hooks/redux-hooks/useTypedSelector";
import {
  selectOfflineInvestment,
  selectSelectedInvestment,
} from "../../../redux/selectors/investmentSelector";
import { useActions } from "../../../hooks/redux-hooks/useActions";
import { TRepayment_Types } from "../../../interfaces/investment";
import useInterestCalculation from "../../../hooks/custom-hooks/useInterestCalculation";
import InvestmentOptions from "./InvestmentOptions";
import {selectUserCurrencies} from "../../../redux/selectors/userSelector";

export const useShowInterestFormConfig = () => {
  const { setOfflineInvestmentInfo } = useActions();
  const currencyList: {
    currencyType: "Foreign" | "Local";
    currencyCode: string;
  }[] = useTypedSelector(selectUserCurrencies);
  const selectedInvestment = useTypedSelector(selectSelectedInvestment);
  const offlineInvestmentInfo = useTypedSelector(selectOfflineInvestment);
  const { data, isLoading, isError } = useInvestmentReadinessOptions();

  const { amount, duration, repaymentPlan, currency, numberOfDaysInYear } =
    offlineInvestmentInfo;

  const [extraInfo, setExtraInfo] = useState<{
    monthlyInterestRepayment: string;
    estimatedReturns: string;
  }>({
    monthlyInterestRepayment: "",
    estimatedReturns: "",
  });


  const filteredInvestmentRange: any = selectedInvestment?.interest_ranges.find(
    (range: any) =>
      range.lower_amount <= Number(5000) && range.upper_amount >= Number(5000),
  );
  const { data: calcuatedData, isLoading: calculatedDataLoading } =
    useInterestCalculation({
      principalAmount: Number(amount),
      interestRate: duration?.interest,
      tenure: duration?.days,
      repaymentPlan,
      numberOfDaysInYear: Number(numberOfDaysInYear),
    });

  useEffect(() => {
    if (
      calcuatedData?.maturity_amount &&
      calcuatedData?.monthly_interest_payable
    ) {
      setExtraInfo({
        estimatedReturns: calcuatedData?.maturity_amount,
        monthlyInterestRepayment: calcuatedData?.monthly_interest_payable,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calcuatedData?.maturity_amount, calcuatedData?.monthly_interest_payable]);

  const onChangeRepaymentFrequency = (plan: TRepayment_Types) => {
    if (amount === "") {
      return message.error("Please enter investment amount");
    }
    setOfflineInvestmentInfo({
      ...offlineInvestmentInfo,
      amount,
      currency: selectedInvestment?.currencyCode,
      repaymentPlan: plan,
      numberOfDaysInYear,
    });
  };

  const onChangAmount = (amount: number) => {
    setOfflineInvestmentInfo({
      ...offlineInvestmentInfo,
      amount,
    });
  };

  const returnInvestmentCurrencies = () => {
  if (selectedInvestment?.usd_product_id && selectedInvestment?.lcy_product_id){
    return currencyList.map((currency: any) => ({
      id: currency.currencyCode,
      name: currency.currencyCode,
    }))
  } else if (selectedInvestment?.usd_product_id && !selectedInvestment?.lcy_product_id) {
      const currency = currencyList.filter(c => c?.currencyCode === "USD")
      if (currency){
        return currency.map((currency: any) => ({
          id: currency.currencyCode,
          name: currency.currencyCode,
        }))
      }
    }else {
    const currency = currencyList.filter(c => c?.currencyCode !== "USD")
    if (currency){
      return currency.map((currency: any) => ({
        id: currency.currencyCode,
        name: currency.currencyCode,
      }))
    }
  }

  }

  const { parser, formatter } = antdCurrencyFormatterParser("USD");
  const showInterestFormConfig: IFormProps = {
    fields: [
      {
        name: "currency",
        label: "Currency",
        type: "select" as "select",
        options: returnInvestmentCurrencies(),
        rules: [{ required: true, message: "Please select investment currency" }],
        initialValue: selectedInvestment?.currencyCode,
      },
      {
        name: "amount",
        label: "Amount",
        type: "number" as "number",
        rules: [
          { required: true, message: "Please enter an amount" },
          { type: "number", min: 1, message: "Amount must cannot be zero" },
        ],
        formatter: formatter,
        parser: parser,
        handleChange: (value) => onChangAmount(value),
      },
      {
        name: "readiness_level",
        label: isLoading ? (
          <span>
            Readiness Level Loading... <Spin size="small" />
          </span>
        ) : (
          "Readiness Level"
        ),
        type: "select" as "select",
        options: data,
        rules: [{ required: true, message: "Please your readiness level" }],
      },
      {
        name: "duration",
        label: "How long do you want to invest",
        type: "custom",
        customNode: (
          <InvestmentOptions
            investmentRange={filteredInvestmentRange}
            duration={duration}
            isError={isError}
            isLoading={isLoading}
            repaymentPlan={repaymentPlan}
            setInvestmentInfo={setOfflineInvestmentInfo}
            onChangeRepaymentFrequency={onChangeRepaymentFrequency}
            calculatedDataLoading={calculatedDataLoading}
            extraInfo={extraInfo}
            setExtraInfo={setExtraInfo}
            currency={currency}
          />
        ),
      },
    ],
  };

  return {
    showInterestFormConfig,
  };
};
