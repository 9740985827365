export enum actionType {
  SET_LOGGED_IN_USER = "SET_LOGGED_IN_USER",
  SET_LOGGED_IN_USER_TOKEN = "SET_LOGGED_IN_USER_TOKEN",
  CLEAR_LOGGED_IN_USER = "CLEAR_LOGGED_IN_USER",
  SET_SIGN_UP = "SET_SIGN_UP",
  RESET_SIGN_UP = "RESET_SIGN_UP",
  SET_INVESTMENT_TYPE = "SET_INVESTMENT_TYPE",
  SET_INVESTMENT_STEP = "SET_INVESTMENT_STEP",
  SET_INVESTMENT_AGREEMENT_CHECK = "SET_INVESTMENT_AGREEMENT_CHECK",
  SELECT_INVESTMENT = "SELECT_INVESTMENT",
  SET_ONLINE_INVESTMENT_INFO = "SET_ONLINE_INVESTMENT_INFO",
  SET_OFFLINE_INVESTMENT_INFO = "SET_OFFLINE_INVESTMENT_INFO",
  RESET_INVESTMENT = "RESET_INVESTMENT",
  SET_RESET_PASSWORD_INFO = "SET_RESET_PASSWORD_INFO",
  SET_WITHDRAWAL_INFO = "SET_WITHDRAWAL_INFO",
  RESET_WITHDRAWAL = "RESET_WITHDRAWAL",
  SET_WALLET_TOPUP_INFO = "SET_WALLET_TOPUP_INFO",
  RESET_WALLET_TOPUP = "RESET_WALLET_TOPUP",
  SHOW_BALANCE = "SHOW_BALANCE",
  SET_INVESTMENT_ACCOUNTS = "SET_INVESTMENT_ACCOUNTS",
  SET_MINIMUM_TOP_UP_AMOUNTS = "SET_MINIMUM_TOP_UP_AMOUNTS",
  SET_USER_AVAILABLE_CURRENCIES = "SET_USER_AVAILABLE_CURRENCIES",
  LOADING_USER_AVAILABLE_CURRENCIES = "LOADING_USER_AVAILABLE_CURRENCIES",
  SET_USER_AVAILABLE_CURRENCIES_FAILED = "SET_USER_AVAILABLE_CURRENCIES_FAILED",
}
