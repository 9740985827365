import React, { useEffect } from "react";
import CustomInputField from "../../CustomHTMLElements/CustomInputField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { yupValidators } from "../../../helpers/yupValidators";
import { ITopUpInfo, PortfolioFundingSteps } from "../PortfolioModal.component";
import { handleFormatAmount } from "../../../helpers/formatAmount";
import { appInsights } from "../../AppInsight/AppInsight";

interface IProps {
  topUpInfo: ITopUpInfo;
  setTopUpInfo: (info: ITopUpInfo) => void;
  setCurrentStep: (step: PortfolioFundingSteps) => void;
}

const schema = yup.object().shape({
  amount: yupValidators.genericRequiredNumber({
    message: "Please enter the amount you want to fund",
    matches: "Please enter a valid amount",
    max: 20,
  }),
});

const FundingAmountModal = ({
  topUpInfo,
  setTopUpInfo,
  setCurrentStep,
}: IProps) => {
  useEffect(() => {
    appInsights.trackPageView({
      name: "FundingAmountModal.tsx",
      isLoggedIn: true,
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: {
      amount: topUpInfo.amount,
    },
  });

  const onSubmit = ({ amount }: { amount: number }) => {
    setTopUpInfo({ ...topUpInfo, amount: handleFormatAmount(String(amount)) });
    setCurrentStep(PortfolioFundingSteps.PaymentMethod);
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomInputField
          type="number"
          enableSeparator
          defaultValue={topUpInfo.amount}
          maxLength={20}
          {...register("amount")}
          placeholder="e.g. 100,000.00"
          label="Enter Amount"
          errors={errors.amount}
        />

        <div className="d-flex justify-content-between mt-5 pt-5">
          <button type="submit" className="btn btn-sm advancly-btn btn-block">
            Continue
          </button>
        </div>
      </form>
    </>
  );
};

export default FundingAmountModal;
