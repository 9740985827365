import React, { useState } from "react";
import { errorHandler } from "../../helpers/errorHandler";
import CustomInputField from "../CustomHTMLElements/CustomInputField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { yupValidators } from "../../helpers/yupValidators";
import Loader from "../Loader/Loader.component";
import Alert from "./../Alert/Alert.component";
import { postData } from "./../../apis/apiMethods";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { useHistory } from "react-router-dom";
import { ROUTES } from "./../../helpers/routes";
import {appInsights} from "../AppInsight/AppInsight";

const schema = yup.object().shape({
  password: yupValidators.genericRequired({
    message: "Please enter your password",
  }),
  newPassword: yupValidators.newPassword,
  confirmPassword: yupValidators.confirmPassword,
});

export default function ChangePassword() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schema),
  });
  const onSubmit = async ({
    password,
    newPassword,
    confirmPassword,
  }: any) => {
    setLoading(true);
    setError("");
    setSuccess("");

    if (newPassword !== confirmPassword) {
      setLoading(false);
      return setError("Your passwords do not match.");
    }

    const reqBody = {
      old_password: password,
      password1: newPassword,
      password2: confirmPassword,
    };

    try {
      await postData(apiEndpoints.CHANGE_PASSWORD, reqBody);
      setSuccess(
        "Your password has been changed successfully. Please log in with your new password."
      );
      setLoading(false);
      setTimeout(() => {
        history.push(ROUTES.LOGIN);
      }, 3000);
    } catch (error) {
      setLoading(false);
      setError(errorHandler(error));
      appInsights.trackException({
        exception : new Error(JSON.stringify(error)),
        properties: {fileName : ChangePassword}
      })
    }
  };
  return (
    <div className="mt-4">
      <h5 className="page-subtitle">Change Password</h5>
      <form onSubmit={handleSubmit(onSubmit)}>
        {error && <Alert message={error} />}
        {success && <Alert message={success} type="success" />}

        <div className="row mt-4">
          <div className="col-12 col-md-6">
            <CustomInputField
              type="password"
              maxLength={128}
              {...register("password")}
              label="Current Password"
              errors={errors.password}
            />
          </div>
          <div className="col-12 col-md-6">
            <CustomInputField
              type="password"
              maxLength={128}
              {...register("newPassword")}
              label="New Password"
              errors={errors.newPassword}
            />
          </div>
          <div className="col-12 col-md-6">
            <CustomInputField
              type="password"
              maxLength={128}
              {...register("confirmPassword")}
              label="Confirm New Password"
              errors={errors.confirmPassword}
            />
          </div>
        </div>

        <div className="d-flex justify-content-end align-items-center flex-wrap">
          <button
            type="reset"
            value="submit"
            className="btn btn-sm advancly-nobg-btn mr-3"
            disabled={loading}
          >
            Cancel
          </button>
          <button
            type="submit"
            value="submit"
            className="btn btn-sm advancly-white-btn"
            disabled={loading}
          >
            Change Password
            {loading && <Loader />}
          </button>
        </div>
      </form>
    </div>
  );
}
