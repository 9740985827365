import React from 'react';
import {Modal} from "antd";

interface PdfViewerProps {
    url?:string;
    open: boolean;
    onCancel: () => void;
}

const PDFViewer = ({ url, open, onCancel }: PdfViewerProps) => {


    return (
        <Modal
            title="PDF Document"
            open={open}
            footer={null}
            width="80%"
            onCancel={onCancel}
        >
            <object
                type={'application/pdf'}
                data={url}
                style={{
                    objectFit: "contain",
                    width: '100%',
                    height: "80vh"
                }}
            ></object>
        </Modal>
    );
};

export default PDFViewer;
