import React, { Dispatch, SetStateAction } from "react";
import styles from "./SignUpTabs.module.scss";
import { ReactComponent as RetailInvestorIcon } from "../../assets/img/user.svg";
import { ReactComponent as CorporateInvestorIcon } from "../../assets/img/building.svg";
import { ReactComponent as RightArrowIcon } from "../../assets/img/rightArrow.svg";
import { funderTypes } from "../../helpers/funderTypes";

interface ISignUpTabs {
  funderType: funderTypes;
  setFunderType: Dispatch<SetStateAction<funderTypes>>;
}

const SignUpTabs: React.FC<ISignUpTabs> = ({ funderType, setFunderType }) => {
  return (
    <div className={styles.tabsAlignment}>
      <div
        className={`${styles.tabItem} ${
          funderType === funderTypes.CORPORATE_FUNDER && styles.active
        }`}
        onClick={() => setFunderType(funderTypes.CORPORATE_FUNDER)}
      >
        <div className={styles.corporateIcon}>
          {" "}
          <CorporateInvestorIcon />
        </div>

        <div className={styles.investorTypeText}>
          {" "}
          <p className={styles.title}>Corporate Investor</p>
          <p className={styles.subTitle}>
            Invest and manage funds as an <br /> organization on the go.
          </p>
        </div>
        <div className={styles.rightArrow}>
          <RightArrowIcon />
        </div>
      </div>
      <div
        className={`${styles.tabItem} ${
          funderType === funderTypes.INDIVIDUAL_FUNDER && styles.active
        }`}
        onClick={() => setFunderType(funderTypes.INDIVIDUAL_FUNDER)}
      >
        <div className={styles.corporateIcon}>
          {" "}
          <RetailInvestorIcon />
        </div>

        <div className={styles.investorTypeText}>
          <p className={styles.title}>Individual Investor</p>
          <p className={styles.subTitle}>
            Invest and manage funds as an <br />
            individual on the go.
          </p>
        </div>
        <div className={styles.rightArrow}>
          <RightArrowIcon />
        </div>
      </div>
    </div>
  );
};
export default SignUpTabs;
