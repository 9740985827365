import { BackButton } from "../../../components/BackButton/BackButton.component";
import useInvestments from "./../../../hooks/custom-hooks/useInvestments";
import Alert from "../../../components/Alert/Alert.component";
import { errorHandler } from "./../../../helpers/errorHandler";
import { Fragment, useEffect, useState } from "react";
import Loader from "../../../components/Loader/Loader.component";
import { InvestmentCard } from "./../../../components/InvestmentCard/InvestmentCard.component";
import { useTypedSelector } from "../../../hooks/redux-hooks/useTypedSelector";
import { selectUserCountry } from "../../../redux/selectors/userSelector";
import { appInsights } from "../../../components/AppInsight/AppInsight";
import { IInvestmentCard } from "../../../interfaces/investment";
import { Button } from "antd";

export default function Investments() {
  const { data, isLoading, error } = useInvestments();
  const countryCode = useTypedSelector(selectUserCountry);
  const [activeTab, setActiveTab] = useState("Debt Investment");
  const [selectedInvestmentList, setSelectedInvestmentList] = useState<
    IInvestmentCard[]
  >([]);
  const [filterType, setFilterType] = useState<"all" | "debt" | "trade">("all");

  // let investments = data;

  useEffect(() => {
    appInsights.trackPageView({ name: "Investment.tsx", isLoggedIn: true });
  }, []);

  useEffect(() => {
    if (data && filterType === 'all') {
      setSelectedInvestmentList(data);
    } else if (data && filterType === 'debt'){
      const debtInvestments = data?.filter((investment: {
        investment_product_type_id: number;
        type: string; }) => investment?.investment_product_type_id === 1);
      if (debtInvestments){
        setSelectedInvestmentList(debtInvestments);
      } else {
        setSelectedInvestmentList([]);
      }
    } else if (data && filterType === 'trade'){
      const tradeInvestments = data?.filter((investment: {
        investment_product_type_id: number;
        type: string; }) => investment?.investment_product_type_id === 2);
      if (tradeInvestments){
        setSelectedInvestmentList(tradeInvestments);
      } else {
        setSelectedInvestmentList([]);
      }
    }
  }, [activeTab, data, filterType]);

  const investmentTypes = [
    {
      id: 1,
      name: "Debt Investment",
    },
  ];
  return (
    <div>
      {error && <Alert message={errorHandler(error)} />}
      <div className="mb-3">
        <BackButton text={<div className="page-title">New Investment</div>} />
      </div>
      <Fragment>
        {isLoading ? (
          <div className="no-advancly-data">
            <Loader type="lg" variant="blue" />
            <div className="mt-3">Fetching available investments...</div>
          </div>
        ) : (
          <Fragment>
            {/* Investment Tabs  */}
            <div className="alignToggleTabItems">
              <div className="d-flex justify-content-between">
                {investmentTypes &&
                  investmentTypes?.length > 0 &&
                  investmentTypes.map((item) => (
                    <div
                      className={`tabItem ${
                        activeTab === item.name && "activeTab"
                      }`}
                      onClick={() => setActiveTab(item.name)}
                      key={item.id}
                    >
                      {item.name}
                    </div>
                  ))}
              </div>
            </div>
            <>
              <div>
                <Button
                    className="mr-2"
                    type={filterType === "all" ? "primary" : "default"}
                    onClick={() => {
                      setFilterType("all");
                    }}
                >
                  All
                </Button>
                <Button
                    className="mr-2"
                    type={filterType === "debt" ? "primary" : "default"}
                    onClick={() => {
                      setFilterType("debt");
                    }}
                >
                  Debt
                </Button>
                <Button
                    className="mr-2"
                    type={filterType === "trade" ? "primary" : "default"}
                    onClick={() => {
                      setFilterType("trade");
                    }}
                >
                  Trade
                </Button>
              </div>
              <br/>
            </>
            {!selectedInvestmentList?.length ||
            selectedInvestmentList?.length === 0 ? (
                <div className="no-advancly-data">
                  <i className="ri-briefcase-4-fill ri-3x"></i>
                  <div className="color-bg-blue py-2 font-weight-bold">
                    There are no investments at the moment, please check back at a
                    later time.
                  </div>
                </div>
            ) : (
                <>
                  <p className="font-weight-bold">
                    These are the available investment opportunities at the
                    moment.
                  </p>
                  <br/>
                  <div className="three-grid-layout">
                    {selectedInvestmentList?.map((investment: any) => {
                      return (
                          <InvestmentCard
                              investment={investment}
                              key={investment.id}
                          />
                      );
                    })}
                </div>
              </>
            )}
          </Fragment>
        )}
      </Fragment>
    </div>
  );
}
