import React, { Fragment, useEffect, useState } from "react";
import { Divider, Modal } from "antd";
import TableDataLoading from "../../TableDataLoading/TableDataLoading.component";
import NoTableData from "../../NoTableData/NoTableData.component";
import { attachClassNameToText } from "../../../helpers/attachClassNameToTag";
import formatMoney from "../../../helpers/formatMoney";
import { formatDate } from "../../../helpers/formatDate";

interface IProps {
  handleCancel: () => void;
  isModalOpen: boolean;
  currency: "NGN" | "KES" | "USD";
  transactions: IPortfolioTransaction[];
}

interface IPortfolioTransaction {
  id: number;
  transaction_type: string;
  account_id: number;
  account_no: string;
  transaction_date: string; // or Date if you prefer to work with Date objects
  currency: string;
  amount: number;
  running_balance: number;
  reversed: boolean;
  submitted_date: string; // or Date
  interested_posted_as_on: boolean;
  is_manual_transaction: boolean;
  lien_transaction: boolean;
}

const PortfolioTransactionsModal = ({
  isModalOpen,
  handleCancel,
  currency,
  transactions,
}: IProps) => {
  const [isLoading] = useState(false);

  return (
    <Modal
      title="Recent Transactions"
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
    >
      <Divider />
      <div className="table-responsive">
        <table className="table">
          <thead className="bg-lighter-grey">
            <tr>
              <th>Id</th>
              <th>{`Amount (${currency})`}</th>
              <th>{`Running Balance (${currency})`}</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <TableDataLoading />
            ) : !transactions?.length ? (
              <NoTableData
                text="You have not made any transactions yet"
                icon={<i className="ri-arrow-left-right-line"></i>}
              />
            ) : (
              <Fragment>
                {transactions?.map((transaction) => {
                  const {
                    id,
                    amount,
                    running_balance,
                    currency,
                    transaction_type,
                    transaction_date,
                  } = transaction;
                  return (
                    <tr key={id}>
                      <td>{id}</td>
                      <td className={attachClassNameToText(transaction_type)}>
                        {formatMoney(amount, currency)}
                      </td>
                      <td
                        className={
                          running_balance > 0 ? "color-green" : "color-red"
                        }
                      >
                        {formatMoney(running_balance, currency)}
                      </td>

                      <td>{formatDate(transaction_date)}</td>
                    </tr>
                  );
                })}
              </Fragment>
            )}
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

export default PortfolioTransactionsModal;
