import { useQuery } from "react-query";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { getData } from "../../apis/apiMethods";

type paramProps = {
  funderId: number;
  walletId: number;
};

const getPortfolios = async ({ queryKey }: any) => {
  const { funderId, walletId } = queryKey[1];
  const { data } = await getData(
    `${apiEndpoints.PORTFOLIOS}?funderId=${funderId}&walletAccountId=${walletId}`
  );
  return data;
};

export default function usePortfolios({ funderId, walletId }: paramProps) {
  return useQuery(["getPortfolios", { funderId, walletId }], getPortfolios, {
    refetchOnWindowFocus: true,
    enabled: walletId && funderId ? true : false,
  });
}
