import { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ROUTES } from "./../../../helpers/routes";
import { TCurrencyCodes } from "../../../interfaces/currencies";
import { PortfolioCard } from "./../../../components/PortfolioCard/PortfolioCard.component";
import Loader from "../../../components/Loader/Loader.component";
import { useTypedSelector } from "./../../../hooks/redux-hooks/useTypedSelector";
import {
  selectUserCurrencies,
  selectUserId,
  selectUserWallets,
} from "./../../../redux/selectors/userSelector";
import usePortfolios from "./../../../hooks/custom-hooks/usePortfolios";
import { formatDate } from "./../../../helpers/formatDate";
import Alert from "../../../components/Alert/Alert.component";
import { errorHandler } from "../../../helpers/errorHandler";
import { appInsights } from "../../../components/AppInsight/AppInsight";
import { Select } from "antd";

export default function Portfolios() {
  const currencyList: {
    currencyType: "Foreign" | "Local";
    currencyCode: string;
  }[] = useTypedSelector(selectUserCurrencies);
  const { lcy_wallet_id, usd_wallet_id } = useTypedSelector(selectUserWallets);
  const [activeTab, setActiveTab] = useState("Debt Investment");
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const funderId = useTypedSelector(selectUserId);
  const walletId = selectedCurrency !== "USD" ? lcy_wallet_id : usd_wallet_id;

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const defaultActiveTab = searchParams.get("activeTab") as TCurrencyCodes;

  useEffect(() => {
    if (defaultActiveTab) {
      setSelectedCurrency(defaultActiveTab);
    } else {
      if (currencyList.length > 0) {
        setSelectedCurrency(currencyList[0]?.currencyCode);
      }
    }
    // eslint-disable-next-line
  }, []);



  useEffect(() => {
    appInsights.trackPageView({ name: "Portfolios.tsx", isLoggedIn: true });
  }, []);

  const { isLoading, error, data } = usePortfolios({ funderId, walletId });

  const investmentTypes = [
    {
      id: 1,
      name: "Debt Investment",
    },
  ];

  return (
    <div>
      {error && <Alert message={errorHandler(error)} />}
      <div className="title-align">
        <div className="page-title">Portfolio</div>
        {/*<div>
          <Link className="btn btn-sm advancly-btn" to={ROUTES.INVESTMENTS}>
            New Investment Deals
          </Link>
        </div>*/}
      </div>

      {/* Currency Tabs  */}
      {/*<div className="alignToggleTabItems">
        <div className="d-flex justify-content-between">
          {currencyList &&
            currencyList?.length > 0 &&
            currencyList.map((item) => (
              <div
                className={`tabItem ${
                  activeTab === item.currencyCode && "activeTab"
                }`}
                onClick={() => setActiveTab(item.currencyCode)}
                key={item.currencyCode}
              >
                {item.currencyCode} Investments
              </div>
            ))}
        </div>
      </div>*/}
      <Fragment>
        {isLoading ? (
          <div className="no-advancly-data">
            <Loader type="lg" variant="blue" />
            <div className="mt-3">Updating portfolio information...</div>
          </div>
        ) : (
          <>
            {/* Investment Tabs  */}
            <div className="alignToggleTabItems">
              <div className="d-flex justify-content-between">
                {investmentTypes &&
                  investmentTypes?.length > 0 &&
                  investmentTypes.map((item) => (
                    <div
                      className={`tabItem ${
                        activeTab === item.name && "activeTab"
                      }`}
                      onClick={() => setActiveTab(item.name)}
                      key={item.id}
                    >
                      {item.name}
                    </div>
                  ))}
              </div>
            </div>
            {!data ||
            !data?.length ||
            data?.filter(
              (portfolio: any) =>
                portfolio?.currency?.toLowerCase() ===
                selectedCurrency?.toLowerCase(),
            ).length === 0 ||
            error ? (
              <div className="no-advancly-data">
                <i className="ri-briefcase-4-fill ri-3x"></i>
                <div className="color-bg-blue py-2 font-weight-bold">
                  You have no {selectedCurrency} investments
                </div>
                <div>Click on the “New Investment” button to get started</div>
              </div>
            ) : (
              <>
                <div className="tw-flex tw-justify-between tw-items-center">
                  {/*<div>
                    <Button
                      className="mr-2"
                      type={filterType === "all" ? "primary" : "default"}
                      onClick={() => {
                        setFilterType("all");
                      }}
                    >
                      All
                    </Button>
                    <Button
                      className="mr-2"
                      type={filterType === "debt" ? "primary" : "default"}
                      onClick={() => {
                        setFilterType("debt");
                      }}
                    >
                      Debt
                    </Button>
                    <Button
                      className="mr-2"
                      type={filterType === "trade" ? "primary" : "default"}
                      onClick={() => {
                        setFilterType("trade");
                      }}
                    >
                      Trade
                    </Button>
                  </div>*/}

                  <Select
                    size="large"
                    className="tw-w-6/12"
                    value={selectedCurrency}
                    onChange={(value) => {
                      setSelectedCurrency(value);
                    }}
                  >
                    {currencyList &&
                      currencyList.map((item) => (
                        <Select.Option value={item.currencyCode}>
                          {item.currencyCode}
                        </Select.Option>
                      ))}
                  </Select>
                </div>
                <br />
                <div className="three-grid-layout">
                  {data
                    ?.filter(
                      (portfolio: any) =>
                        portfolio?.currency?.toLowerCase() ===
                        selectedCurrency?.toLowerCase(),
                    )
                    ?.map((portfolio: any) => {
                      const {
                        investment_product_name,
                        currency,
                        investment_status,
                        principal_amount,
                        effective_date,
                        maturity_date,
                        id,
                        tenure,
                        reference_number,
                      } = portfolio;
                      return (
                        <PortfolioCard
                          portfolio={{
                            title: investment_product_name,
                            startDate: formatDate(effective_date),
                            endDate: formatDate(maturity_date),
                            amount: principal_amount,
                            currencyCode: currency || selectedCurrency,
                            id: id,
                            status: investment_status,
                            tenure,
                          }}
                          key={reference_number}
                        />
                      );
                    })}
                </div>
              </>
            )}
          </>
        )}
      </Fragment>
    </div>
  );
}
