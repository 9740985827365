import React, { Fragment } from "react";
import { getDocumentUploadStatus } from "../../helpers/getDocumentUploadStatus";
import { attachClassNameToTag } from "./../../helpers/attachClassNameToTag";

interface DocumentStatusIconProps {
  data: any;
  field: string;
}

export const DocumentStatusIcon: React.FC<DocumentStatusIconProps> = ({
  data,
  field,
}) => {
  const { pending, success, failed, none } = getDocumentUploadStatus({
    data,
    field,
  });
  let elementToShow = null;
  if (none) {
    elementToShow = <small className={attachClassNameToTag("")}></small>;
  } else if (success) {
    elementToShow = (
      <small className={attachClassNameToTag("success")}>Approved</small>
    );
  } else if (pending) {
    elementToShow = (
      <small className={attachClassNameToTag("pending")}>Pending </small>
    );
  } else if (failed) {
    elementToShow = (
      <small className={attachClassNameToTag("failed")}>Declined</small>
    );
  } else {
    elementToShow = (
      <small className={attachClassNameToTag("error")}>Error</small>
    );
  }

  return <Fragment>{elementToShow}</Fragment>;
};
