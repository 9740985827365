import { Link } from "react-router-dom";
import { ROUTES } from "../../helpers/routes";
import styles from "../DashboardNoInvestment/DashboardNoInvestment.module.scss";

export default function DashboardNoInvestment() {
  return (
    <div className="investment-map-section color-page-black">
      <i className="ri-briefcase-5-fill"></i>
      <div className={` font-weight-bold py-2 ${styles.noInvestments}`}>
        You currently have no investments
      </div>
      <div className="pb-2">
        Click on the “Start Investing” button to get started
      </div>
      <Link className="btn advancly-btn btn-sm" to={ROUTES.INVESTMENTS}>
        Start Investing
      </Link>
    </div>
  );
}
