import React from "react";
import { enforceMaxLength } from "../../helpers/enforceMaxLength";
import ErrorHandler from "../ErrorHandler/ErrorHandler.component";
import { ChangeHandler } from "react-hook-form";
import { NumericFormat } from "react-number-format";

interface ICustomInputFieldProps {
  type: string;
  name?: string;
  placeholder?: string;
  label?: string;
  errors?: any;
  maxLength?: undefined | number;
  max?: number | string;
  defaultValue?: string | number;
  value?: string | number;
  minLength?: undefined | number;
  readOnly?: boolean;
  onChange?: ChangeHandler;
  min?: number | string;
  showRequiredIcon?: boolean;
  hasActionButton?: boolean;
  actionButtonText?: string;
  onClickActionButton?: () => void;
  extraLabel?: string;
  dob?: boolean;
  enableSeparator?: boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
}

const CustomInputField: React.FC<ICustomInputFieldProps> = React.forwardRef(
  (
    {
      extraLabel,
      min,
      showRequiredIcon,
      hasActionButton,
      actionButtonText,
      onClickActionButton,
      type,
      name,
      placeholder,
      label,
      errors,
      maxLength,
      max,
      defaultValue,
      value,
      minLength,
      readOnly,
      onChange,
      enableSeparator = false,
      children,
      ...otherProps
    },
    ref: any,
  ) => {
    return (
      <div className="mb-4">
        <div className="d-flex align-items-center">
          <label className="form-label">{label}</label>
          {showRequiredIcon && <sup className="ml-1 crimson">*</sup>}
          {extraLabel?.length ? (
            <label className="form-label ml-2">
              <small className="crimson">{extraLabel}</small>
            </label>
          ) : null}
        </div>
        <div className="input-group">
          {children && (
            <div className="input-group-prepend">
              <span className="input-group-text">{children}</span>
            </div>
          )}
          {enableSeparator ? (
            <NumericFormat
              allowNegative={false}
              thousandSeparator={true}
              defaultValue={defaultValue}
              className={`form-control ${hasActionButton && "border-right-0"} ${
                children && "border-left-0"
              }`}
              name={name}
              max={max}
              min={min}
              placeholder={placeholder}
              onKeyDown={enforceMaxLength}
              maxLength={maxLength}
              minLength={minLength}
              onChange={onChange}
              readOnly={readOnly}
              step=".01"
              {...otherProps}
              getInputRef={ref}
            />
          ) : (
            <input
              className={`form-control ${hasActionButton && "border-right-0"} ${
                children && "border-left-0"
              }`}
              type={type}
              name={name}
              max={max}
              min={min}
              placeholder={placeholder}
              onKeyDown={enforceMaxLength}
              maxLength={maxLength}
              minLength={minLength}
              defaultValue={defaultValue}
              onChange={onChange}
              readOnly={readOnly}
              ref={ref}
              step=".01"
              {...otherProps}
            />
          )}

          {hasActionButton && (
            <div
              className="input-group-append cursor-pointer"
              onClick={onClickActionButton}
            >
              <span className="input-group-text text-capitalize">
                {actionButtonText}
              </span>
            </div>
          )}
        </div>
        <ErrorHandler errors={errors} />
      </div>
    );
  },
);

export default CustomInputField;
