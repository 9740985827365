export const getDocumentUploadStatus = ({
  data,
  field,
}: {
  data: any;
  field: string;
}) => {
  let pending = false;
  let success = false;
  let failed = false;
  let none = false;

  const search = data?.find(
    (dataItem: any) => dataItem.document_field_code === field
  );
  const result = search?.funder_documents[0]?.approval_status;

  if (result?.toLowerCase() === "pending") {
    pending = true;
  } else if (result?.toLowerCase() === "approved") {
    success = true;
  } else if (result?.toLowerCase() === "declined") {
    failed = true;
  } else {
    none = true;
  }

  return { pending, success, failed, none };
};
