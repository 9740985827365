import { useQuery } from "react-query";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { getData } from "../../apis/apiMethods";

type paramProps = {
  countryCode: string;
};

const getGenderOptions = async ({ queryKey }: any) => {
  const { countryCode } = queryKey[1];
  const { data } = await getData(
    `${apiEndpoints.GET_GENDER_OPTIONS}?countryCode=${countryCode}`
  );
  return data;
};

export default function useGenderOptions({ countryCode }: paramProps) {
  return useQuery(["getGenderOptions", { countryCode }], getGenderOptions, {
    refetchOnWindowFocus: false, // Refetch on window focus
    enabled: countryCode ? true : false,
    keepPreviousData: true,
  });
}
