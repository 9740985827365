import { Fragment, useRef, useState } from "react";
import { useTypedSelector } from "../../hooks/redux-hooks/useTypedSelector";
import { useActions } from "../../hooks/redux-hooks/useActions";
import { WithdrawalModalStepTwo } from "./WithdrawalModalStepTwo/WithdrawalModalStepTwo.component";
import WithdrawalModalStepOne from "./WithdrawalModalStepOne/WithdrawalModalStepOne.component";
import {
  selectWithdrawalCurrency,
  selectWithdrawalCurrentStep,
} from "../../redux/selectors/withdrawalSelector";
import WithdrawalModalStepThree from "./WithdrawalModalStepThree/WithdrawalModalStepThree.component";
import WithdrawalModalStepFour from "./WithdrawalModalStepFour/WithdrawalModalStepFour.component";
import Alert from "../Alert/Alert.component";
import { errorHandler } from "../../helpers/errorHandler";

export default function WithdrawalModal() {
  const dismissRef = useRef<null | HTMLButtonElement>(null);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const currentStep = useTypedSelector(selectWithdrawalCurrentStep);
  const { resetWithdrawal } = useActions();

  const closeModal = () => {
    setSuccess("");
    dismissRef?.current?.click();
    resetWithdrawal();
  };

  return (
    <Fragment>
      {error && (
        <Alert
          message={
            errorHandler(error) ||
            "An error occured while processing your withdrawal, please try again."
          }
        />
      )}
      <div
        className="modal fade"
        id="withdrawalModal"
        tabIndex={-1}
        aria-labelledby="withdrawalModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content p-4">
            <div className="modal-body">
              <button
                type="button"
                className="d-none"
                data-dismiss="modal"
                aria-label="Close"
                ref={dismissRef}
              >
                <span aria-hidden="true">&times;</span>
              </button>

              {(() => {
                switch (currentStep) {
                  case 1:
                    return <WithdrawalModalStepOne closeModal={closeModal} />;
                  case 2:
                    return <WithdrawalModalStepTwo closeModal={closeModal} />;
                  case 3:
                    return <WithdrawalModalStepThree closeModal={closeModal} />;
                  case 4:
                    return (
                      <WithdrawalModalStepFour
                        closeModal={closeModal}
                        setError={setError}
                        setSuccess={setSuccess}
                        success={success}
                      />
                    );

                  default:
                    return null;
                }
              })()}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
