import { useQuery } from "react-query";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { getData } from "../../apis/apiMethods";

type paramProps = {
  walletId: number;
  funderId: number;
};

const getWalletBalance = async ({ queryKey }: any) => {
  const { walletId, funderId } = queryKey[1];
  const { data } = await getData(
    `${apiEndpoints.WALLET_BALANCE}?savingsWalletId=${walletId}&funderId=${funderId}`
  );
  return data;
};

export default function useWalletBalance({ walletId, funderId }: paramProps) {
  return useQuery(
    ["getWalletBalance", { walletId, funderId }],
    getWalletBalance,
    {
      refetchOnWindowFocus: true, // Refetch on window focus
      refetchInterval: 5 * 1000 * 60, // Fetch balance every five minutes
      enabled: walletId && funderId ? true : false,
    }
  );
}
